import axios from 'axios'
import authService from '@/services/auth.service'
import { IGNORED_TOAST_ERROR_ENDPOINTS, HTTP_STATUS_UNAUTHORIZED } from '@/services/constants'
import store from '@/store'

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && error.response.status === HTTP_STATUS_UNAUTHORIZED) {
      authService.logout()
      if (!IGNORED_TOAST_ERROR_ENDPOINTS.some((ignoredEndpoint) => error.config.url.endsWith(ignoredEndpoint))) {
        store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
      }
      return error
    } else {
      throw error
    }
  }
)

instance.interceptors.request.use((config) => {
  if (store.state.user.tokens?.access_token && store.state.user.tokens.access_token !== '') {
    config.headers.Authorization = 'Bearer ' + store.state.user.tokens.access_token
  }
  return config
})

export default instance
