import { RouteRecordRaw } from 'vue-router'
import { CRUD, DataType } from '@/interfaces'

const inventoryRoutes: RouteRecordRaw[] = [
  // publisher user
  {
    path: 'publisher-domains',
    name: 'publisher-domains',
    props: true,
    meta: {
      title: 'inventory'
    },
    children: [
      {
        path: 'add',
        name: 'pd-add-domain',
        component: () => import('@/components/domains/content/DomainsAddDomain.vue'),
        meta: {
          title: 'domains'
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'add-closeUnsavedDomain',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'domains'
            },
            props: { type: DataType.MyDomains, action: CRUD.ConfirmClose }
          }
        ]
      },
      {
        path: 'view/:id',
        name: 'pb-view-domain',
        component: () => import('@/components/domains/content/DomainsViewDomain.vue'),
        props: true,
        meta: {
          title: 'domains'
        },
        children: []
      },
      {
        path: 'delete/:id',
        name: 'pd-delete-domain',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'domains'
        },
        props: { type: DataType.MyDomains, action: CRUD.Delete }
      }
    ]
  },
  {
    path: 'publisher-urls',
    name: 'publisher-urls',
    meta: {
      title: 'urls'
    },
    children: [
      {
        path: 'delete/:id',
        name: 'delete-targeting-url',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'urls'
        },
        props: { type: DataType.PairedUrls, action: CRUD.Delete }
      },
      {
        path: 'details/:id',
        name: 'view-targeting-url-video-details',
        component: () => import('@/components/content/library/details/VideoDetails.vue'),
        meta: {
          title: 'urls'
        },
        children: [
          {
            path: 'share',
            name: 'paired-urls-video-details-share',
            component: () => import('@/components/content/library/VideoShare.vue'),
            meta: {
              title: 'urls'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'excluded-domains',
    name: 'excluded-domains',
    meta: {
      title: 'domains'
    },
    children: [
      {
        path: 'add',
        name: 'cd-add-domain',
        component: () => import('@/components/inventory/excluded-domains/add-domain/DomainsAddExcludedDomain.vue'),
        props: true,
        meta: {
          title: 'domains'
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'add-closeUnsavedEDomain',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'domains'
            },
            props: { type: DataType.ExcludedDomains, action: CRUD.ConfirmClose }
          }
        ]
      },
      {
        path: 'delete/:id',
        name: 'cd-delete-domain',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'domains'
        },
        props: { type: DataType.ExcludedDomains, action: CRUD.Delete }
      }
    ]
  }
]

export default inventoryRoutes
