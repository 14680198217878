import { CRUD, DataType } from '@/interfaces'
import { RouteRecordRaw } from 'vue-router'

const playlistsRoutes: RouteRecordRaw[] = [
  {
    path: 'edit/:id',
    name: 'editplaylist',
    component: () => import('@/components/content/playlist/edit-playlist/PlaylistEdit.vue'),
    meta: {
      title: 'playlists'
    },
    children: [
      {
        path: 'delete',
        name: 'edit-deletePlaylist',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'playlists'
        },
        props: { type: DataType.Playlist, action: CRUD.Delete }
      },
      {
        path: 'closeunsaved',
        name: 'edit-closeUnsavedPlaylist',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'playlists'
        },
        props: { type: DataType.Playlist, action: CRUD.ConfirmClose }
      }
    ]
  },
  {
    path: 'delete/:id',
    name: 'delete-playlist',
    component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
    meta: {
      title: 'playlists'
    },
    props: { type: DataType.Playlist, action: CRUD.Delete }
  },
  {
    path: 'duplicate/:id',
    name: 'duplicate-playlist',
    component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
    meta: {
      title: 'playlists'
    },
    props: { type: DataType.Playlist, action: CRUD.Duplicate }
  }
]

export default playlistsRoutes
