<template>
  <router-view v-slot="{ Component }">
    <transition>
      <component :is="Component" v-if="isLoaded" />
    </transition>
  </router-view>
  <CustomModal v-if="store.state.modal.modalType" />
  <Messages v-if="isLoaded" />
</template>

<script setup lang="ts">
import { onMounted, watch, ref, onBeforeUnmount, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import authService from '@/services/auth.service'
import { apiService } from '@/services'
import { nonAuthRoutes } from '@/router'
import { SUPPORTED_LANGUAGES_TEMP, TRUVID_MAX_FILTER_VIDEO_LENGTH } from './services/constants'
import { updateTitleLanguage } from './services/utils'
import { BroadcastChannel } from 'broadcast-channel'
import Messages from '@/components/messages/Messages.vue'
import CustomModal from '@/components/modals/custom-modal/CustomModal.vue'

// ARRAY HOLDS THE IMAGES THAT NEED TO BE PRELOADED
const imagesToPreload = ['common/close', 'messages/success']
const { locale } = useI18n({ useScope: 'global' })
const route = useRoute()
const router = useRouter()
const store = useStore()
const isPlaying = ref(false)
const mounting = ref(true)
const isLoaded = ref(false)
let broadcastChannel

async function StartUp() {
  await router.isReady()
  if (route.query.lang && SUPPORTED_LANGUAGES_TEMP.map((l) => l.code).includes(route.query.lang.toString())) {
    locale.value = route.query.lang.toString()
    store.dispatch('cache/setPageLanguage', locale.value)
  } else {
    locale.value = store.getters['cache/getPageLanguage']()
  }
  updateTitleLanguage(router.currentRoute.value.meta.title)
  if (
    !nonAuthRoutes.some((r) => {
      return route.fullPath.includes(r)
    }) &&
    store.state.user.user?.id > 0 &&
    store.state.user.tokens &&
    store.state.user.tokens.access_token
  ) {
    let valid = false
    if (store.state.user.tokens_updated + 1000 * 60 * 60 * 24 > new Date().getTime() && store.state.user.tokens.expires * 1000 - new Date().getTime() > 0) {
      try {
        valid = await apiService.organizationUser.checkToken(store.state.user.user.id)
      } catch {
        valid = false
      }
    } else {
      valid = false
    }

    if (!valid) {
      authService.logout()
    } else {
      await refreshToken()
      setTokenTimeout()
      resetSettings()
    }
  } else {
    if (
      !nonAuthRoutes.some((r) => {
        return route.fullPath.includes(r)
      })
    ) {
      authService.logout()
    }
  }
  isLoaded.value = true
  const loadScreen = document.getElementById('startscreen')
  if (loadScreen) {
    loadScreen.remove()
  }

  if (route.query.video_id) {
    router.push(route.path)
  }
  mounting.value = false
  broadcastChannel = new BroadcastChannel('truvid')
  broadcastChannel.onmessage = function (ev) {
    if (ev === 'logout') {
      authService.logout()
    }
  }
}

onMounted(() => {
  window['truvidConsole'] = {
    version: process.env.CONSOLE_VERSION
  }
  StartUp()
  preloadImages()
})

onBeforeUnmount(() => {
  if (broadcastChannel) {
    broadcastChannel.close()
  }
})

const isAuthenticated = computed(() => {
  return store.state.isAuthenticated
})

function resetSettings(): void {
  store.commit('library/setDurationFilter', [0, TRUVID_MAX_FILTER_VIDEO_LENGTH])
  store.commit('library/setCategories', [])
  store.commit('library/setLanguages', [])
  store.commit('library/setPricing', '')
  store.commit('library/setUploadDate', 'all')
  store.commit('library/setSearch', '')
  store.commit('playlists/setSelectedVideos', [])
  store.commit('modal/closeModal')
}

watch(route, (old, newRoute) => {
  if (!mounting.value) {
    isPlaying.value = !!newRoute.query.video_id
  }
})

watch(isAuthenticated, (newValue, oldValue) => {
  if (!newValue && oldValue) {
    if (broadcastChannel) {
      broadcastChannel.postMessage('logout')
    }
  }
})

const refreshTime = 16 * 60 * 60 * 1000

async function refreshToken() {
  const refreshed = store.state.user.tokens_updated
  const now = new Date().getTime()
  if (now - refreshed - 1 > refreshTime) {
    const tokens = await apiService.organizationUser.refreshToken(store.state.user.tokens.refresh_token)
    store.commit('user/setNewTokens', tokens)
  }
}

function setTokenTimeout() {
  if (window.tokenRefresh) {
    clearInterval(window.tokenRefresh)
  }
  const now = new Date().getTime()
  const timeToWait = store.state.user.tokens_updated + refreshTime - now + 1000
  new Promise((resolve) => setTimeout(resolve, timeToWait))
  refreshToken()
  window.tokenRefresh = setInterval(function () {
    refreshToken()
  }, refreshTime)
}

function preloadImages() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadImage = (src) =>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      // img.src = require('./assets/' + src + '.svg')
      // todo - gilad do we need this code? preload image
    })
  Promise.all(imagesToPreload.map(loadImage))
}

watch(
  () => store.state?.user?.user?.id,
  () => {
    requestAnimationFrame(() => {
      locale.value = store.getters['cache/getPageLanguage']()
      updateTitleLanguage(router.currentRoute.value.meta.title)
    })
  }
)
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: auto;
  min-height: 100%;
  position: relative;
}

html {
  scroll-behavior: smooth;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
