<template>
  <img :src="icon" :style="iconStyle" alt="icon">
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import * as icons from '@/components/common/icons/index'

interface Props {
  name: string
  iconHeight?: number
}

const props = defineProps<Props>()
const icon = computed(() => icons[props.name as keyof typeof icons])

const iconStyle = computed(() => {
  if (props.iconHeight) {
    return { height: `${props.iconHeight}px` }
  }
  return {}
})
</script>
