<template>
  <button
    v-if="!hasOnlyContentEditingRoles()"
    class="button-container"
    :class="{ red: props.classList === 'red', blue: props.classList === 'blue', no_hover: selectOnly }"
    @click.stop="click"
  >
    <div class="icon-container">
      <img :src="icon" alt="">
    </div>
    <div class="text-container">
      {{ props.text }}
    </div>
  </button>
</template>

<script setup lang="ts">
import { hasOnlyContentEditingRoles } from '@/services/utils'

const props = withDefaults(
  defineProps<{
    classList?: string
    text?: string
    extendedText?: string
    icon?: string
    extendedIcon?: string
    selectOnly?: boolean
  }>(),
  {
    classList: '',
    text: '',
    extendedText: '',
    icon: '',
    extendedIcon: '',
    selectOnly: false
  }
)
const emit = defineEmits(['click'])

function click() {
  emit('click')
}
</script>

<style lang="scss" scoped>
.button-container {
  width: fit-content;
  max-width: 50px;
  height: 30px;
  margin: 0;
  padding: 6px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $inputBorder;
  background-color: rgba(0, 0, 0, 0.7);
  transition: max-width 1.25s ease;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    max-width: 250px;
    background-color: rgba(0, 0, 0, 0.7);

    .text-container {
      display: inline;
    }

    .icon-container {
      display: none;
    }
  }

  &.no_hover {
    &:hover {
      max-width: 50px;

      .text-container {
        display: none;
      }

      .icon-container {
        display: flex;
      }
    }
  }

  &.darkgray {
    border: 1px solid white;
    background-color: rgba(22, 21, 21, 0.6);
    color: white;

    &:hover {
      background-color: rgba(22, 21, 21, 0.6);
    }
  }

  &.red {
    border: 1px solid var(--Primary, #2c95ff);
    background-color: var(--Primary, #2c95ff);
    color: white;
    opacity: 0.8;

    &:hover {
      background-color: var(--Primary, #2c95ff);
      opacity: 1;
    }
  }

  .icon-container {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: none;
  }

  .text-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    margin: 0px 4px 0px 6px;
    pointer-events: none;
    display: none;
  }

  img {
    width: 20px;
    height: 20px;
  }
}
</style>
