import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const excludedDomainsCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'domain',
      title: 'settings-content-excluded-domains-domain',
      width: 30,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1,
      expand: true
    },
    {
      name: 'created_at',
      title: 'create-date',
      width: 20,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 2
    },
    {
      name: 'actions',
      title: '',
      width: 20,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 3,
      priority: 0
    }
  ]
})

export default excludedDomainsCols
