<template>
  <div class="wrapper-cc" @click.stop>
    <div ref="popupRef" class="modal-cc" tabindex="0" @keydown.esc.stop.prevent="escClose">
      <div class="container">
        <LoadingSpinner v-if="loading" class="spinner" />
        <div v-if="slots.content" class="image-container">
          <slot name="image" />
        </div>
        <div v-if="slots.content" class="text-container">
          <div class="text">
            <slot name="text" />
          </div>
          <div class="sub-text">
            <slot name="secondary" />
          </div>
        </div>
        <div v-else class="default-container">
          <img v-if="image" :src="image">
          <div class="text-container">
            <div class="text">
              {{ primaryText }}
            </div>
            <div v-if="secondaryText" class="sub-text">
              {{ secondaryText }}
            </div>
          </div>
        </div>
        <div v-if="showInput && (keyword || localKeyword) && !hideInput" class="input">
          <CustomInputValidation
            v-model="inputValue"
            :label="inputLabelLocal"
            :input-type="CustomInputType.String"
            :placeholder="placeholderLocal"
            :validation-object="validatorsObject"
            :custom-error-messages="customErrorMessages"
            @change="onChange"
          />
        </div>
        <div class="buttons">
          <button class="link" @click="closeModal()">{{ cancelBtn }}</button>
          <button class="primary" :class="{ disabled: !isValid() }" @click="onModalConfirm()">{{ confirmBtn }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { isEsc } from '@/services/utils'
import CustomInputValidation from '@/components/common/custom-inputs/CustomInputValidation.vue'
import { CustomInputType, DataType, CRUD } from '@/interfaces'
import { useI18n } from 'vue-i18n'
import duplicateIcon from '@/assets/playlists/playlist/duplicate_playlist.svg'
import deleteIcon from '@/assets/common/deleteAvatar.svg'
import warningIcon from '@/assets/common/popup-alert.svg'
import { useSlots } from 'vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { mapCRUDTypeTranslationsNew, mapDataTypeKey } from '@/components/modals/custom-modal/modal-action-components/utils'
import { useStore } from 'vuex'
import { ModalType } from '@/components/modals/modals-util'

export interface ModalProps {
  type: DataType
  action: CRUD | null
  itemName?: string
  id?: number | string | null
  field?: string | null
  text?: string
  icon?: string
  value?: boolean
  placeholder?: string
  inputLabel?: string
  textSecondary?: string
  keyword?: string
  confirmBtnTxt?: string
  cancelBtnTxt?: string
  hideInput?: boolean
  forceQuit?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel?: ((arg: any) => Promise<void>) | ((arg?: any) => void)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm?: ((arg: any) => Promise<void>) | ((arg?: any) => void)
  setFocusOnUnmount?: () => void
}

const inputValue = ref('')
const slots = useSlots()
const { t, te } = useI18n()
const validationObject = ref({})
const validatorsObject = ref({})
const confirmBtn = ref(t('i-understand'))
const cancelBtn = ref(t('cancel'))
const customErrorMessages = ref()
const image = ref(null)
const inputLabelLocal = ref(null)
const placeholderLocal = ref(null)
const store = useStore()
const primaryText = ref('')
const secondaryText = ref('')
const localKeyword = ref('')
const popupRef = ref<HTMLElement | null>(null)
const props = defineProps<ModalProps>()
const emit = defineEmits(['cancel', 'confirm', 'close'])
const shouldHandleOnCancel = ref(true)
const showInput = computed(() => {
  if (props.action !== null) {
    return [CRUD.Delete, CRUD.Duplicate].includes(props.action)
  }
  return false
})
const loading = computed(() => store.getters['modal/getLoadingState']())
const modalType = computed(() => store.getters['modal/getModalType']())

onMounted(() => {
  popupRef.value?.focus()
  emit('close', closeModal)
  setModalOptions()
})

function escClose(event) {
  if (isEsc(event.keyCode)) {
    closeModal()
  }
}

// set default modal properties based on Data type and CRUD action
// all of this (except buttons) is unused if slots are used for templating the content
function setModalOptions() {
  if (props.text) {
    primaryText.value = props.text
    if (props.textSecondary) {
      secondaryText.value = props.textSecondary
    }
  } else if (!slots.content) {
    if (props.action === CRUD.Delete) {
      if (props.type === DataType.Playlist) {
        secondaryText.value = t('playlist-widget-warning')
      } else if (props.type === DataType.MyDomains) {
        secondaryText.value = t('playlist-widget-warning-function')
      } else if (props.type === DataType.Widget) {
        secondaryText.value = t('widget-delete-warning')
      }
    }
    if (props.action === CRUD.Deactivate && props.type === DataType.Widget) {
      secondaryText.value = t('widget-deactivate-secondary')
    }
    if (props.action === CRUD.ConfirmClose) {
      primaryText.value = t('confirmation-popup-changes-close-discard')
    } else if (props.action === CRUD.CloseCreate) {
      if (props.type === DataType.ContentSources) {
        primaryText.value = t('confirmation-popup-discard-video-import')
      } else if (props.type === DataType.Folder) {
        primaryText.value = t('confirmation-popup-discard-folder-creation')
      } else if (props.type === DataType.Users) {
        primaryText.value = t('confirmation-popup-discard-user-creation')
      } else if (props.type === DataType.MyDomains) {
        primaryText.value = t('confirmation-popup-discard-domain-addition')
      } else if (props.type === DataType.ExcludedDomains) {
        primaryText.value = t('confirmation-popup-discard-domain-exclusion')
      } else if (props.type === DataType.Widget) {
        primaryText.value = t('confirmation-popup-discard-widget-creation')
      } else if (props.type === DataType.Video) {
        primaryText.value = t('confirmation-popup-discard-video-upload')
      } else if (props.type === DataType.Playlist) {
        primaryText.value = t('confirmation-popup-discard-playlist-creation')
      } else if (props.type === DataType.Package) {
        primaryText.value = t('confirmation-popup-discard-package-creation')
      }
    } else if (props.type === DataType.OfferedPackages && props.action === CRUD.ConfirmAccept) {
      primaryText.value = modalType.value === ModalType.OFFERED_PACKAGES_All_PACKAGE_MODAL ? t('accept-all-packages-question') : t('accept-package-are-you-sure')
    } else if (props.type === DataType.OfferedPackages && props.action === CRUD.ConfirmDecline) {
      primaryText.value = t('decline-package-are-you-sure')
    } else if (props.type === DataType.PairedUrls && props.action === CRUD.Unpair) {
      primaryText.value = t('confirmation-popup-unpair-url')
    } else {
      primaryText.value = getConfirmationPopupText(props.action, props.type)
    }
  }

  // set keyword to init input field
  localKeyword.value = t('keyword-delete')
  confirmBtn.value = mapCRUDTypeTranslationsNew(props.action)
  cancelBtn.value = t('cancel')
  if (props.action === CRUD.Delete) {
    image.value = deleteIcon
    inputLabelLocal.value = t('type-to-confirm', { keyword: t('keyword-delete') })
    validatorsObject.value = { sameAs: t('keyword-delete') }
    customErrorMessages.value = {
      sameAs: t('keyword-missmatch', { value: t('keyword-delete') })
    }
  } else if (props.action === CRUD.Duplicate) {
    image.value = duplicateIcon
    inputLabelLocal.value = t('duplicate-name-label')
    placeholderLocal.value = inputLabelLocal.value
    inputValue.value = t('duplicate-item-placeholder', { item_name: props.itemName })
    validatorsObject.value = { required: true, minLen: 2, maxLen: 128 }
  } else if ([CRUD.ConfirmClose, CRUD.CloseCreate, CRUD.Deactivate, CRUD.Confirm, CRUD.Unpair, CRUD.ConfirmAccept].includes(props.action)) {
    image.value = warningIcon
    if (props.action === CRUD.Deactivate) {
      cancelBtn.value = t('back')
      confirmBtn.value = t('crud-deactivate')
    }
  }
  if (props.action === CRUD.ConfirmClose || props.action === CRUD.CloseCreate) {
    confirmBtn.value = t('discard')
    cancelBtn.value = t('back')
  }
  if (props.action === CRUD.ConfirmAccept) {
    confirmBtn.value = t('accept')
    cancelBtn.value = t('back')
  }
  if (props.action === CRUD.ConfirmDecline) {
    image.value = deleteIcon
    confirmBtn.value = t('decline')
    cancelBtn.value = t('back')
  }
  if (props.action === CRUD.Unpair) {
    confirmBtn.value = t('crud-unpair')
  }

  // overwrite defaults with values passed in by props
  if (props.icon) {
    image.value = props.icon
  }
  if (props.placeholder) {
    placeholderLocal.value = props.placeholder
  }
  if (props.inputLabel) {
    inputValue.value = props.inputLabel
  }
  if (props.confirmBtnTxt) {
    confirmBtn.value = props.confirmBtnTxt
  }
  if (props.cancelBtnTxt) {
    cancelBtn.value = props.cancelBtnTxt
  }
}

async function onModalConfirm() {
  await store.dispatch('modal/startLoading')
  if (typeof props.onConfirm === 'function') {
    await props.onConfirm({ ...props, inputValue: inputValue.value, value: true })
  }
  await store.dispatch('modal/stopLoading')
  shouldHandleOnCancel.value = false
  await store.dispatch('modal/closeModal')
}

function onChange(val) {
  if (val) validationObject.value[val.id] = val.valid
}

function isValid(): boolean {
  let valid = true
  Object.keys(validationObject.value).forEach((key) => {
    if (!validationObject.value[key]) valid = false
  })
  return valid
}

async function closeModal() {
  if (typeof props.onCancel === 'function') {
    await props.onCancel({ ...props })
  }
  await store.dispatch('modal/closeModal')
}

function getConfirmationPopupText(crudType: CRUD, dataType: DataType): string {
  let defaultText = mapCRUDTypeTranslationsNew(crudType) + ' ' + t(`title-${mapDataTypeKey(dataType)}`)
  if (crudType === CRUD.Delete) {
    defaultText = defaultText + ` ${t('confirmation-popup-permanently')}?`
  } else {
    defaultText = defaultText + '?'
  }
  const i18nKey = `confirmation-popup-${mapDataTypeKey(dataType)}-${crudType}`
  const i18nKeyValue = te(i18nKey) ? t(i18nKey) : null
  return i18nKeyValue ? i18nKeyValue : defaultText
}

onUnmounted(async () => {
  if (typeof props.setFocusOnUnmount === 'function') {
    props.setFocusOnUnmount()
  }
})
</script>

<style lang="scss" scoped>
.wrapper-cc {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  .warning {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    background: #fdedee;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    gap: 16px;
    margin: 0 0 32px 0;
    width: 100%;
    max-width: 396px;
  }

  .no-background {
    background: none;
    font-size: 14px;
  }

  .spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
  }

  .modal-cc {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &.loading {
      background-color: transparent;
      border: none;
    }

    .close-x {
      position: absolute;
      right: 16px;
      top: 24px;
      cursor: pointer;
    }

    .container {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      position: relative;

      > img {
        margin-bottom: 32px;
      }

      .default-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
      }

      .text-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 396px;
        margin-bottom: 32px;

        .text {
          font-style: normal;
          font-weight: 800;
          font-size: 21px;
          line-height: 29px;
          text-align: center;

          /* body text */

          color: #4c4c66;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }

        .sub-text {
          font-family: 'Nunito';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.1px;
          color: #8a898c;
        }
      }

      .input {
        width: 396px;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }
  }

  .noData {
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-item {
      padding-block: 1rem;
      font-weight: bold;
      font-size: x-large;
    }

    .text {
      text-align: center;
    }
  }
}
</style>
