/* eslint-disable @typescript-eslint/no-explicit-any */
import '@/styles/styles.scss'
import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import Hotjar from './plugins/hotjar/hotjar'
import Heap from './plugins/heap/heap'
import { Event } from '@sentry/types'

// Create the app
const app = createApp(App)
const sentryAuthToken = import.meta.env.VITE_APP_SENTRY_AUTH_TOKEN
const isProduction = import.meta.env.VITE_APP_IS_PRODUCTION === 'true'

// VueObserveVisibility['install'] = () => null
app.use(i18n)
app.use(router)
app.use(store)
// app.use(VueObserveVisibility)

// Sentry Configuration
let sentryConfig = {
  app,
  dsn: sentryAuthToken,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^\//]
}

sentryConfig = {
  ...sentryConfig,
  ...{
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    beforeSend: (event: Event) => {
      console.error('DEV-ERROR: ' + event.exception?.values?.map((v) => v.value).join(','))
      console.error(event)
      return event
    }
  }
}

// Global Error Handler
app.config.errorHandler = (err) => {
  if ((err as Error).message.match(/Loading chunk \d+ failed/)) {
    window.location.reload()
  } else {
    console.error('Error:', err)
  }
}

// Router Error Handler
router.onError((error) => {
  if ((error as Error).message.match(/loading chunk \d* failed./i)) {
    window.location.reload()
  }
})

// for debug purpose
// app.use(Heap, {
//   envId: '3223852839',
// });

if (isProduction) {
  Sentry.init(sentryConfig)

  app.use(Hotjar, {
    id: 5144947,
    snippetVersion: 6,
    isProduction: isProduction
  })

  app.use(Heap, {
    envId: '98793124'
  })
}

app.mount('#app')

// Global TypeScript Declarations
declare global {
  interface Window {
    BranovatePlayer: any
    player: any
    tokenRefresh: any
    hls: any
  }
}
