import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const domainsCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'name',
      title: 'accounts-account-name',
      width: 18,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1,
      expand: true
    },
    {
      name: 'website',
      title: 'accounts-account-website',
      width: 18,
      alignment: ListColumnAlignment.Left,
      sortable: false,
      position: 2,
      priority: 2
    },
    {
      name: 'agency_account_type',
      title: 'accounts-account-type',
      width: 27,
      alignment: ListColumnAlignment.Left,
      sortable: false,
      position: 4,
      priority: 4
    },
    {
      name: 'created_at',
      title: 'accounts-account-created',
      width: 14,
      alignment: ListColumnAlignment.Left,
      sortable: true,
      position: 5,
      priority: 5
    },
    {
      name: 'agency_fee',
      title: 'accounts-input-agency-agencyFee',
      width: 12,
      alignment: ListColumnAlignment.Left,
      sortable: false,
      position: 3,
      priority: 3
    },
    {
      name: 'actions',
      title: '',
      width: 5,
      alignment: ListColumnAlignment.Left,
      sortable: false,
      position: 6,
      priority: 0,
      minWidthInPx: '150px'
    }
  ]
})

export default domainsCols
