<script lang="ts">
import { onMounted, defineComponent, ref, Teleport, Transition, h, Comment, Text, Static, Fragment, Suspense, withDirectives, withKeys, vShow, toRefs, computed, watch } from 'vue'
import type { PropType } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useFloating, arrow, offset, inline, flip, size, autoUpdate, detectOverflow } from '@floating-ui/vue'
import type { Placement, Middleware, ClientRectObject, ReferenceElement } from '@floating-ui/vue'
import { useId } from '@/composables'

export default defineComponent({
  inheritAttrs: false,
  props: {
    active: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String as PropType<Placement>,
      default: 'right-start'
    },
    content: {
      type: String,
      default: undefined
    },
    virtualReference: {
      type: Object as PropType<ClientRectObject>,
      default: undefined
    },
    virtualReferenceContext: {
      type: Object as PropType<Element>,
      default: undefined
    },
    maxWidth: {
      type: Number,
      default: 280
    },
    disableAnimation: {
      type: Boolean,
      default: false
    },
    textLength: {
      type: Number,
      default: 50
    },
    hover: {
      type: Boolean,
      default: true
    },
    elementResize: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:active'],
  setup(props, { emit, slots }) {
    const active = ref(false)
    const visible = ref(false)
    const zIndex = ref(10000)
    function activate() {
      if (active.value) return

      active.value = true
      emit('update:active', true)
    }

    function deactivate() {
      if (!active.value) return
      active.value = false
      emit('update:active', false)
    }

    watch(
      () => props.active,
      (val) => {
        if (val) {
          activate()
          return
        }

        deactivate()
      },
      { immediate: true }
    )

    let stopAutoUpdate: (() => void) | null

    const id = useId('tooltip')
    const referenceEl = ref()
    const floatingEl = ref()
    const arrowEl = ref<HTMLElement>()
    const middleware = computed<Middleware[]>(() => {
      const result = [
        arrow({
          element: arrowEl,
          padding: 8
        }),
        offset(() => (arrowEl.value ? 8 : 0)),
        size({
          apply({ availableWidth, elements }) {
            Object.assign(elements.floating.style, {
              maxWidth: `${Math.min(availableWidth, props.maxWidth)}px`
            })
          }
        })
      ]

      if (!props.virtualReference && props.inline) {
        result.push(inline())
      }

      result.push(flip())

      const viewportCheck = {
        name: 'isInViewport',
        async fn(state) {
          const overflow = await detectOverflow(state)
          return {
            data: {
              isOverFlow: overflow.top >= 0
            }
          }
        }
      }

      result.push(viewportCheck)

      return result
    })
    const options = toRefs(props)
    const reference = computed<ReferenceElement>(() => {
      if (props.virtualReference) {
        return {
          getBoundingClientRect() {
            return props.virtualReference
          },
          contextElement: props.virtualReferenceContext
        }
      }
      return referenceEl.value
    })
    const { x, y, strategy, placement, middlewareData, update } = useFloating(reference, floatingEl, {
      placement: options.placement,
      middleware
    })

    onMounted(() => {
      onClickOutside(referenceEl, (event) => {
        if (props.hover) return
        const floatingElement = floatingEl.value
        if (floatingElement && (floatingElement.contains(event.target) || referenceEl.value.contains(event.target))) {
          return
        }
        if (active.value) {
          deactivate()
        }
      })
    })

    function onEsc(e: KeyboardEvent) {
      if (!active.value) return
      e.stopPropagation()
      e.preventDefault()
      deactivate()
    }

    watch(visible, (val) => {
      if (stopAutoUpdate) {
        stopAutoUpdate()
        stopAutoUpdate = null
      }

      if (val) {
        stopAutoUpdate = autoUpdate(reference.value, floatingEl.value, update, {
          elementResize: props.elementResize
        })
      }
    })

    watch(active, (newVal) => {
      if (!newVal) return

      update()
    })

    return () => {
      const target = slots.default ? slots.default() : []
      const rootClass = 'trv-tooltip'
      let tooltipVisibility = true
      let boundToTarget = false

      return [
        target.map((vnode) => {
          if (
            vnode.type === Comment ||
            vnode.type === Text ||
            vnode.type === Static ||
            vnode.type === Suspense ||
            vnode.type === Fragment ||
            vnode.type === Teleport ||
            vnode.type === 'div'
          ) {
            if (props.hover && vnode.type === 'div' && vnode.children?.length && typeof vnode.children === 'string') {
              if (props.textLength && props.textLength < vnode.children.length) {
                vnode.children = vnode.children.substring(0, props.textLength) + '...'
              } else {
                tooltipVisibility = false
              }
            } else if (!props.hover) {
              return vnode
            }
          }

          if (boundToTarget || !tooltipVisibility) {
            return vnode
          }

          boundToTarget = true

          return h(vnode, {
            ref: referenceEl,
            'aria-describedby': id,
            title: undefined,
            ...(props.hover
              ? {
                  onMouseenter: activate,
                  onMouseleave: deactivate,
                  onFocus: activate,
                  onBlur: deactivate
                }
              : {
                  onClick: (e) => {
                    if (e.target.tagName.toLowerCase() === 'a') {
                      return
                    }
                    active.value ? deactivate() : activate()
                  },
                  onBlur: deactivate,
                  onKeydown: withKeys(onEsc, ['esc']) // todo: make sure to make this work, currently not working
                })
          })
        }),

        h(Teleport, { to: 'body' }, [
          h(
            'div',
            {
              ref: floatingEl,
              style: active.value
                ? {
                    position: strategy.value,
                    top: '0',
                    left: '0',
                    transform: `translate3d(${Math.round(x.value || 0)}px, ${Math.round(y.value || 0)}px, 0)`,
                    pointerEvents: active.value ? 'auto' : 'none',
                    zIndex: zIndex.value
                  }
                : {},
              'aria-hidden': !active.value,
              role: 'tooltip',
              id
            },
            [
              h(
                Transition,
                {
                  name: props.disableAnimation ? '' : '_transition',
                  mode: 'in-out',
                  onAfterLeave: () => {
                    visible.value = false
                  },
                  onBeforeEnter: () => {
                    visible.value = true
                  }
                },
                () => {
                  return withDirectives(
                    h(
                      'div',
                      {
                        style: {
                          maxWidth: props.maxWidth,
                          backgroundColor: '#ffffff',
                          color: '#6f6e71 !important',
                          borderRadius: '6px',
                          padding: '8px 10px',
                          fontWeight: 'normal',
                          fontFamily: 'Nunito',
                          fontStyle: 'normal',
                          fontSize: '14px',
                          boxShadow: '0 6px 30px -6px rgba(0, 0, 0, 0.25)',
                          wordBreak: 'break-word'
                        },
                        class: [rootClass, placement.value ? `_placement-${placement.value.split('-')[0]}` : undefined]
                      },
                      [
                        h('div', {
                          ref: arrowEl,
                          style: {
                            display: 'none',
                            position: 'absolute',
                            left: middlewareData.value.arrow?.x ? `${middlewareData.value.arrow?.x}px` : null,
                            top: middlewareData.value.arrow?.y ? `${middlewareData.value.arrow?.y}px` : null
                          },
                          class: `${rootClass}__arrow`
                        }),
                        h('div', { class: `${rootClass}__content` }, [(slots.content ? slots.content() : undefined) || props.content])
                      ]
                    ),
                    [[vShow, active.value]]
                  )
                }
              )
            ]
          )
        ])
      ]
    }
  }
})
</script>

<style lang="scss">
.trv-tooltiptooltip {
  $root: &;
  $bg-color: #9fa8cd;
  background-color: transparent;
  background: #ffffff;
  width: fit-content;
  height: fit-content;
  word-break: break-word;
  color: #4c4c66;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  white-space: pre-line;
  padding: 16px;
  border-radius: 6px;
  max-width: inherit;
  max-height: inherit;
  box-sizing: border-box;

  .trv-tooltip__content {
    pointer-events: auto;
  }

  &__arrow {
    position: absolute;
    width: 12px;
    height: 12px;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-style: solid;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  &._transition-enter-from,
  &._transition-leave-to {
    opacity: 0;
  }

  &._transition-enter-active,
  &._transition-leave-active {
    transition-property: opacity, transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }

  &._placement-left {
    #{$root}__arrow {
      left: 100%;

      &::before {
        border-width: 6px 0 6px 6px;
        border-color: transparent transparent transparent $bg-color;
        left: 0;
        top: 0;
      }
    }
  }

  &._placement-right {
    #{$root}__arrow {
      right: 100%;

      &::before {
        border-width: 6px 6px 6px 0;
        border-color: transparent $bg-color transparent transparent;
        right: 0;
        top: 0;
      }
    }
  }

  &._placement-top {
    #{$root}__arrow {
      top: 100%;

      &::before {
        border-width: 6px 6px 0 6px;
        border-color: $bg-color transparent transparent transparent;
        top: 0;
        left: 0;
      }
    }
  }

  &._placement-bottom {
    #{$root}__arrow {
      bottom: 100%;

      &::before {
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent $bg-color transparent;
        bottom: 0;
        left: 0;
      }
    }
  }
}
</style>
