<template>
  <div>
    <div v-if="props.viewType === DataViewType.Grid" class="frame">
      <PlaylistImage :source="getThumbnail()" class="packages" :border-radius="[6, 6, 6, 6]" @click.stop="openEditPackage(item)">
        <div class="info">
          <div>
            <div class="title">
              <Tooltip :placement="'top'" :content="item.package_name" :textLength="20" />
            </div>
            <div class="params">
              <Tooltip :placement="'top'" :content="item.content_owner_name" :textLength="20" />
            </div>
            <div class="params">
              <div>{{ getUTCDateString(item.created_at) }}</div>
            </div>
          </div>

          <div class="pendingbuttons">
            <button v-if="item.status !== 'active'" :class="`outline primary ${item.status === 'active' ? 'disabled' : ''}`" @click.stop="handleApproval(item.id, true)">
              {{ $t('accept') }}
            </button>
            <div v-else class="accepted">
              {{ $t('content-package-accepted') }}
            </div>
          </div>
        </div>
        <div class="menu-settings" @click.stop="null">
          <ActionButtons :actions="actions" :view-type="DataViewType.Grid" />
        </div>
      </PlaylistImage>
    </div>
    <div v-if="props.viewType === DataViewType.List" class="playlist list">
      <div ref="dataViewRow">
        <div class="content">
          <div>
            <div class="proposal grid" :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`" @click="openEditPackage(item)">
              <div class="videos" :class="shouldHideOnBreakpoint(props.columns, 'package-name')">
                <Tooltip :placement="'top'" :content="item.package_name" :textLength="20" />
              </div>
              <div class="container" :class="shouldHideOnBreakpoint(props.columns, 'content-creator-name')">
                <img class="profile-pic" :src="item.content_owner_profile_image || defaultProfile" alt="">
                <div class="name-container">
                  <div class="name">
                    <Tooltip :placement="'top'" :content="item.content_owner_name" :textLength="20" />
                  </div>
                </div>
              </div>
              <div v-if="item.status === 'active'" :class="shouldHideOnBreakpoint(props.columns, 'status')">
                <div class="activation approved">
                  {{ $t('content-package-accepted') }}
                </div>
              </div>
              <div v-if="item.status === 'pending'" :class="shouldHideOnBreakpoint(props.columns, 'status')">
                <div class="activation pending">
                  {{ $t('pending') }}
                </div>
              </div>
              <div class="sent-at" :class="shouldHideOnBreakpoint(props.columns, 'sent-at')">{{ getUTCDateString(item.created_at) }}</div>
              <div class="actions" :class="shouldHideOnBreakpoint(props.columns, 'actions')">
                <ActionButtons :actions="actions" :view-type="DataViewType.List" :is-visible="isRowHovered" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useElementHover } from '@vueuse/core'
import { CRUD, DataActions, DataType, DataViewType } from '@/interfaces'
import { useRouter } from 'vue-router'
import { getUTCDateString, openModalCustomConfirmation } from '@/services/utils'
import { Tooltip } from '@/components/common'
import PlaylistImage from '@/components/content/playlist/PlaylistImage.vue'
import videoIcon from '@/assets/content/fake_video.svg'
import defaultProfile from '@/assets/common/fake_profile.png'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { onMounted, ref, onBeforeMount, inject } from 'vue'
const viewportWidth = ref(window.innerWidth)
import { Proposal } from '@/services/content/package/types'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { ModalType } from '@/components/modals/modals-util'
import { apiService } from '@/services'
import { OFFERED_PACKAGES_ACTIONS_INJECTION_KEY } from '@/constants'

const router = useRouter()
const props = defineProps(['item', 'viewType', 'selected', 'columns'])
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const modalType = ModalType.OFFERED_PACKAGES_PACKAGE_MODAL
const dataActions = inject<DataActions>(OFFERED_PACKAGES_ACTIONS_INJECTION_KEY)

onMounted(() => {
  window.addEventListener('resize', updateViewportWidth)
})

onBeforeMount(() => {
  window.removeEventListener('resize', updateViewportWidth)
})

const updateViewportWidth = () => {
  viewportWidth.value = window.innerWidth
}
const actions = [
  {
    label: 'accept',
    handler: handleAccept,
    translationKey: 'content-package-accept',
    isDisabled: props.item.status === 'active'
  },
  {
    label: 'decline',
    handler: handleDecline,
    translationKey: 'content-package-decline',
    isDisabled: props.item.status === 'active'
  }
]

function handleAccept() {
  handleApproval(props.item.id, true)
}
function handleDecline() {
  handleApproval(props.item.id, false)
}

function openEditPackage(item: Proposal) {
  router.push({
    path: `/packages/offered-packages/edit/${item.id}`,
    name: 'ext-edit-package',
    params: {
      id: item.id
    }
  })
}

function getThumbnail() {
  return props.item.content_owner_profile_image || videoIcon
}

function handleApproval(id: string, state: boolean) {
  closeConfirm(id, state)
}

async function postApproval(id: string, state: boolean): Promise<void> {
  try {
    await apiService.contentPackage.postPublisherProposal(id, state)
    if (dataActions?.refreshData) dataActions.refreshData()
  } catch (error) {
    console.log(error)
  }
}

function closeConfirm(id: string, state: boolean) {
  openModalCustomConfirmation({ action: state ? CRUD.ConfirmAccept : CRUD.ConfirmDecline, type: DataType.OfferedPackages, onConfirm: () => postApproval(id, state) }, modalType)
}
</script>

<style lang="scss" scoped>
.icons-padding {
  padding: 5px;
}

.frame {
  border-radius: 6px;
  @include hoverFrameEffect;
}

.package-bottom {
  border: 1px solid $inputBorder;
  border-radius: 0px 0px 6px 6px;
  border-top: 0;

  .package-counts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0;
    padding: 24px 16px;
    column-gap: 32px;

    .count {
      display: flex;
      flex-direction: column;
      font-family: Nunito;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      justify-content: center;
      text-align: center;
      margin: 0;

      .count-title {
        color: var(--body-text, #4c4c66);
        /* P */
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.1px;
        text-transform: capitalize;
      }
    }
  }
}

.content {
  height: fit-content;
}

// .disabled-accept {
//   opacity: 0.2;
//   pointer-events: none;
// }

.top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  justify-content: flex-end;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #8a898c;
  }
}

.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.top-bar {
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    color: #c2c6cc;
    margin: 0 10px 0 10px;
    cursor: pointer;
    position: relative;

    .line {
      display: none;
      position: absolute;
    }

    &.selected {
      color: #4c4c66;
      font-weight: 700;

      .line {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #2c95ff;
      }
    }
  }
}

.pendingbuttons {
  display: flex;
  align-items: flex-start;
  justify-items: flex-end;

  button {
    margin: 0;
  }

  .accepted {
    // border-radius: 4px;
    // background-color: var(--secondary, #42C8A0);
    border-radius: 4px;
    border: 1px solid var(--status, #42c8a0);
    background: rgba(66, 200, 160, 0.1);
    color: #42c8a0;
    padding: 3px 8px;
    height: 30px;
    font-size: 14px;
    line-height: 23px;
    vertical-align: middle;
  }
}

.button-bar {
  display: grid;
  grid-template-columns: auto 213px 263px;
  align-items: center;
  margin-top: 10px;

  .search-buttons {
    display: flex;
    align-items: center;

    .filter {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      text-transform: capitalize;
      color: #9599c6;
      display: flex;
      cursor: pointer;
      width: 100px;
      margin: 0 30px 0 10px;
      position: relative;

      img {
        margin: 0 5px 0 5px;
      }

      .filterbox {
        width: 408px;
        height: 210px;
        position: absolute;
        background: #ffffff;
        border-radius: 8px;
        filter: drop-shadow(10px 12.6px 29px rgba(67, 66, 89, 0.1));
        z-index: 12;
        top: calc(100% + 5px);
      }
    }
  }

  button {
    width: 190px;
    justify-self: end;
  }

  .edit {
    background: none;
    width: 180px;
    padding: 0;
  }
}

.menu-settings {
  margin-top: 16px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  right: 0;

  // img {
  //   position: absolute;
  // }
}

.packages {
  width: 100%;
  padding-bottom: 60%;
  border-radius: 6px;
  background: #f2f4fa;
  cursor: pointer;

  img.image {
    border-radius: 6px;
  }

  &:hover {
    .view {
      opacity: 1;
    }
  }

  .info {
    padding: 15px;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 2px;
    }

    .params {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #ffffff;
      display: flex;
      justify-content: left;
      align-items: center;
      padding-top: 2px;

      .dot {
        display: flex;
        width: 19px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.list {
    width: 130px;
    padding-bottom: 0;
    height: 60px;
    border-radius: 6px;
    margin-right: 32px;
    cursor: pointer;
  }
}

.profile-pic {
  width: 145px;
  height: 145px;
  border-radius: 100%;
  border: 6px solid #dadbe8;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.button-bar {
  display: grid;
  grid-template-columns: auto 213px 263px;
  align-items: center;
  margin-top: 10px;

  .search-buttons {
    display: flex;
    align-items: center;

    .filter {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      text-transform: capitalize;
      color: #9599c6;
      display: flex;
      cursor: pointer;
      width: 100px;
      margin: 0 30px 0 10px;
      position: relative;

      img {
        margin: 0 5px 0 5px;
      }

      .filterbox {
        width: 408px;
        height: 210px;
        position: absolute;
        background: #ffffff;
        border-radius: 8px;
        filter: drop-shadow(10px 12.6px 29px rgba(67, 66, 89, 0.1));
        z-index: 12;
        top: calc(100% + 5px);
      }
    }
  }

  button {
    width: 190px;
    justify-self: end;
  }

  .edit {
    background: none;
    width: 180px;
    padding: 0;
  }
}

.search-input {
  width: 350px;
  position: relative;

  input {
    padding-left: 40px;
    border-radius: 30px;
  }

  img {
    position: absolute;
    top: 9px;
    left: 12px;
    cursor: pointer;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #dadbe8;
}

.grid {
  display: grid;
  grid-template-columns: 18% 21% 18% 15% auto;
  align-items: center;

  > div {
    padding: 1rem;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img:hover {
    filter: $truvid-filter-effect;
  }
}

.legend {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;
  text-transform: capitalize;

  /* body text */

  color: #4c4c66;
  margin-bottom: 20px;
}

.proposal {
  height: 80px;
  align-items: center;

  &:hover {
    background: #f3f4fa;
  }

  .status {
    background-color: #fff !important;
    border: 1px solid #9599c6;
    color: #9599c6 !important;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }

  .container {
    display: flex;
    align-items: center;

    img {
      height: 44px;
      width: 44px;
      border: 1px solid rgba(218, 219, 232, 1);
      border-radius: 100%;
      padding: 1px;
    }
  }

  .name-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #4c4c66;
  }

  .videos {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #4c4c66;
  }

  .related-tags {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 7px;

    .tag {
      background-color: #e8ebf4;
      border-radius: 2px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #4c4c66;
      padding: 2% 5%;
    }

    .tag-count {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      text-align: center;
      letter-spacing: 0.1px;
      text-transform: capitalize;

      /* body text */
      color: #4c4c66;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .video-count {
    border-radius: 100%;
    width: 27px;
    height: 27px;
    background-color: #8caeff;
    color: #fff;
    padding: 4px 10px;
    margin-right: 10px;
  }

  .activation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 20px;

    width: 97px;
    height: 28px;

    border-radius: 4px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.1px;
    text-transform: capitalize;

    /* dark gray */

    /* secondary */
    &.approved {
      width: 115px;
      height: 28px;

      background: rgba(66, 200, 160, 0.1);
      border-radius: 4px;
      color: #42c8a0;
    }

    &.pending {
      width: 115px;
      height: 28px;

      background: #f2f2f2;
      border-radius: 4px;

      color: #6f6e71;
    }
  }

  .status {
    width: 115px;
    height: 32px;

    background: #f2f2f2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.1px;
    text-transform: capitalize;

    /* dark gray */

    color: #6f6e71;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      color: rgb(62, 28, 39);
      background: rgba(254, 241, 246, 1);
    }
  }

  .trash {
    justify-self: right;
    cursor: pointer;
    margin-right: 20px;
  }

  .ads {
    width: 157px;
    height: 32px;
    border: 1px solid #9599c6;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #9599c6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      transform: translateY(2px);
    }
  }
}
</style>
