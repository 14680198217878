<template>
  <div class="modal-footer">
    <Button
      v-if="buttonConfirm" :variant="ButtonVariant.PRIMARY" :text="buttonConfirm" :disabled="disabled"
      @click="handleConfirm"
    />
    <Button
      v-if="buttonCancel" :variant="ButtonVariant.SECONDARY" :text="buttonConfirm" :disabled="disabled"
      @click="handleCancel"
    />
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/common/buttons/Button.vue'
import { CustomModalProps } from '@/interfaces'
import { ButtonVariant } from '@/styles/types'
import { useStore } from 'vuex'

const store = useStore()
defineProps<CustomModalProps>()

function handleConfirm() {
  console.log('component CONFIRM')
  store.dispatch('modal/clickConfirm')
}

function handleCancel() {
  console.log('component CANCEL')
  store.dispatch('modal/clickCancel')
}
</script>

<style scoped>
.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
</style>
