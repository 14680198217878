export enum UserRolesNames {
  AgencyOwner = 'agency_owner',
  ContentOwner = 'content_owner',
  PublisherOwner = 'publisher_owner',
  ContentEditor = 'content_editor',
  PublisherEditor = 'publisher_editor',
  ContentAccounting = 'content_accounting',
  PublisherAccounting = 'publisher_accounting'
}

export enum UserSection {
  Agency = 'agency',
  Content = 'content',
  Publisher = 'publisher'
}

export enum ResetPasswordStatus {
  COMPLETED = 'Completed'
}
