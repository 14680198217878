import { Component } from 'vue'
import { Commit } from 'vuex'

// in case i18n is needed in the future...
// import i18n from '@/i18n'
// i18n.global.t('some-translation', { someParam: someValue })

export type VueComponentContent = Component
export type VueComponentHeader = Component
export type VueComponentFooter = Component
export type VueComponentLoader = Component

export interface IModalProps {
  modalType: string | null
  componentContent: VueComponentContent | null
  componentHeader?: VueComponentHeader | null
  componentFooter?: VueComponentFooter | null
  componentLoader?: VueComponentLoader | null
  props?: Record<string, unknown>
}

export interface IModalState {
  modalType: string | null
  modalState: {
    componentContent: VueComponentContent | null
    componentHeader: VueComponentHeader | null
    componentFooter: VueComponentFooter | null
    componentLoader: VueComponentLoader | null
    props: Record<string, unknown>
  }
  loading: boolean
}

export interface IModalProps {
  modalType: string | null
  componentContent: VueComponentContent | null
  componentHeader?: VueComponentHeader | null
  componentFooter?: VueComponentFooter | null
  componentLoader?: VueComponentLoader | null
  props?: Record<string, unknown>
}

export interface IModalState {
  modalType: string | null
  modalState: {
    componentContent: VueComponentContent | null
    componentHeader: VueComponentHeader | null
    componentFooter: VueComponentFooter | null
    componentLoader: VueComponentLoader | null
    props: Record<string, unknown>
  }
  loading: boolean
}

const basicState = {
  componentContent: null,
  componentHeader: null,
  componentFooter: null,
  componentLoader: null,
  props: {}
}

export const modal = {
  namespaced: true,
  state: (): IModalState => ({
    modalState: basicState,
    loading: false,
    modalType: null
  }),
  mutations: {
    openModal(state: IModalState, payload: IModalProps) {
      const { props, modalType, componentContent, componentHeader, componentFooter, componentLoader } = payload
      state.loading = false
      state.modalType = modalType
      state.modalState = {
        componentContent,
        componentHeader: componentHeader || null,
        componentFooter: componentFooter || null,
        componentLoader: componentLoader || null,
        props: props || {}
      }
    },

    closeModal(state: IModalState) {
      state.modalState = basicState
      state.modalType = null
    },

    startLoading(state: IModalState) {
      state.loading = true
    },

    stopLoading(state: IModalState) {
      state.loading = false
    }
  },
  actions: {
    openModal({ commit }: { commit: Commit }, payload: IModalProps) {
      commit('openModal', payload)
    },
    closeModal({ commit }: { commit: Commit }) {
      commit('closeModal')
    },
    startLoading({ commit }: { commit: Commit }) {
      commit('startLoading')
    },
    stopLoading({ commit }: { commit: Commit }) {
      commit('stopLoading')
    }
  },
  getters: {
    getLoadingState: (state: IModalState) => () => {
      return state.loading
    },
    getModalType: (state: IModalState) => () => {
      return state.modalType
    }
  }
}
