export const TRUVID_TERMS_OF_SERVICE = 'https://truvid.com/terms/terms-of-service'
export const TRUVID_PRIVACY_POLICY = 'https://truvid.com/terms/privacy-policy'
export const TRUVID_INFO_WIDGET_TYPES = 'https://info.truvid.com/en/console-knowledge-base/what-types-of-widgets-are-there'
export const TRUVID_INFO_USER_TYPES =
  'https://info.truvid.com/_hcms/mem/login?redirect_url=https%3A%2F%2Finfo.truvid.com%2Fen%2Fconsole-knowledge-base%2Fwhat-are-the-different-user-types'
export const TRUVID_INFO_UPLOAD_YOUTUBE_CHANNEL =
  'https://info.truvid.com/_hcms/mem/login?redirect_url=https%3A%2F%2Finfo.truvid.com%2Fen%2Fconsole-knowledge-base%2Fhow-to-upload-your-youtube-channel'
export const TRUVID_INFO_PAIR_URL = 'https://info.truvid.com/en/console-knowledge-base/how-to-connect-a-url-to-a-video-in-your-video-content-library'
export const HELP_CENTER_PAGE_URL = 'https://info.truvid.com/en/console-knowledge-base'
export const SUPPORT_EMAIL = 'support@truvid.com'
export const HELP_CONNECT_URL_TO_VIDEO = '/how-to-connect-a-url-to-a-video-in-your-video-content-library'
export const SUPPORT_PAGE_URL = 'https://support.truvid.com/customerportal'
export const TRUVID_WEBSITE_LIBRARY = 'https://truvid.com/library'
export const TRUVID_BETASITE_LIBRARY = 'https://betasite.truvid.com/library'
export const TRUVID_GENERAL_FOLDER = 'general'
export const TRUVID_INPUT_NAME_MIN_LENGTH = 2
export const TRUVID_INPUT_NAME_MAX_LENGTH = 128
export const TRUVID_PROFILE_MAX_SIZE_USER_NAME = 18
export const TRUVID_INPUT_DESCRIPTION_MAX_LENGTH = 255
export const TRUVID_INPUT_PACKAGE_DESCRIPTION_MAX_LENGTH = 500
export const TRUVID_INPUT_TAGS_LIMIT = 5
export const TRUVID_INPUT_CATEGORIES_LIMIT = 5
export const TRUVID_ENGLISH_LANGUAGE_ID = 9
export const TRUVID_UNITED_STATES_COUNTRY_ID = 230
export const TRUVID_TITLE_MAX_LENGTH = 200
export const TRUVID_DESCRIPTION_MAX_LENGTH = 5000
export const TRUVID_CHANNEL_NAME_MAX_LENGTH = 300
export const TRUVID_URL_MAX_LENGTH = 256
export const TRUVID_REPORT_COLUMN_MAX_LENGTH = 18
export const TRUVID_FILTERS_COLLAPSED_WIDTH = 82
export const TRUVID_FILTERS_EXPANDED_WIDTH = 282
export const MAX_THUMBNAIL_SIZE = 5252880
export const MILLISECONDS_PER_SECOND = 1000
export const MOBILE_WIDTH = 768 // this should be the same as $md in global.css
export const TRUVID_WIDGET_MIDDLE_CENTER_INDEX = 5
export const TRUVID_MAX_FILTER_VIDEO_LENGTH = 5400200
export const TRUVID_MAX_SCREEN_SIZE = { height: 4320, width: 7680 }
export const TRUVID_MIN_WIDGET_DESKTOP = { width: 300, height: 169 }
export const TRUVID_MIN_WIDGET_MOBILE = { width: 256, height: 144 }
export const HTTP_STATUS_OK = 200
export const HTTP_STATUS_CREATED = 201
export const HTTP_STATUS_ACCEPTED = 202
export const HTTP_STATUS_NO_CONTENT = 204
export const HTTP_STATUS_BAD_REQUEST = 400
export const HTTP_STATUS_UNAUTHORIZED = 401
export const HTTP_STATUS_FORBIDDEN = 403
export const HTTP_STATUS_NOT_FOUND = 404
export const HTTP_STATUS_METHOD_NOT_ALLOWED = 405
export const HTTP_STATUS_CONFLICT = 409
export const HTTP_STATUS_GONE = 410
export const HTTP_STATUS_INTERNAL_SERVER_ERROR = 500
export const HTTP_STATUS_NOT_IMPLEMENTED = 501
export const HTTP_STATUS_BAD_GATEWAY = 502
export const HTTP_STATUS_SERVICE_UNAVAILABLE = 503
export const HTTP_STATUS_GATEWAY_TIMEOUT = 504

export const DEFAULT_DATA_VIEW_PAGE = 1

export const SUPPORTED_LANGUAGES_TEMP = [{ id: 9, label: 'English', name: 'English', code: 'en' }]

export const SUPPORTED_LANGUAGES = [
  { id: 4, label: 'Chinese', name: '中文', code: 'zh' },
  { id: 9, label: 'English', name: 'English', code: 'en' },
  { id: 27, label: 'Portuguese', name: 'Português', code: 'pt' },
  { id: 32, label: 'Spanish', name: 'Español', code: 'es' },
  { id: 20, label: 'Japanese', name: 'Japanese', code: 'ja' }
]

export const DATA_VIEW_SERVICE_ROUTES = ['embed', 'edit', 'add', 'share', 'view', 'accept-proposals', 'delete', 'duplicate', 'createplaylist', 'congratulations']

export const MIN_LAYOUT_BREAKPOINTS = ['sm', 'xs', 'md']
export const IGNORED_TOAST_ERROR_ENDPOINTS = ['/login']

export const AMERICA_NEW_YORK = 1

export const TRUVID_TIME_ZONES = [
  { name: 'America/Los_Angeles' }, // (PST)
  { name: 'America/New_York' }, // (EST)
  { name: 'America/Sao_Paulo' }, // (Brazil)
  { name: 'UTC' }, // (UK)
  { name: 'Asia/Taipei' }, // (CST)
  { name: 'Japan' } // (Japan)
]

export const TRUVID_SHARE_LINKS = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?text=',
  whatsapp: 'https://web.whatsapp.com/send?text=',
  email: 'mailto:?subject=Shared Content&body=',
  linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  pinterest: 'https://pinterest.com/pin/create/button/?url=${url}&media=${media}&description=${description}'
}

export const DEFAULT_CHANNEL_NAME = 'MyChannel'

export const NOT_AVAILABLE = 'N/A'

export const TRUVID_MIN_DATE = new Date(2019, 0, 1)

export const TRUVID_REGISTRATION_SERVER_FINAL_STEP = 0

export const TRUVID_REGISTRATION_STEPS = {
  REGISTRATION_STEP_0: 0,
  REGISTRATION_STEP_1: 1,
  REGISTRATION_STEP_2: 2,
  REGISTRATION_STEP_3: 3
}

export const DOMAIN_PLACEHOLDER = 'example.com'

export enum TRUVID_FILTER_OPTIONS {
  DURATION,
  CATEGORIES,
  UPLOAD_DATE,
  PRICING,
  LANGUAGES,
  CREATORS,
  CONTENT_TYPE,
  PACKAGES,
  FOLDER // currently only one folder supported per filter
}

export const VIDEO_EXTENSIONS = {
  MP4: 'mp4',
  MPEG: 'mpeg',
  WEBM: 'webm',
  OGG: 'ogg',
  AVI: 'avi',
  MKV: 'mkv',
  MOV: 'mov',
  WMV: 'wmv',
  HLS: 'm3u8',
  DASH: 'mpd'
}

export const PASTEL_COLORS = [
  { name: 'Pink', rgb: 'rgb(255, 182, 193)' },
  { name: 'LightSalmon', rgb: 'rgb(255, 160, 122)' },
  { name: 'Coral', rgb: 'rgb(255, 127, 80)' },
  { name: 'Gold', rgb: 'rgb(255, 215, 0)' },
  { name: 'LightGreen', rgb: 'rgb(144, 238, 144)' },
  { name: 'DarkTurquoise', rgb: 'rgb(0, 206, 209)' },
  { name: 'SteelBlue', rgb: 'rgb(127, 172, 213)' },
  { name: 'Lavender', rgb: 'rgb(230, 230, 250)' },
  { name: 'Pink', rgb: 'rgb(255, 192, 203)' }
]

export const TRUVID_CREATE_PLAYLIST_STATES = {
  ADD_VIDEOS: 1,
  SELECT_EXISTING_PLAYLISTS: 2,
  SELECT_CREATE_NEW_PLAYLIST: 3
}

export const GRAPHCOLORS = { line: '#3d5ea1', bar: '#27c2d7' }

export const greyIconColor = 'rgba(125, 129, 178, 1)'
