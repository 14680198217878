import { MOBILE_WIDTH } from '@/services/constants'
import { useEventListener } from '@vueuse/core'
import { Ref, ref } from 'vue'

interface UseViewPort {
  getCurrentBreakpoint: () => string
  isMobileLayout: Ref<boolean>
  width: Ref<number>
  height: Ref<number>
  getCurrentDivSize: (parentId: string) => { width: number; height: number }
  getCurrentViewPortWidth: () => number
}

interface ResponsiveDataBreakpoints {
  [key: number]: string
}

const responsiveDataBreakpoints: ResponsiveDataBreakpoints = {
  0: 'xs',
  576: 'sm',
  768: 'md',
  992: 'lg',
  1200: 'xl',
  1400: 'xxl',
  1600: 'xxxl',
  1900: 'hd'
}

export function useViewPort(): UseViewPort {
  const currentBreakpoint = ref<number>(findNearestBreakpoint(window.innerWidth))
  const isMobileLayout = ref<boolean>(window.innerWidth <= MOBILE_WIDTH)
  const width = ref(window.innerWidth)
  const height = ref(window.innerHeight)

  // Function to find the nearest breakpoint
  function findNearestBreakpoint(value: number): number {
    const breakpoints = Object.keys(responsiveDataBreakpoints)
      .map(Number)
      .sort((a, b) => a - b)
    let nearest = breakpoints[0]
    for (const breakpoint of breakpoints) {
      if (breakpoint <= value) {
        nearest = breakpoint
      } else {
        break
      }
    }
    return nearest
  }

  // Event listener to update current breakpoint and mobile layout status on window resize
  useEventListener(window, 'resize', () => {
    currentBreakpoint.value = findNearestBreakpoint(window.innerWidth)
    isMobileLayout.value = window.innerWidth <= MOBILE_WIDTH
  })
  // Function to get the current size of a specific div and its children individually
  function getCurrentDivSize(parentId: string): { width: number; height: number; childrenSizes: { [key: string]: { width: number; height: number } } } {
    const parent = document.getElementById(parentId)
    const childrenSizes: { [key: string]: { width: number; height: number } } = {}

    if (parent) {
      const observer = new MutationObserver(() => {
        // const sizes = calculateSize(parent)
      })
      observer.observe(parent, { subtree: true, childList: true, attributes: true })

      const children = parent.children
      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement
        const rect = child.getBoundingClientRect()
        childrenSizes[child.id || i.toString()] = {
          width: rect.width,
          height: rect.height
        }
      }

      const totalSize = calculateSize(parent)
      return {
        width: totalSize.width,
        height: totalSize.height,
        childrenSizes: childrenSizes
      }
    } else {
      return {
        width: 0,
        height: 0,
        childrenSizes: {}
      }
    }
  }

  function calculateSize(parent: HTMLElement): { width: number; height: number } {
    const children = parent.querySelectorAll('*')
    let totalWidth = 0
    let totalHeight = 0
    children.forEach((child) => {
      const rect = child.getBoundingClientRect()
      totalWidth += rect.width
      totalHeight += rect.height
    })
    return {
      width: totalWidth,
      height: totalHeight
    }
  }

  function getCurrentBreakpoint(): string {
    return responsiveDataBreakpoints[currentBreakpoint.value]
  }

  function getCurrentViewPortWidth(): number {
    return window.innerWidth
  }

  return {
    getCurrentBreakpoint,
    isMobileLayout,
    width,
    height,
    getCurrentDivSize,
    getCurrentViewPortWidth
  }
}
