<template>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div
        v-if="store.state.modal.modalState?.componentContent" class="modal-wrapper" aria-modal="true" role="dialog"
        tabindex="-1"
      >
        <ModalBase ref="modalContainer" v-bind="store.state.modal.modalState?.props" @close-action="closeModal">
          <template v-if="store.state.modal.modalState?.componentHeader" #custom-header>
            <component :is="store.state.modal.modalState?.componentHeader" v-bind="store.state.modal.modalState?.props" />
          </template>
          <template #custom-component>
            <component :is="store.state.modal.modalState?.componentContent" v-bind="store.state.modal.modalState?.props" />
          </template>
          <template v-if="store.state.modal.modalState?.componentFooter" #custom-footer>
            <component :is="store.state.modal.modalState?.componentFooter" v-bind="store.state.modal.modalState?.props" />
          </template>
        </ModalBase>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import ModalBase from './modal-structure/ModalBase.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
const modalContainer = ref()
const store = useStore()

onClickOutside(modalContainer, async (e) => {
  const target = e.target as HTMLElement
  if (target.className === 'modal-wrapper') {
    await closeModal()
  }
})

async function closeModal() {
  const modalProps = store.state.modal.modalState.props
  if (typeof modalProps.onCancel === 'function') {
    await modalProps.onCancel({ ...modalProps })
  }
  await store.dispatch('modal/closeModal')
}

onMounted(() => {
  // hide overflow if needed
  const body = document.body
  if (body) body.style.overflow = 'hidden'
})

onUnmounted(() => {
  // reset overflow to auto
  const body = document.body
  if (body) body.style.overflow = 'auto'
})
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;

  z-index: 999999;

  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.564);
  backdrop-filter: blur(3px);

  display: grid;
  place-items: center;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: 0.25s ease all;
}
</style>
