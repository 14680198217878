import qs from 'qs'
import type { Folder, FolderFilters, Folders } from '@/services/folder/types'
import api from '@/services/api'

const getFolders = async (filters: FolderFilters): Promise<Folders> => {
  const a = await api.get<Folders>('/folders', {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
  return a.data
}
const getFolder = async (id: number): Promise<Folder> => {
  const a = await api.get<Folder>(`/folder?folder_id=${id}`)
  return a.data
}
const postFolder = async (folderName): Promise<void> => {
  await api.post<void>('/folder', { folder_name: folderName })
}
const putFolder = async (folder: Folder): Promise<void> => {
  await api.put<void>('/folder', folder)
}
const deleteFolder = async (id: number): Promise<void> => {
  await api.delete<Folder>(`/folder/${id}`)
}

export default { getFolders, getFolder, postFolder, putFolder, deleteFolder }
