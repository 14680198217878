import { CheckboxGroupInput } from '@/interfaces'
import { TRUVID_MIN_DATE } from '@/services/constants'
import isValidDomain from 'is-valid-domain'

/* DEFINE CUSTOM VALIDATORS HERE AND IMPLEMENT IN CustomInputValidation file */

const atLeastNCheckbox = (n: number) => (value: CheckboxGroupInput[]) => {
  let counter = 0
  value.forEach((el: CheckboxGroupInput) => {
    el.selected ? counter++ : null
  })
  return counter >= n
}
const atLeastNOptions = (n: number) => (value: string[]) => {
  const counter = value.length
  return counter >= n
}
const isValidDate = (value: Date[] | string[]) => {
  return !isNaN(new Date(value[0]).getTime()) && !isNaN(new Date(value[1]).getTime())
}
const isValidDomainPattern = (value: string) => {
  return isValidDomain(value, { wildcard: true })
}

const parseAndValidateDate = (value: string): Date | null => {
  if (!value || value.length < 10) {
    return null
  }
  const dateParts = value.split('/')
  if (dateParts.length !== 3) {
    return null
  }
  const day = parseInt(dateParts[0], 10)
  const month = parseInt(dateParts[1], 10) - 1
  const year = parseInt(dateParts[2], 10);
  const parsedDate = new Date(year, month, day)
  if (isNaN(parsedDate.getTime())) {
    return null
  }
  if (
    parsedDate.getDate() === day &&
    parsedDate.getMonth() === month &&
    parsedDate.getFullYear() === year
  ) {
    return parsedDate
  }
  return null
}

const isValidStringDate = (value: string): boolean => {
  return !!parseAndValidateDate(value)
}

const isValidStringDateTodayOrOlder = (value: string): boolean => {
  const parsedDate = parseAndValidateDate(value)
  if (!parsedDate) {
    return false
  }
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return parsedDate.getTime() <= today.getTime()
}

const isValidStringDateLaterThanMinDate = (value: string): boolean => {
  const parsedDate = parseAndValidateDate(value);
  if (!parsedDate) {
    return false
  }
  return parsedDate.getTime() >= TRUVID_MIN_DATE.getTime()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notSameAs = (updatedValue: any) => (value: any) => {
  return updatedValue.toLowerCase() !== value.toLowerCase()
}

export { isValidDomainPattern, isValidStringDateTodayOrOlder, isValidStringDateLaterThanMinDate, atLeastNCheckbox, atLeastNOptions, isValidStringDate, isValidDate, notSameAs }
