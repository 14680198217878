<template>
  <MobileMenu v-if="!showDesktop" show-close="true" @close="close">
    <slot />
  </MobileMenu>
  <slot v-else />
</template>

<script setup lang="ts">
import MobileMenu from '@/components/common/mobile/MobileMenuContainer.vue'
import { MOBILE_WIDTH } from '@/services/constants'
import { onUnmounted } from 'vue'
const emits = defineEmits(['close'])
import { ref, onMounted } from 'vue'

function close() {
  emits('close')
}
const showDesktop = ref(false)

function setResize() {
  showDesktop.value = window.innerWidth > MOBILE_WIDTH
}

onMounted(() => {
  setResize()
  window.addEventListener('resize', setResize)
})

onUnmounted(() => {
  setResize()
  window.removeEventListener('resize', setResize)
})
</script>

<style lang="scss" scoped>
@media (max-width: $mobile_width) {
  .desktop {
    display: none;
  }
}
</style>
