import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'
import i18n from '@/i18n'

const packageCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'name',
      title: 'content-package-name',
      width: 25,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1,
      expand: true
    },
    {
      name: 'created_at',
      title: 'create-date',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 5,
      priority: 2,
      minWidthInPx: '150px'
    },
    {
      name: 'total_folders',
      title: 'content-package-folders',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: false, // not supported for now
      position: 2,
      priority: 3,
      minWidthInPx: '150px'
    },
    {
      name: 'total_videos',
      title: 'content-package-videos-number',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: false, // not supported for now
      position: 3,
      priority: 4,
      minWidthInPx: '150px'
    },
    {
      name: 'publishers_count',
      title: 'content-package-connected-publishers',
      width: 10,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 4,
      priority: 5,
      tooltip: i18n.global.t('content-package-connected-publishers-info')
    },
    {
      name: 'actions',
      title: '',
      width: 15,
      alignment: ListColumnAlignment.Center,
      position: 6,
      priority: 0
    }
  ]
})

export default packageCols
