import type { TipaltiLinks } from '@/services/billing/types'
import api from '@/services/api'

const getTipaltiLinks = async (): Promise<TipaltiLinks> => {
  const a = await api.get<TipaltiLinks>('/billing/tipalti/links')
  a.data.home = decodeURI(a.data.home)
  a.data.payment_history = decodeURI(a.data.payment_history)
  return a.data
}

export default {
  getTipaltiLinks
}
