import api from '@/services/api'
import { SUPPORTED_LANGUAGES_TEMP } from '@/services/constants'
import { CategoriesResponse, Countries, Languages, VideoPlayerData } from '@/services/general/types'
import { filterArrayByIds } from '@/services/utils'
import store from '@/store'
import { loadScript } from 'vue-plugin-load-script'

const getLanguages = async (params?: { supportedOnly?: false }): Promise<Languages> => {
  const languagesFromCache = store.getters['cache/getLanguages']()
  if (languagesFromCache.languages?.length) {
    if (params?.supportedOnly) {
      return Promise.resolve({
        languages: filterArrayByIds(
          languagesFromCache.languages,
          SUPPORTED_LANGUAGES_TEMP.map((language) => language.id)
        )
      })
    }
    return Promise.resolve(languagesFromCache)
  }

  const a = await api.get<Languages>('/languages')
  store.commit('cache/setLanguages', { languages: [...a.data.languages] })
  if (params?.supportedOnly) {
    a.data.languages = filterArrayByIds(
      a.data.languages,
      SUPPORTED_LANGUAGES_TEMP.map((language) => language.id)
    )
  }
  return a.data
}
const getCountries = async (): Promise<Countries> => {
  const countriesFromCache = store.getters['cache/getCountries']()
  if (countriesFromCache.countries?.length) {
    return Promise.resolve(countriesFromCache)
  }

  const a = await api.get<Countries>('/countries')
  store.commit('cache/setCountries', a.data)
  return a.data
}
const getCategories = async (): Promise<CategoriesResponse> => {
  const categoriesFromCache = store.getters['cache/getCategories']()
  if (categoriesFromCache.categories?.length) {
    return Promise.resolve(categoriesFromCache)
  }
  const a = await api.get<CategoriesResponse>('/categories')
  store.commit('cache/setCategories', a.data)
  return a.data
}
const getVideoPlayer = async (): Promise<boolean> => {
  const a = await api.get<VideoPlayerData>('/player/files')
  const link = `https://${a.data.js_cdn}/${a.data.player}`
  await loadScript(link)
  return true
}

export default {
  getLanguages,
  getCountries,
  getCategories,
  getVideoPlayer
}
