import { createI18n } from 'vue-i18n'

export const defaultLocale = 'en'

function loadLocaleMessages() {
  const locales = import.meta.glob('./locales/*.json', { eager: true })
  const messages = {}

  for (const path in locales) {
    const matched = path.match(/\/locales\/([A-Za-z0-9-_]+)\.json$/i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales[path].default
    }
  }
  return messages
}

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.VITE_APP_IS_PRODUCTION === 'false' ? false : import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

export default i18n
