<template>
  <div>
    <div class="`ci-container text">
      <div class="input-container" :class="{ error: !valid, ...getClassList() }" :for="customId">
        <img v-if="props.iconPre" class="icon" :src="props.iconPre" @click="handleIconAction(props.iconAction)">
        <input
          :id="customId"
          v-model="inputValueLocal"
          type="text"
          :class="{ 'lower-case': lowerCase }"
          :placeholder="placeholder"
          :disabled="isLoading || disabled"
          autocomplete="off"
          @input="handleEvents"
          @blur="validate"
          @keyup.enter="enter"
        >
        <img v-if="props.iconPost && displayPostIcon" :class="{ icon: props.iconAction }" :src="props.iconPost" @click="handleIconAction(props.iconAction)">
        <!-- <div v-if="props.isLoading" class="loader-container">
          <span class="loader"></span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { InputIconActionType } from '@/interfaces/index'
import { uid } from 'uid'

const customId = ref(uid())
const inputValueLocal = ref(null)
const displayPostIcon = computed(() => {
  if (props.iconAction === InputIconActionType.Clear) return !!inputValueLocal.value && !props.isLoading
  return true
})
const props = defineProps<{
  placeholder?: string
  label?: string
  valid?: boolean
  modelValue: string | number | boolean | object | null
  iconPre?: string | null
  iconPost?: string | null
  iconAction?: InputIconActionType | null
  classList?: string[] | null
  isLoading?: boolean | null
  disabled?: boolean | null
  lowerCase?: boolean
}>()

onMounted(() => {
  inputValueLocal.value = props.modelValue
})

watch(
  () => props.modelValue,
  () => {
    inputValueLocal.value = props.modelValue
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'iconActionEmit', value: string): void
  (e: 'validate')
  (e: 'enter')
  (e: 'blur')
}>()

function handleEvents() {
  emit('update:modelValue', inputValueLocal.value)
}

function validate() {
  emit('validate')
  emit('blur')
}

function enter() {
  emit('enter')
}

function handleIconAction(type: InputIconActionType) {
  switch (type) {
    case InputIconActionType.Clear:
      inputValueLocal.value = ''
      emit('update:modelValue', inputValueLocal.value)
      break
    case InputIconActionType.Log:
    default:
      break
  }
}

function getClassList(): { [key: string]: boolean } {
  const classes = { disabled: props.disabled || props.isLoading }
  if (!props.classList) return classes
  return { ...classes, ...Object.fromEntries(Object.entries(props.classList).map(([, v]) => [v, true])) }
}
</script>

<style scoped lang="scss">
.ci-container {
  position: relative;
}
.icon {
  cursor: pointer;

  .input-container.small & {
    transform: scale(0.8);
  }

  &:hover {
    filter: brightness(0.6);
  }
}

input.lower-case {
  text-transform: lowercase;
}

.loader-container {
  position: absolute;
  right: 15px;
  top: 9px;
  display: flex;
  .loader {
    width: 18px;
    height: 18px;
    border: 3px solid #9fa3cb;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
