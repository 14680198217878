<template>
  <div class="container">
    <div
      :class="{ 'checkbox-description-container': true, flex: true, gap: !separator, row: props.layout === InputLayout.Horizontal, mobile: props.layout === InputLayout.Mobile }"
    >
      <div v-for="(value, key, index) in inputValueLocal" :key="index" class="checkbox-container">
        <div :class="{ checkbox: true, indent: inputValueLocal[key].indent }">
          <label v-if="labelPosition === LabelPosition.PRE" :for="inputValueLocal[key].id">{{ inputValueLocal[key].label }}</label>
          <TextIconTooltip v-if="labelPosition === LabelPosition.PRE && inputValueLocal[key].tooltip" :tooltip="inputValueLocal[key].tooltip" />
          <input
            :id="inputValueLocal[key].id"
            v-model="inputValueLocal[key].selected"
            type="checkbox"
            :class="{ error: !valid, ...getClassList() }"
            :value="inputValueLocal[key].label"
            :disabled="disabled || inputValueLocal[key].disabled"
            :name="'group' + customId"
            @change="handleEvents($event, inputValueLocal[key])"
          >
          <label v-if="labelPosition === LabelPosition.POST" :for="inputValueLocal[key].id" :class="labelClass">{{ inputValueLocal[key].label }}</label>
          <TextIconTooltip v-if="labelPosition === LabelPosition.POST && inputValueLocal[key].tooltip" :tooltip="inputValueLocal[key].tooltip" />
        </div>
        <hr v-if="separator && key !== inputValueLocal.length - 1">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { InputLayout, InputIconActionType } from '@/interfaces/index'
import TextIconTooltip from '@/components/common/tooltips/TextIconTooltip.vue'
import { LabelPosition } from '@/components/common/custom-inputs/types'
import { uid } from 'uid'

const customId = ref(uid())
const inputValueLocal = ref(null)
const props = defineProps<{
  modelValue: string | number | boolean | object | null
  layout?: InputLayout
  iconPre?: string | null
  valid?: boolean
  classList?: string[] | null
  iconPost?: string | null
  iconAction?: InputIconActionType | null
  labelPosition?: LabelPosition | null
  separator?: boolean | null
  disabled?: boolean | null
  labelClass?: string
}>()

onMounted(() => {
  inputValueLocal.value = props.modelValue
})

watch(
  () => props.modelValue,
  () => {
    inputValueLocal.value = props.modelValue
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'iconActionEmit', value: string): void
  (e: 'validate')
}>()

function handleEvents(ev, element) {
  if (element.level === 1) {
    if (element.selected) {
      inputValueLocal.value.forEach((el) => {
        if (el.level === 2 && el.type === element.type) {
          el.selected = true
          el.disabled = true
        }
      })
    } else {
      inputValueLocal.value.forEach((el) => {
        if (el.level === 2 && el.type === element.type) {
          el.selected = false
          el.disabled = false
        }
      })
    }
  }

  emit('update:modelValue', inputValueLocal.value)
  emit('validate')
}

function getClassList(): { [key: string]: boolean } {
  const classes = { [props.labelPosition]: true }
  if (!props.classList) return classes
  return { ...classes, ...Object.fromEntries(Object.entries(props.classList).map(([, v]) => [v, true])) }
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex: 1;
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  &.indent {
    margin-left: 16px;
  }
}

.checkbox-container {
  width: 100%;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  &.row {
    flex-direction: row;
  }

  &.gap {
    gap: 8px;
  }
}

hr {
  opacity: 0.4;
  border: 1px solid #e5e5e5;
  margin: 1rem 0;
}

.checkbox-description-container {
  margin: 16px 8px;
}

label {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

input:disabled + label {
  cursor: default;
}

label + input:disabled {
  cursor: default;
}

.mobile {
  color: var(--dark-gray, #6f6e71);

  /* H4 */
  label {
    font-size: 16px;
    font-style: normal;
    flex-direction: column;
    width: calc(100% - 60px);
    display: inline-block;
    height: 100%;
    line-height: 70px;
  }

  .checkbox-container {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:not(:last-child) {
      border-bottom: 1px solid var(--bold-filed, #e8ebf4);
    }

    .checkbox {
      display: flex;
      width: 100%;
      height: 70px;
    }

    input {
      float: right;
      margin-left: 42px;
    }
  }
}
</style>
