export enum DashboardName {
  publisher_activity = 'publisher_activity',
  publisher_quality = 'publisher_quality',
  publisher_activity_top = 'publisher_activity_top',
  publisher_quality_top = 'publisher_quality_top',
  publisher_quality_info = 'publisher_quality_info',
  content_activity = 'content_activity',
  content_activity_top = 'content_activity_top',
  content_activity_info = 'content_activity_info',
  agency_accounts = 'agency_accounts',
  agency_activity = 'agency_activity',
  agency_activity_top = 'agency_activity_top',
  agency_quality_info = 'agency_quality_info',
  agency_quality = 'agency_quality',
  content_activity_most_viewed = 'content_activity_most_viewed'
}
