import { transfromReportsColumns, transfromReportsRows } from '@/services/utils'
import { ReportColumns, ReportCriteria, ReportTable, TableColumn } from '@/services/report/types'
import { ErrorMessage } from '@/interfaces'
import api from '@/services/api'

const getReportColumns = async (report_name: string): Promise<TableColumn[]> => {
  const a = await api.get<ReportColumns>(`/report/columns?report_name=${report_name}`)
  a.data.columns.forEach((c) => {
    c.is_selected = c.is_default
  })
  return transfromReportsColumns(a.data)
}
const checkReportStatus = async (): Promise<ErrorMessage> => {
  const a = await api.get<ErrorMessage>('/report/status')
  return a.data
}
const getReports = async (report_criteria: ReportCriteria, currentReport: string) => {
  const a = await api.post<ReportTable>('/report?report_name=' + currentReport, report_criteria)
  return transfromReportsRows(a.data)
}
const getReportsCSV = async (report_criteria: ReportCriteria, currentReport: string) => {
  report_criteria.download = true
  const a = await api.post<ReportTable>('/report?report_name=' + currentReport, report_criteria)
  return a.data.url
}

export default {
  getReportColumns,
  checkReportStatus,
  getReports,
  getReportsCSV
}
