<template>
  <div class="image_wrapper">
    <img
      v-if="!blank && currentSource.length > 0"
      loading="lazy"
      class="image original"
      :class="{ loaded: loaded }"
      :src="currentSource + '?' + uid()"
      alt="random image"
      :style="{ borderRadius: `${currentBorderRadius[0]}px ${currentBorderRadius[1]}px ${currentBorderRadius[2]}px ${currentBorderRadius[3]}px` }"
      @load="onImgLoad"
    >
    <div
      v-if="!(masks === 'false' || blank)"
      class="top-mask"
      :style="{ borderRadius: `${currentBorderRadius[0]}px ${currentBorderRadius[1]}px ${currentBorderRadius[2]}px ${currentBorderRadius[3]}px` }"
    />
    <div
      v-if="!(masks === 'false' || blank)"
      class="bottom-mask"
      :style="{ borderRadius: `${currentBorderRadius[0]}px ${currentBorderRadius[1]}px ${currentBorderRadius[2]}px ${currentBorderRadius[3]}px` }"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { uid } from 'uid'
import { onMounted, ref, watch } from 'vue'
const props = defineProps(['source', 'masks', 'blank', 'borderRadius'])
const currentSource = ref('')
const loaded = ref(false)
const currentBorderRadius = ref([6, 6, 6, 6])

onMounted(() => {
  loaded.value = false
  currentSource.value = props.source
  currentBorderRadius.value = props.borderRadius ? props.borderRadius : [6, 6, 6, 6]
})

function onImgLoad() {
  loaded.value = true
}

const computedSource = computed({
  get: () => {
    return props.source
  },
  set: (val) => {
    currentSource.value = val
  }
})
watch(computedSource, () => {
  currentSource.value = computedSource.value
})
</script>

<style lang="scss">
.image_wrapper {
  position: relative;
}
.top-mask {
  width: 100%;
  height: 40%;
  position: absolute;
  top: 0px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}

.bottom-mask {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 1s ease-out;
  object-fit: cover;
  &.loaded {
    opacity: 1;
  }
}
</style>
