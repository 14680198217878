<template>
  <div ref="dataViewRow" class="accounts" :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`" @click.stop="openEditAccount()">
    <Tooltip
      :placement="'top'" class="pl-2" :content="props.item.name" :textLength="30"
      :class="shouldHideOnBreakpoint(props.columns, 'name')"
    />
    <Tooltip :placement="'top'" :content="props.item.website ?? '---'" :textLength="25" :class="shouldHideOnBreakpoint(props.columns, 'website')" />
    <div class="roles" :class="shouldHideOnBreakpoint(props.columns, 'agency_account_type')">
      <div v-if="props.item.is_publisher" class="role">{{ $t('accounts-input-type-publisher') }}</div>
      <div v-if="props.item.is_content_owner" class="role">{{ $t('accounts-input-type-content') }}</div>
    </div>
    <div class="date" :class="shouldHideOnBreakpoint(props.columns, 'created_at')">{{ getDateString(props.item.created_at) }}</div>
    <div class="name" :class="shouldHideOnBreakpoint(props.columns, 'agency_fee')">{{ props.item.agency_fee }}%</div>
    <div class="accounts-actions-container" :class="shouldHideOnBreakpoint(props.columns, 'actions')">
      <ActionButtons :actions="actions" :is-visible="isRowHovered" />
      <AccountsSelectAccount :is-visible="isRowHovered" :org-id="item.organization_id" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Tooltip } from '@/components/common'
import { getDateString } from '@/services/utils'
import { useRouter } from 'vue-router'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { ref } from 'vue'
import { useElementHover } from '@vueuse/core'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import AccountsSelectAccount from './select/AccountsSelectAccount.vue'

const router = useRouter()
const props = defineProps(['item', 'columns'])
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const actions = [
  {
    label: 'edit',
    handler: openEditAccount,
    translationKey: 'edit'
  }
]

function openEditAccount() {
  router.push({ name: 'edit-account', params: { id: props.item.organization_id } })
}
</script>

<style lang="scss" scoped>
.approved {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.1px;
  text-transform: capitalize;
  background: rgba(50, 89, 255, 0.1);
  /* primary */
  min-width: 80px;
  border: 1px solid #2c95ff;
  color: #2c95ff;
  padding: 5px 21.5px;
  width: fit-content;
  border-radius: 4px;
  text-align: center;
}

.pending {
  background-color: #f2f2f2;
  color: #6f6e71;

  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.1px;
  text-transform: capitalize;
  /* primary */
  min-width: 120px;
  border: 1px solid #b4b4b4;
  padding: 5px 21.5px;
  width: fit-content;
  border-radius: 4px;
  text-align: center;
}

.roles {
  display: flex;
  flex-wrap: wrap;

  .role {
    white-space: nowrap;
    padding: 2px 8px;
    overflow: hidden;
    min-width: 160px;
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    text-transform: none;
    margin: 4px 8px 4px 0;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid var(--D2D5EC, #d2d5ec);
    color: var(--Body-text, #4c4c66);
  }
}

.content {
  padding: 30px;
  height: fit-content;
  background: linear-gradient(190.57deg, rgba(255, 255, 255, 0.92) 30.11%, #fdfcff 68.16%, rgba(255, 255, 255, 0.84) 90.02%);
  box-shadow: 10px 12.6px 52px rgba(126, 123, 160, 0.05);
  border-radius: 10px 10px 0px 0px;

  &.loading {
    min-height: 80vh;
  }
}

.top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2em;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #8a898c;
  }
}

.top-bar {
  display: flex;
  align-items: center;

  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    color: #c2c6cc;
    margin: 0 10px 0 10px;
    cursor: pointer;
    position: relative;

    .line {
      display: none;
      position: absolute;
    }

    &.selected {
      color: #4c4c66;
      font-weight: 700;

      .line {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #2c95ff;
      }
    }
  }
}

.button-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  .search-buttons {
    display: flex;
    align-items: center;

    .filter {
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      text-transform: capitalize;
      color: #9599c6;
      display: flex;
      cursor: pointer;
      width: 100px;
      margin: 0 30px 0 10px;
      position: relative;

      img {
        margin: 0 5px 0 5px;
      }

      .filterbox {
        width: 408px;
        height: 210px;
        position: absolute;
        background: #ffffff;
        border-radius: 8px;
        filter: drop-shadow(10px 12.6px 29px rgba(67, 66, 89, 0.1));
        z-index: 12;
        top: calc(100% + 5px);
      }
    }
  }

  button {
    width: 190px;
    justify-self: end;
  }

  .edit {
    background: none;
    width: 180px;
    padding: 0;
  }
}

.search-input {
  width: 350px;
  position: relative;

  input {
    padding-left: 40px;
    border-radius: 30px;
  }

  img {
    position: absolute;
    top: 9px;
    left: 12px;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #dadbe8;
}

.grid {
  display: grid;
  grid-template-columns: 21% 21% 21% 21% auto;
}

.legend {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.2px;
  text-transform: capitalize;

  /* body text */

  color: #4c4c66;
  margin-bottom: 20px;
}

.accounts {
  height: 80px;
  align-items: center;
  display: grid;
  grid-template-columns: 18% 18% 27% 14% 8% auto;

  &:hover {
    background: #f3f4fa;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }

  .status {
    width: 115px;
    height: 32px;

    background: #f2f2f2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.1px;
    text-transform: capitalize;

    /* dark gray */

    color: #6f6e71;
    display: flex;
    align-items: center;
    justify-content: center;

    &.approved {
      color: #5777ff;
      background: rgba(50, 89, 255, 0.3);
    }
  }

  .view {
    justify-self: right;
    cursor: pointer;
    margin-right: 20px;
  }

  .trash {
    justify-self: right;
    cursor: pointer;
    margin-right: 20px;
  }

  .ads {
    width: 157px;
    height: 32px;
    border: 1px solid #9599c6;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #9599c6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      transform: translateY(2px);
    }
  }
}

.actions {
  display: flex;
  justify-content: space-around;
  padding: 0 35px;

  img {
    margin: 0 15px;
  }
}

.accounts {
  height: 80px;
  align-items: center;

  &:hover {
    background: #f3f4fa;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    color: #4c4c66;
    opacity: 0.8;
  }

  .status {
    width: 115px;
    height: 32px;

    background: #f2f2f2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.1px;
    text-transform: capitalize;

    /* dark gray */

    color: #6f6e71;
    display: flex;
    align-items: center;
    justify-content: center;

    &.approved {
      color: #5777ff;
      background: rgba(50, 89, 255, 0.3);
    }
  }

  .trash {
    justify-self: right;
    cursor: pointer;
    margin-right: 20px;
  }

  .ads {
    width: 157px;
    height: 32px;
    border: 1px solid #9599c6;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    color: #9599c6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      transform: translateY(2px);
    }
  }
}

.accounts-actions-container {
  display: flex;
  align-items: center;
}
</style>
