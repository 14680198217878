import type { Domain } from '@/services/domain/types'

export interface Settings {
  domainToDelete: Domain
}

export const settings = {
  namespaced: true,
  state: () => ({
    domainToDelete: null
  }),
  mutations: {
    setDomainToDelete(state: Settings, domain: Domain) {
      state.domainToDelete = domain
    },
    logout(state) {
      state.domainToDelete = null
    }
  }
}
