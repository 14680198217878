export const enum ButtonVariant {
  PRIMARY = 'primary', // "Primary Blue" on Figma
  SECONDARY = 'secondary', // "Secondary Blue" on Figma
  TERTIARY = 'tertiary', // "Clickable 3th Blue" on Figma
  TERTIARY_GRAY = 'tertiary-gray', // "Clickable 3th Gray" on Figma
  LINK = 'link' // "Link Blue" on Figma
}

export const enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big'
}

export interface BaseButton {
  variant?: ButtonVariant
  size?: ButtonSize
  text?: string
  disabled?: boolean
}

export interface WithIconButton extends BaseButton {
  iconCmp: string
  isIconLeft: boolean
}
