<template>
  <div class="actions-container">
    <div v-if="isDotsMenu" class="actions" :class="{ 'is-visible': isVisible }">
      <TeleportTooltip
        v-for="action in actions" :key="action.label" :placement="'top'" :show-ellipsis="false"
        :content="$t(action.translationKey)"
      >
        <span class="flex pointer" :class="[{ disabled: action.isDisabled, 'is-hidden': action.isHidden }, action.label]" @click.stop="action.handler">
          <IconWrapper :class="{ 'is-loading': action.isLoading }" :name="getIconSrc(action)" />
        </span>
      </TeleportTooltip>
    </div>
    <DropdownComponent v-else @click.stop="null">
      <template #trigger>
        <img v-if="props.viewType === DataViewType.Grid" src="@/assets/playlists/playlist/menu.svg">
        <img v-else src="@/assets/common/graydotsmenu.svg">
      </template>
      <template #content="scopedProps">
        <div class="dropdown-background" @click="scopedProps.close()" />
        <div
          v-for="action in actions"
          :key="action.label"
          class="dropdown-item"
          :class="{ disabled: action.isDisabled }"
          @click.stop="
            () => {
              scopedProps.close()
              action.handler()
            }
          "
        >
          <IconWrapper class="dropdown-item-icon" :name="getIconSrc(action)" :alt="action.label" :class="{ 'is-loading': action.isLoading }" />
          <span class="style-font">{{ $t(action.translationKey) }}</span>
        </div>
      </template>
    </DropdownComponent>
  </div>
</template>

<script setup lang="ts">
import { TeleportTooltip, DropdownComponent } from '@/components/common'
import { useViewPort } from '@/composables'
import { DataViewType } from '@/interfaces'
import { computed } from 'vue'
import IconWrapper from '@/components/common/icons/IconWrapper.vue'

interface Action {
  label: string
  handler: () => void
  translationKey: string
  dropDownLabel?: string
  isDisabled?: boolean
  isHidden?: boolean
  isLoading?: boolean
}

const props = defineProps<{ actions: Action[]; isVisible?: boolean; isDotsMenu?: boolean; viewType?: DataViewType; isGreyIcon?: boolean; iconHeight?: number }>()
const { getCurrentBreakpoint } = useViewPort()
const isDotsMenu = computed(() => {
  if (props.viewType === DataViewType.Grid) {
    return false
  }
  const currentBreakpoint = getCurrentBreakpoint()
  if (props.actions.length <= 2) {
    return true
  } else {
    return currentBreakpoint === 'xxxl' || currentBreakpoint === 'xxl' || currentBreakpoint === 'hd'
  }
})

function getIconSrc(action: Action): string {
  if (action.dropDownLabel) {
    return actionIcons[action.dropDownLabel].iconSrc
  } else {
    return actionIcons[action.label].iconSrc
  }
}

const actionIcons = {
  edit: {
    iconSrc: 'editIcon'
  },
  embed: {
    iconSrc: 'embedIcon'
  },
  embedMenu: {
    iconSrc: 'embedIcon'
  },
  delete: {
    iconSrc: 'deleteIcon'
  },
  deletePlaylist: {
    iconSrc: 'deletePlaylist'
  },
  duplicate: {
    iconSrc: 'duplicateIcon'
  },
  share: {
    iconSrc: 'shareIcon'
  },
  reinvite: {
    iconSrc: 'reInviteIcon'
  },
  add: {
    iconSrc: 'addIcon'
  },
  refresh: {
    iconSrc: 'refreshIcon'
  },
  login: {
    iconSrc: 'loginIcon'
  },
  distribute: {
    iconSrc: 'distributeIcon'
  },
  accept: {
    iconSrc: 'acceptIcon'
  },
  decline: {
    iconSrc: 'declineIcon'
  },
  copy: {
    iconSrc: 'copyIcon'
  },
  close: {
    iconSrc: 'closeIcon'
  }
}
</script>

<style scoped lang="scss">
.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  visibility: hidden;
  &.is-visible {
    visibility: visible;
  }

  .is-hidden {
    display: none;
  }
  .refresh .is-loading {
    animation: spin 2s linear infinite;
  }
}

.actions-container {
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  &:hover {
    .actions {
      visibility: visible;
      opacity: 1;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown-item {
  display: flex;
  .dropdown-item-icon {
    padding-right: 16px;
  }
}

.dropdown-background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -55%);
  width: calc(100% + 4.3rem);
  height: calc(100% + 4rem);
  z-index: $actionButtonsZIndex;
}
</style>
