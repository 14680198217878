import { RouteRecordRaw } from 'vue-router'

const externalRoutes: RouteRecordRaw[] = [
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue'),
    meta: {
      title: 'register'
    }
  },
  {
    path: '/ui',
    name: 'ui',
    component: () => import('@/views/UI_elements.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'login'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/password/ForgotPassword.vue'),
    meta: {
      title: 'reset-password'
    }
  },
  {
    path: '/reset-password',
    name: 'resetpassword',
    component: () => import('@/views/password/ResetPassword.vue'),
    meta: {
      title: 'reset-password'
    }
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/views/password/ResetPassword.vue'),
    meta: {
      title: 'layout'
    }
  },
  {
    path: '/ui',
    name: 'ui',
    component: () => import('@/views/password/ResetPassword.vue'),
    meta: {
      title: 'ui'
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/Maintenance.vue'),
    meta: {
      title: 'maintenance'
    }
  },
  {
    path: '/signup-complete',
    name: 'signupcomplete',
    component: () => import('@/views/SignupComplete.vue'),
    meta: {
      title: 'sign up complete'
    }
  },
  {
    path: '/org-auth',
    name: 'org-auth',
    component: {
      template: '<div />'
    }
  }
]

export default externalRoutes
