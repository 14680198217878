import type { Widget } from '@/services/widget/types'
import { Widgets } from '@/services/widget/types'

export const widgets = {
  namespaced: true,
  state: () => ({
    widgets: [],
    totalWidgets: 0
  }),
  mutations: {
    setWidgets(state: Widgets, w: Widget[]) {
      state.widgets = w
    },
    setTotal(state: Widgets, total: number) {
      state.totalWidgets = total
    },
    logout(state: Widgets) {
      state.widgets = []
      state.totalWidgets = 0
    }
  },
  actions: {
    reset(context) {
      context.commit('setTotal', 0)
      context.commit('setWidgets', [])
    }
  }
}
