<template>
  <div class="icons-container" :class="{ disabled: disabled() }">
    <Tooltip :placement="'top'">
      <div class="icons" @click="changeView(DataViewType.Grid)">
        <div class="flex">
          <img :src="viewType === DataViewType.Grid ? IconFrameSelected : IconFrame" alt="">
        </div>
      </div>
      <template #content>
        <div class="style-font">{{ $t('view-type-frame') }}</div>
      </template>
    </Tooltip>
    <Tooltip :placement="'top'">
      <div class="icons" @click="changeView(DataViewType.List)">
        <img :src="viewType === DataViewType.List ? IconListSelected : IconList" alt="">
      </div>
      <template #content>
        <div class="style-font">{{ $t('view-type-list') }}</div>
      </template>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { DataViewType } from '@/interfaces'
import { Tooltip } from '@/components/common'
import IconFrame from '@/assets/playlists/frame.svg'
import IconFrameSelected from '@/assets/playlists/frame_selected.svg'
import IconList from '@/assets/playlists/list.svg'
import IconListSelected from '@/assets/playlists/list_selected.svg'

const emit = defineEmits(['viewChanged'])

interface Props {
  viewType: DataViewType
  disabled?: () => boolean
}

withDefaults(defineProps<Props>(), {
  viewType: DataViewType.Grid,
  disabled: () => false
})

const changeView = (newView: DataViewType) => {
  emit('viewChanged', newView)
}
</script>

<style scoped lang="scss">
.icons-container {
  display: flex;
  margin-left: 16px;
  height: 35px;
  min-width: 80px;
  width: 80px;
  justify-content: space-between;

  @media (max-width: $md) {
    display: none;
  }

  .icons {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
}
</style>
