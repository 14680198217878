<template>
  <div>
    <div class="ci-container textarea">
      <textarea
        :id="customId"
        v-model="inputValueLocal"
        :class="{ error: !valid, nofocus: disabled }"
        type="textarea"
        :placeholder="placeholder"
        :rows="rowCount"
        autocomplete="off"
        @input="handleEvents"
        @blur="validate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { uid } from 'uid'

const customId = ref(uid())
const inputValueLocal = ref(null)
const props = defineProps<{
  placeholder?: string
  label?: string
  valid?: boolean
  modelValue: string | number | boolean | object | null
  rowCount?: number
  disabled?: boolean
}>()

onMounted(() => {
  inputValueLocal.value = props.modelValue
})

watch(
  () => props.modelValue,
  () => {
    inputValueLocal.value = props.modelValue
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'iconActionEmit', value: string): void
  (e: 'validate')
}>()

function handleEvents() {
  emit('update:modelValue', inputValueLocal.value)
}

function validate() {
  emit('validate')
}
</script>

<style scoped lang="scss">
.ci-container {
  position: relative;
}

.nofocus {
  resize: none;
  pointer-events: none;
  input:focus {
    outline: none;
  }
}
</style>
