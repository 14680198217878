import type { Lead } from '@/services/lead/types'
import api from '@/services/api'

const getLead = async (lead: string): Promise<Lead> => {
  const a = await api.get<Lead>(`/lead?id=${lead}`)
  return a.data
}
const putLead = async (data): Promise<void> => {
  await api.put<Lead>('/lead', {
    ...data
  })
}

export default {
  getLead,
  putLead
}
