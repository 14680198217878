<template>
  <div>
    <div class="`ci-container text">
      <div class="input-container" :class="{ error: !valid, ...getClassList() }" :for="customId">
        <input
          :id="customId" v-model="inputValueLocal" :type="viewType" autocomplete="on"
          :placeholder="placeholder" @input="handleEvents" @blur="validate" @keyup.enter="enter"
        >
        <img :class="{ icon: true, active: viewType === 'text' }" src="@/assets/register/TogglePasswordView.svg" @click="togglePasswordView()">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { InputIconActionType } from '@/interfaces/index'
import { uid } from 'uid'

const customId = ref(uid())
const viewType = ref('password')
const inputValueLocal = ref(null)
const props = defineProps<{
  placeholder?: string
  label?: string
  valid?: boolean
  modelValue: string | number | boolean | object | null
  iconAction?: InputIconActionType | null
  classList?: string[] | null
}>()

onMounted(() => {
  inputValueLocal.value = props.modelValue
})

watch(
  () => props.modelValue,
  () => {
    inputValueLocal.value = props.modelValue
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | string | object | null): void
  (e: 'iconActionEmit', value: string): void
  (e: 'validate')
  (e: 'enter')
}>()

function handleEvents() {
  emit('update:modelValue', inputValueLocal.value)
}

function validate() {
  emit('validate')
}

function enter() {
  emit('enter')
}

function togglePasswordView(): void {
  if (viewType.value === 'password') {
    viewType.value = 'text'
  } else {
    viewType.value = 'password'
  }
}

function getClassList(): { [key: string]: boolean } {
  const classes = {}
  if (!props.classList) return classes
  return { ...classes, ...Object.fromEntries(Object.entries(props.classList).map(([, v]) => [v, true])) }
}
</script>

<style scoped lang="scss">
.ci-container {
  position: relative;
}
.icon {
  cursor: pointer;

  &:hover {
    filter: invert(51%) sepia(90%) saturate(7491%) hue-rotate(230deg) brightness(107%) contrast(101%);
  }

  &.active {
    filter: invert(51%) sepia(90%) saturate(7491%) hue-rotate(230deg) brightness(107%) contrast(101%);
  }
}

.loader-container {
  position: absolute;
  right: 15px;
  top: 9px;
  display: flex;
  .loader {
    width: 18px;
    height: 18px;
    border: 3px solid #9fa3cb;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
