import { DataViewType } from '@/interfaces'
import store from '@/store'
import { computed } from 'vue'
import type { ComputedRef } from 'vue'
export interface UseViewTypeReturn {
  viewType: ComputedRef<DataViewType>
  setViewType: (_viewType: DataViewType) => void
}

export function useViewType(): UseViewTypeReturn {
  const viewType = computed<DataViewType>(() => store.getters['cache/getViewType']())

  function setViewType(_viewType: DataViewType) {
    store.dispatch('cache/setViewType', _viewType)
  }

  return {
    viewType,
    setViewType
  }
}
