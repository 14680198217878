<template>
  <div ref="dataViewRow" class="widget" @click.prevent="editStream">
    <div class="content grid" :style="`grid-template-columns:  ${columnsGridTemplate(props.columns, true)};`">
      <div class="title" :class="shouldHideOnBreakpoint(props.columns, 'title')">
        <Tooltip :placement="'top'" :content="title" :textLength="15" @click.prevent="editStream" />
      </div>
      <!-- <div class="url lowercase">
          <Tooltip :placement="'top'">
            <div class="flex">
              {{ shortenText(item.url, 15) }}
            </div>
            <template #content>
              <div class="style-font">{{ item.url }}</div>
            </template>
          </Tooltip>
        </div> -->
      <div class="date" :class="shouldHideOnBreakpoint(props.columns, 'created_date')">{{ createdDate }}</div>
      <div class="date" :class="shouldHideOnBreakpoint(props.columns, 'last_run')">{{ lastRun }}</div>
      <div class="date" :class="shouldHideOnBreakpoint(props.columns, 'last_run_no_of_cols')">{{ lastRunNumOfErrors }}</div>
      <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'exposure')">
        <div
          class="activation"
          :class="{
            active: item.is_public === true,
            inactive: item.is_public === false
          }"
        >
          <div>{{ $t('settings-sources-public-' + item.is_public) }}</div>
        </div>
      </div>
      <div class="folder" :class="shouldHideOnBreakpoint(props.columns, 'folder')">
        <Tooltip
          v-if="folderName !== '---'" :placement="'top'" :content="folderName" :textLength="15"
          @click.prevent="editStream"
        />

        <div v-else class="flex">
          {{ shortenText(folderName, 15) }}
        </div>
      </div>
      <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'category')">
        <div class="category category-bg">{{ categoryName }}</div>
      </div>
      <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'type')">
        <div class="type">{{ $t('settings-sources-type-' + item.type) }}</div>
      </div>
      <div class="head" :class="shouldHideOnBreakpoint(props.columns, 'status')">
        <div class="activation" :class="determineStatus(item.approved, item.is_active)">
          <div>{{ $t('settings-sources-active-' + determineStatus(item.approved, item.is_active)) }}</div>
        </div>
      </div>
      <div class="d-flex flex-xs-justify-end" :class="shouldHideOnBreakpoint(props.columns, 'actions')">
        <ActionButtons :actions="actions" :is-visible="isRowHovered" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Tooltip } from '@/components/common'
import { getDateString, openModalCustomConfirmation, shortenText } from '@/services/utils'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { computed, inject, ref } from 'vue'
import { useElementHover } from '@vueuse/core'
import { CRUD, DataActions, DataType } from '@/interfaces'
import { VIDEO_ACTIONS_INJECTION_KEY } from '@/constants'
import { ModalType } from '@/components/modals/modals-util'
import { apiService } from '@/services'

const props = defineProps(['item', 'columns'])
const { t } = useI18n()
const folderName = computed(() => props.item.folder?.name ?? '---')
const categoryName = computed(() => (props.item.category?.name ? t(`category_${props.item.category?.id}`) : '---'))
const title = computed(() => props.item.title ?? '---')
const lastRun = computed(() => (props.item?.last_run ? getDateString(props.item.last_run) : '---'))
const lastRunNumOfErrors = computed(() => (props.item?.last_run || props.item?.last_run_feed_status_valid ? props.item?.last_run_number_of_errors : '---'))
const createdDate = computed(() => (props.item?.created_date ? getDateString(props.item.created_date) : '---'))
const router = useRouter()
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)
const actions = [
  {
    label: 'edit',
    handler: editStream,
    translationKey: 'edit'
  },
  {
    label: 'delete',
    handler: openDeleteStream,
    translationKey: 'stream-delete'
  }
]
const modalType = ModalType.STREAMS_DELETE_STREAM_MODAL
const dataActions = inject<DataActions>(VIDEO_ACTIONS_INJECTION_KEY)
const deleteStream = async (params) => {
  return apiService.videos.deleteStream(params.id).then(() => {
    if (dataActions?.refreshData) dataActions.refreshData()
  })
}

function editStream() {
  router.push({
    path: `/videos/content-source/edit/${props.item.id}`,
    name: 'cd-edit-stream',
    params: { id: props.item.id }
  })
}

function determineStatus(approved, isActive) {
  if (approved === false) {
    return 'pending'
  } else if (approved === true && isActive === true) {
    return 'active'
  } else if (approved === true && isActive === false) {
    return 'inactive'
  }
}

function openDeleteStream() {
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.ContentSources, id: props.item.id, onConfirm: deleteStream }, modalType)
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.approved {
  width: 100%;
}

.head {
  padding: 16px;
}

.grid {
  display: grid;
  grid-template-columns: 12% 9% 9% 7% 9% 10% 12% 10% 9% 7% auto;

  > div {
    display: flex;
    align-items: center;
    padding-inline: 1rem;
  }
}

.activation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 20px;

  width: 97px;
  height: 28px;

  border-radius: 4px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.1px;
  text-transform: capitalize;

  /* dark gray */

  /* secondary */
  &.active {
    width: 112px;
    height: 28px;

    background: rgba(66, 200, 160, 0.1);
    border-radius: 4px;
    color: #42c8a0;
  }

  &.inactive {
    width: 110px;
    height: 28px;

    background: #f2f2f2;
    border-radius: 4px;

    color: #6f6e71;
  }
  &.pending {
    width: 110px;
    height: 28px;

    background: #f2f2f2;
    border-radius: 4px;

    color: #6f6e71;
  }
}

.widget {
  .content {
    align-items: center;
    font-style: normal;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #4c4c66;
    box-sizing: border-box;
    text-overflow: ellipsis;
    height: 60px;
    //opacity: 0.8;
    // padding: 0 71px;

    // .title {
    //text-transform: capitalize;
    // }

    .type {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      //padding: 3px 20px;
      border-radius: 4px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      letter-spacing: 0.1px;
      width: 130px;
      height: 28px;

      background: #f2f2f2;
      border-radius: 4px;

      color: #6f6e71;
      white-space: nowrap;
    }
  }

  @media (max-width: $xl) {
    height: auto;
    padding: 10px 20px;
  }

  @media (max-width: $lg) {
    height: auto;
    padding: 10px 20px;
  }

  @media (max-width: $md) {
    height: auto;
    padding: 10px 20px;
  }

  .device-type {
    border: 1px solid $inputBorder;
    border-radius: 4px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    text-transform: capitalize;
    /* body text */
    color: #4c4c66;
    opacity: 0.7;
    padding: 4px 12px;
    margin-right: 8px;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    white-space: nowrap;
  }

  // background-color: #f3f4fa;
  &:hover {
    background-color: #f3f4fa;
  }
}

.category-bg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  height: 28px;
  white-space: nowrap;
}
</style>
