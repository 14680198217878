import { CRUD, DataType } from '@/interfaces'
import type { RouteRecordRaw } from 'vue-router'

const accountsRoutes: RouteRecordRaw[] = [
  {
    path: 'edit/:id',
    name: 'edit-account',
    component: () => import('@/components/accounts/edit/AccountsEditAccount.vue'),
    meta: {
      title: 'accounts'
    },
    children: [
      {
        path: 'closeunsaved',
        name: 'edit-closeUnsavedAgencyUser',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'accounts'
        },
        props: { type: DataType.AgencyAccounts, action: CRUD.ConfirmClose }
      }
    ]
  },
  {
    path: 'add',
    name: 'add-account',
    component: () => import('@/components/accounts/add/AccountsAddAccount.vue'),
    meta: {
      title: 'accounts',
      fullscreen: true
    }
  }
  // {
  //   path: 'delete/:id',
  //   name: 'delete-account',
  //   component: () =>
  //     import('../../components/dashboard/accounts/AccountsDeleteAccount.vue'),
  //   props: true,
  //   meta: {
  //     title: 'accounts',
  //   },
  // },
]

export default accountsRoutes
