import i18n from '@/i18n'
import api from '@/services/api'
import type { LoggedInUserRoles, LoginUser, User, UserInfo, UserInfoResponse, Users, UserTokens } from '@/services/organization-user/types'
import type { DataViewFilters } from '@/services/types'
import { removeEmptyItems } from '@/services/utils'
import type { CustomAxiosError } from '@/interfaces'
import store from '@/store'
import { AxiosError } from 'axios'
import { TodoList } from '@/interfaces'

const login = async (user: { email: string; password: string }): Promise<LoginUser> => {
  const a = await api.post<LoginUser>('/login', user)
  return a.data
}
const forgotPassword = async (email: string): Promise<void> => {
  await api.delete<UserInfo>('/user/password', { data: { email: email } })
}
const resetPassword = async (userId: number, token: string, password: string): Promise<{ status: string } | CustomAxiosError> => {
  const res = await api.put<{ status: string }>('/user/password', {
    user_id: userId,
    token: token,
    password: password
  })
  return res.data
}
const checkToken = async (user_id: number): Promise<boolean> => {
  const a = await api.get<UserInfo>('/user', { params: { user_id } })
  return a.status < 300
}
const refreshToken = async (refresh_token: number): Promise<UserTokens> => {
  const a = await api.post<UserTokens>('/refresh_token', { refresh_token })
  return a.data
}
const getUserInfo = async (user_id: number): Promise<UserInfoResponse> => {
  const a = await api.get<UserInfoResponse>('/user', { params: { user_id } })
  return a.data
}
const getAvailableRoles = async (): Promise<LoggedInUserRoles> => {
  const availableRolesFromCache = store.state.user.mode === 'user' ? store.getters['cache/getAvailableRoles']() : store.getters['cache/getAvailableRolesAgency']()
  if (availableRolesFromCache.available_roles?.length) {
    return Promise.resolve(availableRolesFromCache)
  }
  const a = await api.get<LoggedInUserRoles>('/user/available_roles')
  store.state.user.mode === 'user' ? store.commit('cache/setAvailableRoles', a.data) : store.commit('cache/setAvailableRolesAgency', a.data)
  return a.data
}
const getUsers = async (params: DataViewFilters): Promise<Users> => {
  const a = await api.get<Users>('/users', { params })
  return a.data
}
//todo connect api when ready
const getUserTodoList = async (): Promise<TodoList> => {
  return {
    accountCreated: false,
    domainsVisited: false,
    copyADStxt: false,
    checkADStxt: false,
    billingInfoAvailable: false,
    reportsVisited: false,
    accountLogin: false,
    domainCreated: false,
    playlistCreated: false,
    widgetCreated: false,
    embedCodeCopied: false,
    videoUploaded: false,
    sourceImported: false,
    profileUpdated: false,
    packageCreated: false,
    packageDistributed: false
  }
  // const a = await api.get<Users>(process.env.VUE_APP_API_URL + '/users', { params })
  // return a.data
}
//todo connect api when ready
const putUserTodoList = async (params: TodoList): Promise<TodoList> => {
  // const a = await api.get<Users>(process.env.VUE_APP_API_URL + '/users', { params })
  // return a.data
  return params
}
const postUser = async (
  first_name: string,
  last_name: string,
  email: string,
  language_id: number,
  country_id: number,
  phone_number: string,
  roles: number[]
): Promise<User | AxiosError> => {
  const putBody = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    language_id: language_id,
    country_id: country_id,
    phone_number: phone_number,
    roles: roles
  }

  try {
    const res = await api.post<User>('/user', putBody)
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('add-user-message-success'),
      type: 'success'
    })
    return res.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    throw error as AxiosError
  }
}
const postUserReInvite = async (data): Promise<void> => {
  try {
    await api.post('/user/resend_invite', { ...data })
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('reinvite-success'),
      type: 'success'
    })
  } catch (error) {
    if (error.message) {
      store.dispatch('messages/addMessage', { message: error.message, type: 'error' })
    } else {
      store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    }
  }
}
const putUser = async (data): Promise<void> => {
  try {
    const a = await api.put<User>('/user', { ...data })
    if (a.data?.user) {
      store.commit('user/setUserInfo', a.data.user)
    }
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
  }
}
const putUserSettings = async (
  user_id: number,
  new_email: string,
  first_name: string,
  last_name: string,
  language_id: number,
  country_id: number,
  timezone: string,
  phone_number: string,
  is_active: boolean,
  roles: number[]
): Promise<User> => {
  const putBody = {
    user_id: user_id,
    new_email: new_email,
    first_name: first_name,
    last_name: last_name,
    language_id: language_id,
    country_id: country_id,
    timezone: timezone,
    phone_number: phone_number,
    is_active: is_active,
    roles: roles
  }

  if (user_id != store.state.user.user.id) {
    removeEmptyItems(putBody)
  } else {
    removeEmptyItems(putBody, ['is_active'])
  }

  try {
    const a = await api.put<User>('/user', putBody)
    return a.data
  } catch (error) {
    await store.dispatch('messages/addMessage', { message: error, type: 'error' })
  }
}
const getUserAccessToken = async (user_id: number, invitation_code: string): Promise<LoginUser> => {
  const a = await api.post<LoginUser>('/user/access_token', {
    user_id,
    invitation_code
  })
  return a.data
}
const updateUserPassword = async (userId: number, password: string) => {
  try {
    const a = await api.put('/user', {
      user_id: userId,
      new_password: password
    })

    store.dispatch('messages/addMessage', {
      message: i18n.global.t('password-change-success'),
      type: 'success'
    })
    return a.data
  } catch (e) {
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('password-change-fail'),
      type: 'error'
    })
  }
}

export default {
  login,
  forgotPassword,
  resetPassword,
  checkToken,
  refreshToken,
  getUserInfo,
  getUsers,
  postUser,
  postUserReInvite,
  putUser,
  putUserSettings,
  getUserAccessToken,
  getAvailableRoles,
  updateUserPassword,
  putUserTodoList,
  getUserTodoList
}
