<template>
  <div class="legend grid">
    <div>
      <div class="padded">
        <div
          :class="expanded && expandedItem === props.item.domain.id ? 'domain flex expanded' : 'domain grid'"
          :style="`grid-template-columns: ${columnsGridTemplate(props.columns)}`"
        >
          <div class="domain-name" :style="`grid-template-columns: ${columnsGridTemplate(props.columns)}`" @click="expand(props.item.domain.id)">
            <div class="menu-arrow-container">
              <div class="arrow-container ml-2">
                <img :class="{ 'menu-arrow': true, expanded: expandedItem }" src="@/assets/common/chevron-up.svg" alt="">
              </div>
              <div class="domain-name-container">{{ props.item.domain?.domain || '' }}</div>
            </div>
            <!-- <div v-if="expanded && expandedItem == props.item.domain.id" class="total-circle">
              {{ props.item.urls.length }}
            </div> -->
            <div v-if="expanded && expandedItem == props.item.domain.id" class="date">
              {{ getUTCDateString(props.item.domain?.create_date) }}
            </div>
          </div>
          <div v-if="expanded && expandedItem == props.item.domain.id" class="line" />

          <div v-if="expanded && expandedItem == props.item.domain.id" :class="`urls ${expanded && expandedItem == props.item.domain.id ? 'expanded' : ''}`">
            <!-- <span v-if="!expanded || expandedItem != props.item.domain.id" class="total-circle-out">{{ props.item.urls.length }}</span> -->
            <div v-if="expanded && expandedItem == props.item.domain.id ? 'hidden' : ''">
              <div v-for="(url, index) in props.item.urls" :key="url.id" class="detail-row" @click.stop="openVideoDetails(url)">
                <div :class="`details ${!(index % 2) ? '' : 'detail-bg'}`">
                  <div class="date" />
                  <div class="date">
                    {{ index + 1 }}
                  </div>
                  <div class="date">
                    <Tooltip :placement="'top'" :content="url.url" :textLength="20" />
                  </div>
                  <div class="date">{{ getUTCDateString(url.video?.upload_date) }}</div>
                  <div class="name expanded">
                    <VideoComponent
                      :item="url.video"
                      :hover-animation="false"
                      :with-title-events="false"
                      :remove-add-button="true"
                      :interactive="false"
                      :show-time="false"
                      :show-ownership="false"
                      :show-top-text="false"
                      :default-cursor="false"
                      class="videocomponent mr-4"
                    />
                    <Tooltip :placement="'top'" :content="props.item.domain.video?.title" :textLength="20" />
                  </div>
                  <ActionButtons :actions="[{ label: 'delete', handler: () => openDeleteUrl(url), translationKey: 'delete' }]" :is-visible="true" :is-dots-menu="false" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="!expanded || expandedItem != props.item.domain.id" class="date">
            {{ getUTCDateString(props.item.domain?.create_date) }}
          </div>
          <!-- <div v-if="!expanded || expandedItem != props.item.domain.id" class="name">
            <Tooltip :placement="'top'" :content="props.item.domain?.video?.title" :textLength="20" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'
import { getUTCDateString } from '@/services/utils'
import { Tooltip } from '@/components/common'
import { useRouter } from 'vue-router'
import VideoComponent from '@/components/base/VideoComponent.vue'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { columnsGridTemplate } from '@/services/layoutUtils'
import { openModalCustomConfirmation } from '@/services/utils'
import { ModalType } from '@/components/modals/modals-util'
import { CRUD, DataActions, DataType } from '@/interfaces'
import { apiService } from '@/services'
import { INVENTORY_PAIRED_URLS_INJECTION_KEY } from '@/constants'

// todo gilad

const router = useRouter()
const expanded = ref(false)
const props = defineProps(['item', 'columns'])
const expandedItem = ref('')
const modalType = ModalType.PAIRED_URLS_DELETE_PAIR_URL_MODAL
const dataActions = inject<DataActions>(INVENTORY_PAIRED_URLS_INJECTION_KEY)

function openDeleteUrl(url) {
  openModalCustomConfirmation({ action: CRUD.Unpair, type: DataType.PairedUrls, onConfirm: () => deleteUrl(url) }, modalType)
}

async function deleteUrl(url) {
  const res = await apiService.content.deleteContentTargeting([url.id])
  if (res && dataActions?.refreshData) dataActions.refreshData()
}

function openVideoDetails(url) {
  router.push({
    name: 'view-targeting-url-video-details',
    params: { id: url.video_id }
  })
}

function expand(id) {
  expanded.value = false
  if (expandedItem.value === id) {
    expandedItem.value = ''
    expanded.value = false
  } else {
    expanded.value = !expanded.value
    expandedItem.value = id
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: 20px;
  padding: 20px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video {
  height: 70px;
  border-radius: 13px;
}

.padded {
  width: 100%;
}

.fit-parent {
  min-height: 75vh;
}

.grid {
  display: grid;
  grid-template-columns: 35% 15% 40% auto;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}

.loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.domain {
  &:hover {
    background: #f3f4fa;
  }

  .date {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #4c4c66;
    padding: 32px 16px;
  }

  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #4c4c66;
    padding: 16px;

    &.expanded {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .videocomponent {
        width: 110px !important;
        height: 62px !important;
      }
    }
  }

  .detail-row {
    cursor: pointer;

    & > div {
      display: grid;
      grid-template-columns: 2% 3% 20% 25% 40% auto;
      align-items: center;
    }
  }

  .detail-bg {
    background: #f3f4fa;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #dadbe8;
}

.legend {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.2px;
  /* body text */

  color: #4c4c66;
}

.domain-name {
  display: grid;
  align-items: center;
  flex-direction: row;
  grid-template-columns: 35% 15% 40% auto;
  width: 100%;
  cursor: pointer;

  .menu-arrow-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    display: flex;

    img.menu-arrow {
      cursor: pointer;
      transform: rotate(90deg);
      transition: all linear 0.2s;

      &.expanded {
        transform: rotate(180deg);
      }
    }

    > div.arrow-container {
      width: 11px;
    }

    .domain-name-container {
      margin-left: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.1px;
      color: #4c4c66;
      opacity: 0.8;
    }
  }
}

.total-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  width: 1em;
  margin-left: 1.5em;
  height: 1em;
  background: #7d81b2;
  border-radius: 50%;
  color: white;
  font-weight: 400;
  font-size: 14px;
}

.total-circle-out {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  width: 1em;
  margin-left: 1.5em;
  height: 1em;
  background: #7d81b2;
  border-radius: 50%;
  color: white;
  font-weight: 400;
  font-size: 10px;
}

.urls {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.expanded {
    .total-circle {
      margin-left: 0;
    }
  }

  & > div:not(.hidden) {
    width: 100%;
  }

  .header {
    font-weight: 700;
  }
}

.expanded {
  height: fit-content;

  .total-circle {
    margin-left: 0;
  }

  &:hover {
    background-color: white !important;
  }
}
</style>
