import store from '@/store'
import qs from 'qs'
import type { DataViewFilters } from '@/services/types'
import type {
  ContentPackage,
  PackageProposals,
  Package,
  PackageFilters,
  PackageProposal,
  Packages,
  PackagesProposals,
  PackagesProposalsResponse
} from '@/services/content/package/types'
import api from '@/services/api'
import i18n from '@/i18n'

const postContentProposal = async (data): Promise<PackageProposal> => {
  try {
    const a = await api.post<PackageProposal>('/content/package/proposal', data)
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return error
  }
}
const getProposals = async (params?: DataViewFilters): Promise<PackagesProposals> => {
  const a = await api.get<PackagesProposalsResponse>('/publisher/packages/proposals', {
    params
  })

  //if not params return all data
  if (!params) {
    return a.data
  }

  // no backend API pagination implementation - handling manually
  const startIndex = (params.page_number - 1) * params.results_per_page
  const endIndex = startIndex + params.results_per_page

  a.data.proposals = a.data.proposals.slice(startIndex, endIndex)

  let count = 0
  a.data.proposals.forEach((e) => {
    if (e.status === 'pending') count += 1
  })
  a.data.total_pending_per_page = count

  return a.data
}
const getPublisherProposal = async (id: string, pageNumber: number): Promise<PackageProposals> => {
  const a = await api.get(`/publisher/packages/proposal?id=${id}&page_number=${pageNumber}`)
  return a.data
}
const postPublisherProposal = async (proposal_id: string, accept_proposal: boolean): Promise<void> => {
  try {
    await api.post<void>('/publisher/packages/proposal', {
      proposal_id,
      accept_proposal
    })
    store.dispatch('messages/addMessage', {
      message: accept_proposal ? i18n.global.t('content-package-accepted-message') : i18n.global.t('content-package-decline-message'),
      type: 'success'
    })
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
  }
}
const getPublisherPackages = async (filters: PackageFilters): Promise<Packages> => {
  const a = await api.get<Packages>('/publisher/packages', {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
  return a.data
}
const getPackages = async (filters: PackageFilters): Promise<Packages> => {
  const a = await api.get<Packages>('/content/packages', {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
  return a.data
}
const getContentPackage = async (id: number): Promise<ContentPackage> => {
  const a = await api.get<ContentPackage>(`/content/package?package_id=${id}`)
  return a.data
}
const postPackage = async (package_name: string, description: string, video_ids: string[], folder_ids: number[], tags: string[]): Promise<Package> => {
  const a = await api.post<Package>('/content/package', {
    package_name: package_name,
    description: description,
    video_ids: video_ids,
    folder_ids: folder_ids,
    tags: tags
  })
  return a.data
}
const updatePackage = async (package_id: number, package_name: string, description: string, video_ids: string[], folder_ids: number[], tags: string[]): Promise<ContentPackage> => {
  const a = await api.put<ContentPackage>('/content/package', {
    package_id: package_id,
    package_name: package_name,
    description: description,
    video_ids: video_ids,
    folder_ids: folder_ids,
    tags: tags
  })
  return a.data
}

export default {
  postContentProposal,
  getProposals,
  getPublisherProposal,
  postPublisherProposal,
  getPublisherPackages,
  getPackages,
  getContentPackage,
  postPackage,
  updatePackage
}
