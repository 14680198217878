import store from '@/store'
import i18n from '@/i18n'
import type { ChannelExists, ChannelHandle, ChannelPreview, ContentChannel, ChannelParams } from '@/services/channel/types'
import api from '@/services/api'

const getContentChannel = async () => {
  const a = await api.get<ContentChannel>('/channels/edit_channel_info')
  return a.data
}
const deleteChannelBanner = async () => {
  try {
    const a = await api.delete(`/channel/banner`)

    store.dispatch('messages/addMessage', {
      message: i18n.global.t('channel-save-completed'),
      type: 'success'
    })
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: i18n.global.t('channel-update-failed') + ', ' + error.response.data.error.description, type: 'error' })
    //console.error('Error:', error.response ? error.response.data : error.message)
    throw error
  }
}
const deleteChannelIcon = async () => {
  try {
    const a = await api.delete(`/channel/icon`)
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('channel-save-completed'),
      type: 'success'
    })
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: i18n.global.t('channel-update-failed') + ', ' + error.response.data.error.description, type: 'error' })
    //console.error('Error:', error.response ? error.response.data : error.message)
    throw error
  }
}
const getContentChannelDataByOrgId = async (orgId: string) => {
  const a = await api.get<ChannelPreview>('/channel?organization_id=' + orgId)
  return a.data
}
const getContentChannelDataByHandle = async (handle: string, params?: ChannelParams): Promise<ChannelPreview> => {
  const queryParams = new URLSearchParams({ ...params, handle })
  const url = `/channel?${queryParams}`
  const response = await api.get<ChannelPreview>(url)
  return response.data
}
const putChannel = async (req: {
  name?: string
  description?: string
  handle?: string
  categories_order?: number[]
  banner?: string
  icon?: string
  messageName: string
}, showMessage = true): Promise<ContentChannel> => {
  const formData = new FormData()
  if (req.name) {
    formData.append('name', req.name)
  }
  if (req.description) {
    formData.append('description', req.description)
  }
  if (req.handle) {
    formData.append('handle', req.handle)
  }
  if (req.categories_order) {
    req.categories_order.forEach((co: number) => {
      formData.append('categories_order', co.toString())
    })
  }
  if (req.banner) {
    formData.append('banner', req.banner)
  }
  if (req.icon) {
    formData.append('icon', req.icon)
  }
  try {
    const res = await api.put<ContentChannel>('/channel', formData)
    if (showMessage) {
      store.dispatch('messages/addMessage', {
        message: i18n.global.t('channel-save-completed'),
        type: 'success'
      })
    }
    return res.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: i18n.global.t('channel-update-failed') + ', ' + error.response.data.error.description, type: 'error' })
    throw error
  }
}
const getIsHandleAvailable = async (handle: string): Promise<boolean> => {
  try {
    if (!handle) return false
    const response = await api.get<ChannelExists>(`/channel/exist/${handle}`)
    return !response.data.exists
  } catch (error) {
    console.error('Error checking handle:', error)
    return false
  }
}
const getHandleByOrganization = async (organization: number): Promise<string> => {
  try {
    const response = await api.get<ChannelHandle>(`/channel/handle/${organization}`)
    return response.data.handle || ''
  } catch (error) {
    console.error('Error checking handle:', error)
    return ''
  }
}

export default {
  getContentChannel,
  deleteChannelBanner,
  deleteChannelIcon,
  getContentChannelDataByOrgId,
  putChannel,
  getIsHandleAvailable,
  getHandleByOrganization,
  getContentChannelDataByHandle
}
