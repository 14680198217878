import i18n from '@/i18n'
import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const domainsColsAgency = computed<ListColumn[]>(() => {
  return [
    {
      name: 'domain',
      title: 'agency-domain-address',
      width: 20,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 2,
      priority: 1,
    },
    {
      name: 'account',
      title: 'accounts-domains-account-name',
      width: 20,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 1,
      priority: 2,
    },
    {
      name: 'system_status',
      title: 'settings-domain-status',
      width: 18,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 3,
      priority: 3
    },
    {
      name: 'settings-status',
      title: 'inventory-my-domains-col-status-ads-txt',
      width: 12,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 4,
      priority: 4,
      tooltip: i18n.global.t('inventory-my-domains-col-status-ads-txt-info')
    },
    {
      name: 'create_date',
      title: 'create-date',
      width: 13,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 5,
      priority: 5
    },
    {
      name: 'actions',
      title: ``,
      width: 15,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 6,
      priority: 0,
      minWidthInPx: '250px'
    }
  ]
})

export default domainsColsAgency
