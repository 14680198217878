import type { GetDashboard } from '@/services/dashboard/types'
import { DashboardName } from '@/services/dashboard/constants'
import api from '@/services/api'
import type { Videos } from '../videos/types.js'

const GetDashboardPublisherActivity = async (req: GetDashboard) => {
  req.name = DashboardName.publisher_activity
  const a = await api.post('/publisher/dashboard', req)
  return a.data
}
const GetDashboardPublisherQuality = async (req: GetDashboard) => {
  req.name = DashboardName.publisher_quality
  const a = await api.post('/publisher/dashboard', req)
  return a.data
}
const GetDashboardPublisherQualityInfo = async (req: GetDashboard) => {
  req.name = DashboardName.publisher_quality_info
  const a = await api.post('/publisher/dashboard', req)
  return a.data
}
const GetDashboardPublisherActivityTop = async (req: GetDashboard) => {
  req.name = DashboardName.publisher_activity_top
  const a = await api.post('/publisher/dashboard', req)
  return a.data
}
const GetDashboardPublisherQualityTop = async (req: GetDashboard) => {
  req.name = DashboardName.publisher_quality_top
  const a = await api.post('/publisher/dashboard', req)
  return a.data
}
const GetDashboardAgencyAccounts = async (req: GetDashboard) => {
  req.name = DashboardName.agency_accounts
  const a = await api.post('/agency/dashboard', req)
  return a.data
}
const GetDashboardAgencyActivity = async (req: GetDashboard) => {
  req.name = DashboardName.agency_activity
  const a = await api.post('/agency/dashboard', req)
  return a.data
}
const GetDashboardAgencyActivityTop = async (req: GetDashboard) => {
  req.name = DashboardName.agency_activity_top
  const a = await api.post('/agency/dashboard', req)
  return a.data
}
const GetDashboardAgencyQuality = async (req: GetDashboard) => {
  req.name = DashboardName.agency_quality
  const a = await api.post('/agency/dashboard', req)
  return a.data
}
const GetDashboardAgencyQualityInfo = async (req: GetDashboard) => {
  req.name = DashboardName.agency_quality_info
  const a = await api.post('/agency/dashboard', req)
  return a.data
}
const GetDashboardContentActivity = async (req: GetDashboard) => {
  req.name = DashboardName.content_activity
  const a = await api.post('/content/dashboard', req)
  return a.data
}
const GetDashboardContentActivityInfo = async (req: GetDashboard) => {
  req.name = DashboardName.content_activity_info
  const a = await api.post('/content/dashboard', req)
  return a.data
}
const GetDashboardContentActivityTop = async (req: GetDashboard) => {
  req.name = DashboardName.content_activity_top
  const a = await api.post('/content/dashboard', req)
  return a.data
}
const getMostViewedVideos = async (req: GetDashboard): Promise<Videos> => {
  req.name = DashboardName.content_activity_most_viewed
  const a = await api.post('/content/dashboard', req)
  return a.data
}

export default {
  GetDashboardPublisherActivity,
  GetDashboardPublisherQuality,
  GetDashboardPublisherQualityInfo,
  GetDashboardPublisherActivityTop,
  GetDashboardPublisherQualityTop,
  GetDashboardAgencyAccounts,
  GetDashboardAgencyActivity,
  GetDashboardAgencyActivityTop,
  GetDashboardAgencyQuality,
  GetDashboardAgencyQualityInfo,
  GetDashboardContentActivity,
  GetDashboardContentActivityInfo,
  GetDashboardContentActivityTop,
  getMostViewedVideos
}
