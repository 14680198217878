<template>
  <div class="profile-initials-container" :style="{ '--bg-color': initialsBgColor }">
    <span class="profile-initials">{{ initials }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface ProfileImagePlaceholderProps {
  initials: string
  fontSize?: number
  initialsBgColor?: string
}

const props = withDefaults(defineProps<ProfileImagePlaceholderProps>(), {
  fontSize: 2
})
const fontSize = computed(() => `${props.fontSize}rem`)
</script>

<style scoped>
.profile-initials-container {
  background-color: var(--bg-color);

  .profile-initials {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: v-bind(fontSize);
    font-weight: 700;
  }
}
</style>
