import { RouteRecordRaw } from 'vue-router'

const domainsRoutes: RouteRecordRaw[] = [
  {
    path: 'view/:id/:orgId/',
    name: 'agency-view-domain',
    component: () => import('@/components/domains/content/DomainsViewDomain.vue'),
    props: true,
    meta: {
      title: 'domains'
    }
  }
]

export default domainsRoutes
