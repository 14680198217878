<template>
  <div class="modal-header">
    <img v-if="!store.state.modal.loading" class="close-x" src="@/assets/common/close.svg" @click.stop="emit('close-action')">
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { CustomModalProps } from '@/interfaces'

const store = useStore()
defineProps<CustomModalProps>()
const emit = defineEmits(['close-action'])
</script>

<style scoped>
.modal-header {
  .close-x {
    position: absolute;
    right: 16px;
    top: 24px;
    cursor: pointer;
  }
}
</style>
