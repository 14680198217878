import i18n from '@/i18n'
import { DataViewFilters } from '@/services/types'
import { LoginUser } from '@/services/organization-user/types'
import api from '@/services/api'
import store from '@/store'
import type { Follow, Organization, Organizations } from '@/services/organization/types'
const loginRemoteAccount = async (orgId: number, userId: number): Promise<LoginUser> => {
  const a = await api.post<LoginUser>('/organization/login', { organization_id: orgId, user_id: userId })
  return a.data
}
const getOrganizations = async (params: DataViewFilters): Promise<Organizations> => {
  const { data } = await api.get<Organizations>('/organizations', { params })
  return data
}
const getOrganization = async (organization_id: number): Promise<Organization> => {
  const { data } = await api.get<Organization>(`/organization?organization_id=${organization_id}`)
  return data
}
const postOrganization = async (
  first_name: string,
  last_name: string,
  email: string,
  organization_name: string,
  organization_website: string,
  is_organization_agency: boolean,
  is_organization_publisher: boolean,
  is_organization_content_owner: boolean,
  agency_fee: number,
  country_id: number,
  language_id: number
): Promise<void> => {
  await api.post<Organization>('/organization', {
    first_name,
    last_name,
    email,
    organization_name,
    organization_website,
    is_organization_agency,
    is_organization_publisher,
    is_organization_content_owner,
    agency_fee,
    country_id,
    language_id
  })
}
const putOrganization = async (data): Promise<Organization> => {
  try {
    const a = await api.put<Organization>('/organization', { ...data })
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('settings-users-organization-edit-success'),
      type: 'success'
    })
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return Promise.reject(error)
  }
}
const followOrganization = async (organization_id: number): Promise<void> => {
  await api.post<Organization>(`/organization/${organization_id}/follow`)
}
const unFollowOrganization = async (organization_id: number): Promise<void> => {
  await api.post<Organization>(`/organization/${organization_id}/unfollow`)
}
const checkFollowOrganization = async (organization_id: number): Promise<Follow> => {
  const a = await api.get<Follow>(`/organization/${organization_id}/follow`)
  return a.data
}
const deleteOrganizationProfileImage = async (): Promise<boolean> => {
  try {
    const a = await api.delete('/organization/profile_image')
    store.dispatch('messages/addMessage', { message: i18n.global.t('profile-image-delete-success'), type: 'success' })
    return a.data
  } catch (error) {
    store.dispatch('messages/addMessage', { message: i18n.global.t('org-profile-remove-image-error'), type: 'error' })
    return error
  }
}
const uploadOrganizationAvatar = async (thumb: File) => {
  const bodyFormData = new FormData()
  bodyFormData.append('profile_image', thumb)
  try {
    const a = await api.post<{ profile_image: string }>('/organization/profile_image', bodyFormData)
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('profile-image-add-update-success'),
      type: 'success'
    })

    return a.data.profile_image
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return ''
  }
}

export default {
  loginRemoteAccount,
  getOrganizations,
  getOrganization,
  postOrganization,
  putOrganization,
  followOrganization,
  unFollowOrganization,
  checkFollowOrganization,
  deleteOrganizationProfileImage,
  uploadOrganizationAvatar
}
