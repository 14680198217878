export interface Widget {
  widget: Widget[]
}

export const widget = {
  namespaced: true,
  state: () => ({
    widget: []
  }),
  mutations: {
    setWidget(state: Widget, w: Widget[]) {
      state.widget = w
    },
    unsetWidget(state: Widget) {
      state.widget = null
    }
  }
}
