import type { Video } from '@/services/videos/types'
import type { Folder } from '@/services/folder/types'
import { Package } from '@/services/content/package/types'

export interface Packages {
  packages: Package[]
  videos: Video[]
  selectedVideos: Video[]
  folders: Folder[]
  selectedFolders: Folder[]
}

export const packages = {
  namespaced: true,
  state: () => ({
    selectedVideos: [],
    selectedFolders: [],
    packages: [],
    videos: [],
    folders: [],
    totalPackages: 0
  }),
  mutations: {
    selectVideo(state, video: Video) {
      state.selectedVideos.unshift(video)
    },
    setSelectVideos(state, videos: Video[]) {
      state.selectedVideos = videos
    },
    selectFolder(state, folder: Folder) {
      state.selectedFolders.unshift(folder)
    },
    setSelectFolders(state, folders: Folder[]) {
      state.selectedFolders = folders
    },
    setFolders(state, folders: Folder[]) {
      state.folders = folders
    },
    deselectVideo(state, id) {
      state.selectedVideos = state.selectedVideos.filter((item) => item.video_id !== id)
    },
    deselectFolder(state, id) {
      state.selectedFolders = state.selectedFolders.filter((item) => item.id !== id)
    },
    setPackages(state, packages: Packages) {
      state.packages = packages
    },
    setTotal(state, total: number) {
      state.totalPackages = total
    },

    logout(state) {
      state.selectedVideos = []
      state.selectedFolders = []
      state.packages = []
      state.videos = []
      state.folders = []
      state.totalPackages = 0
    }
  },
  actions: {
    setSelectVideos(context, videos: Video[]) {
      context.commit('setSelectVideos', videos)
    },
    setSelectFolders(context, folders: Folder[]) {
      context.commit('setSelectFolders', folders)
    },
    setFolders(context, folders: Folder[]) {
      context.commit('setFolders', folders)
    },
    setPackages(context, packages: Packages[]) {
      context.commit('setPackages', packages)
    },
    deselectVideo(context, id: string) {
      context.commit('deselectVideo', id)
    },
    deselectFolder(context, id: string) {
      context.commit('deselectFolder', id)
    },
    reset(context) {
      context.commit('setTotal', 0)
      context.commit('setPackages', [])
    }
  }
}
