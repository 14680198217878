import { CRUD, DataType } from '@/interfaces'
import { checkPermission } from '@/store/permissions'
import { RouteRecordRaw } from 'vue-router'

const packagesRoutes: RouteRecordRaw[] = [
  {
    path: 'packages',
    name: 'packages-packages',
    // component: () => import('../../views/dashboard/PackagesPage.vue'),
    meta: {
      title: 'packages'
    },
    children: [
      {
        path: 'add',
        name: 'add-package',
        component: () => import('@/components/content/packages/my-packages/addpackage/PackageWizard.vue'),
        beforeEnter: (to, from, next) => checkPermission('my_packages_packages', next),
        meta: {
          title: 'packages',
          fullscreen: true
        }
      },
      {
        path: 'duplicate/:id',
        name: 'duplicate-package',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        beforeEnter: (to, from, next) => checkPermission('my_packages_packages', next),
        meta: {
          title: 'packages',
          modal: true
        },
        props: { type: DataType.Package, action: CRUD.Duplicate }
      },
      {
        path: 'edit/:id',
        name: 'edit-package',
        component: () => import('@/components/content/packages/my-packages/addpackage/PackageWizard.vue'),
        props: true,
        beforeEnter: (to, from, next) => checkPermission('my_packages_packages', next),
        meta: {
          title: 'packages',
          fullscreen: true
        }
      },
      {
        path: 'accept-proposals',
        name: 'pd-accept-proposals',
        component: () => import('@/components/content/packages/AcceptAllProposals.vue'),
        props: true,
        meta: {
          title: 'packages'
        }
      }
    ]
  },
  {
    path: 'offered-packages',
    name: 'offered-packages',
    meta: {
      title: 'packages'
    },
    children: [
      {
        path: 'accept-proposals',
        name: 'ext-accept-proposals',
        component: () => import('@/components/content/packages/AcceptAllProposals.vue'),
        props: true,
        meta: {
          title: 'content'
        }
      },
      {
        path: 'edit/:id',
        name: 'ext-edit-package',
        component: () => import('@/components/content/packages/offered-packages/OfferedPackagesEdit.vue'),
        props: true,
        meta: {
          title: 'packages'
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'edit-closeUnsavedOfferedPackages',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'packages'
            },
            props: { type: DataType.OfferedPackages, action: CRUD.Confirm }
          }
        ]
      },
      {
        path: 'closeunsaved',
        name: 'edit-closeUnsavedOfferedPackages',
        component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
        meta: {
          title: 'packages'
        },
        props: { type: DataType.OfferedPackages, action: CRUD.Confirm }
      }
    ]
  }
]

export default packagesRoutes
