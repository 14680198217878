import { RouteRecordRaw } from 'vue-router'

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: 'publisher-dashboard',
    name: 'publisher-dashboard',
    components: {
      top: () => import('@/components/dashboard/publisher/DashboardPublisher.vue'),
      side: () => import('@/components/dashboard/sidemenu/DashboardSideMenu.vue'),
      bottom: () => import('@/components/common/videostrip/VideoStrip.vue')
    },
    meta: {
      title: 'dashboard'
    }
  },
  {
    path: 'content-dashboard',
    name: 'content-dashboard',
    components: {
      top: () => import('@/components/dashboard/content/DashboardContent.vue'),
      side: () => import('@/components/dashboard/sidemenu/DashboardSideMenu.vue'),
      bottom: () => import('@/components/common/videostrip/VideoStrip.vue')
    },
    meta: {
      title: 'dashboard'
    }
  },
  {
    path: 'agency-dashboard',
    name: 'agency-dashboard',
    components: {
      top: () => import('@/components/dashboard/agency/DashboardAgency.vue'),
      side: () => import('@/components/dashboard/sidemenu/DashboardSideMenu.vue'),
      bottom: () => import('@/components/dashboard/agency/DashboardAgencyAccounts.vue')
    },
    meta: {
      title: 'dashboard'
    }
  }
]

export default dashboardRoutes
