<template>
  <div v-if="props.viewType === DataViewType.Grid" class="folder frame pointer" :class="{ selected: selected }" @click="toggleFolder">
    <ImageComponent :source="getThumbnail(0)" class="thumbnails">
      <div class="add-btn">
        <VideoActionButton
          :text="''" :icon="selected ? IconRemove : IconAdd" :class-list="'red'" :select-only="true"
          @click="toggleFolder"
        />
      </div>
      <div class="info">
        <div class="title">
          <Tooltip :placement="'top'" :content="item.name" :textLength="18" />
        </div>
      </div>
    </ImageComponent>
    <div class="small-videos">
      <ImageComponent :source="getThumbnail(1)" :blank="props.item.videos && props.item.videos.length < 2" class="thumbnails" />
      <ImageComponent :source="getThumbnail(2)" :blank="props.item.videos && props.item.videos.length < 3" class="thumbnails" />
      <ImageComponent :source="getThumbnail(3)" :blank="props.item.videos && props.item.videos.length < 4" class="thumbnails">
        <div v-if="props.item.videos && props.item.number_of_videos > 4" class="morevideos">+{{ props.item.number_of_videos - 4 }}</div>
      </ImageComponent>
    </div>
  </div>
  <div v-if="props.viewType === DataViewType.List" class="playlist list">
    <input v-model="selected" type="checkbox" class="checkbox">
    <ImageComponent :source="getThumbnail(0)" class="thumbnails list" />
    <div class="title">
      <Tooltip :placement="'top'" :content="props.item.playlist_name" :textLength="100" />
    </div>
    <div class="videos-amount">
      <div class="videos-amount-number">
        <div>{{ props.item.number_of_videos }}</div>
      </div>
      <span v-if="props.item.videos && props.item.number_of_videos === 1">{{ $t('playlist-video') }}</span><span v-if="props.item.videos && props.item.number_of_videos > 1">{{ $t('playlist-videos') }}</span>
    </div>
    <div>{{ $t('playlist-tags') }}</div>
    <div>{{ getDateString(props.item.create_date) }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { DataViewType } from '@/interfaces'
import { uid } from 'uid'
import { useStore } from 'vuex'
import ImageComponent from '@/components/common/ImageComponent.vue'
import VideoActionButton from '@/components/base/VideoActionButton.vue'
import { getDateString } from '@/services/utils'
import { Tooltip } from '@/components/common'
import IconAdd from '@/assets/common/add-icon.svg'
import IconRemove from '@/assets/common/remove-icon.svg'

const props = defineProps(['item', 'viewType', 'columns', 'pageRoute', 'detailsLink', 'withBottom', 'imageIdx', 'withTitleEvents', 'hoverAnimation', 'selectOnly'])
const store = useStore()
function toggleFolder() {
  if (selected.value) {
    deselectFolder()
  } else {
    selectFolder()
  }
}

function deselectFolder() {
  store.commit('packages/deselectFolder', props.item.id)
}

function selectFolder() {
  const folderExists = store.state.packages.selectedFolders.some((folder) => folder.id === props.item.id)
  if (!folderExists) {
    const newFolder = {
      ...props.item,
      uid: uid()
    }
    store.commit('packages/selectFolder', newFolder)
  }
}

function getThumbnail(index: number) {
  if (props.item.videos && index < props.item.videos.length) {
    return props.item.videos[index].thumbnails[1].url
  } else {
    return ''
  }
}

const selected = computed(() => {
  return !!store.state.packages.selectedFolders.find((v) => v.id === props.item.id)
})
</script>

<style lang="scss" scoped>
.folder {
  width: 100%;
  height: fit-content;
  transition: opacity 1s ease;

  &.selected {
    transition: opacity 0.5s ease;
    opacity: 0.4;
    background: rgba(20, 13, 63, 0.11);
  }

  &.list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 40px 160px 330px 160px 150px 140px;
    align-items: center;
    padding: 15px 0 15px 0;

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: 0.1px;
      text-transform: capitalize;

      /* body text */

      color: #4c4c66;
    }

    .videos-amount {
      /* white */
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .videos-amount-number {
        margin-right: 5px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 27px;
        height: 27px;
        background: #8caeff;
        border-radius: 30px;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.1px;
        text-transform: capitalize;
      }
    }

    .checkbox {
      // justify-self: center;
      justify-self: center;
      margin: 0;
    }
  }

  .add-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  .frame {
    padding: 3% 3% 20px 3%;
    margin-bottom: 20px;
    cursor: pointer;

    .checkbox {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 30px;
      height: 30px;
      cursor: pointer;

      img {
        position: absolute;
      }
    }

    .view {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3.81739px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      /* identical to box height */

      text-transform: uppercase;

      color: #ffffff;
      position: absolute;
      width: 87px;
      height: 30px;
      left: 50%;
      top: 50%;

      background: #2c95ff;
      border-radius: 29.4526px;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }

    .date {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      position: absolute;
      width: 87px;
      height: 30px;
      bottom: 0px;
      left: 15px;

      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #ffffff;

      img {
        margin-right: 4px;
      }
    }
  }
}

.thumbnails {
  width: 100%;
  padding-bottom: 60%;
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;

  &:hover {
    .view {
      opacity: 1;
    }
  }

  .info {
    top: 15px;
    left: 15px;
    position: absolute;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      text-transform: capitalize;
      color: #ffffff;
      white-space: normal;
      width: 100%;
    }

    .params {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      text-transform: capitalize;

      /* white */

      color: #ffffff;
    }
  }

  &.list {
    width: 130px;
    padding-bottom: 0;
    height: 83px;
    border-radius: 6px;
    cursor: pointer;
  }
}

.small-videos {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 8px;
  box-sizing: border-box;

  .thumbnails {
    width: 31%;
    padding-bottom: 16%;
    border-radius: 4px;

    .morevideos {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff34;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      text-transform: capitalize;
      color: #ffffff;
    }
  }

  :nth-child(1) {
    border-radius: 0 4px 4px 4px;
  }

  :nth-child(4) {
    border-radius: 4px 0 4px 4px;
  }
}
</style>
