<template>
  <div>
    <div v-if="props.viewType === DataViewType.Grid" class="playlist frame">
      <PlaylistImage :source="getThumbnail(0)" class="thumbnails" :border-radius="[6, 6, 0, 0]" @click.stop="openPlaylistEdit(false)">
        <div class="info">
          <div class="title">
            <TeleportTooltip :max-width="2000" :text-length="20" :placement="'top'">
              <div class="flex">
                {{ shortenText(playlist.playlist_name, 20) }}
              </div>
              <template #content>
                <div class="style-font">{{ playlist.playlist_name }}</div>
              </template>
            </TeleportTooltip>
          </div>
          <div class="params">
            <div>{{ getDateString(playlist.create_date) }}</div>
            <div class="dot">•</div>
            <div v-if="playlist.auto_updated">{{ $t('playlist-auto-update') }}</div>
            <div v-if="playlist.auto_updated" class="dot">•</div>
            <div v-if="playlist.is_random_order">{{ $t('playlist-play-randomly') }}</div>
            <div v-if="!playlist.is_random_order">{{ $t('playlist-play-by-order') }}</div>
          </div>
        </div>
        <div class="menu-settings" @click.stop="null">
          <ActionButtons :actions="actions" :class="actions" :view-type="DataViewType.Grid" />
        </div>
      </PlaylistImage>
      <div class="small-videos">
        <PlaylistImage
          :source="getThumbnail(1)"
          :mini-image="true"
          :blank="props.item.videos && props.item.videos.length < 2"
          class="thumbnails"
          :border-radius="[0, 3, 3, 0]"
          @add="addVideosToPlaylist"
          @edit="openPlaylistEdit(false)"
        />
        <PlaylistImage
          :source="getThumbnail(2)"
          :mini-image="true"
          :blank="props.item.videos && props.item.videos.length < 3"
          class="thumbnails"
          :border-radius="[3, 3, 3, 3]"
          @add="addVideosToPlaylist"
          @edit="openPlaylistEdit(false)"
        />
        <PlaylistImage
          :source="getThumbnail(3)"
          :mini-image="true"
          :blank="props.item.videos && props.item.videos.length < 4"
          class="thumbnails"
          :border-radius="[3, 3, 3, 3]"
          @add="addVideosToPlaylist"
          @edit="openPlaylistEdit(false)"
        />
        <PlaylistImage
          :source="getThumbnail(4)"
          :mini-image="true"
          :blank="props.item.videos && props.item.videos.length < 5"
          class="thumbnails"
          :border-radius="[3, 0, 0, 3]"
          @add="addVideosToPlaylist"
          @edit="openPlaylistEdit(false)"
        >
          <div v-if="props.item.videos_amount > 5" class="morevideos">+{{ props.item.videos_amount - 5 }}</div>
        </PlaylistImage>
      </div>
      <div class="tags">
        <div :id="'tag-group-container' + playlist.playlist_id" ref="tagsparentframe" class="tag-group">
          <div v-for="tag in categoriesToShow" :key="tag.id" class="tag" :title="tag.name">{{ $t('category_' + tag.id) }}</div>
          <div v-if="categoriesHidden.length > 0" id="extratag" class="count">
            <CategoriesDropdown class="column" :extra-tags="categoriesHidden" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="props.viewType === DataViewType.List"
      ref="dataViewRow"
      class="playlist list"
      :style="`grid-template-columns:  ${columnsGridTemplate(props.columns)};`"
      @click.stop="openPlaylistEdit(false)"
    >
      <div class="title" :class="shouldHideOnBreakpoint(props.columns, 'playlist_name')">
        <PlaylistImage :source="getThumbnail(0)" class="thumbnails list" :border-radius="[3, 3, 3, 3]" @click.stop="openPlaylistEdit(false)" />
        <div class="title-text">
          <TeleportTooltip
            :max-width="2000" :text-length="20" :placement="'top'" :content="playlist.playlist_name"
            @click.stop="openPlaylistEdit(false)"
          >
            <div id="titles">
              {{ playlist.playlist_name }}
            </div>
          </TeleportTooltip>
        </div>
      </div>
      <div class="title" :class="shouldHideOnBreakpoint(props.columns, 'playlist_id')">
        <div class="title-text">
          <TeleportTooltip :max-width="2000" :text-length="20" :placement="'top'">
            <div class="flex" @click.stop="openPlaylistEdit(false)">
              {{ shortenText(playlist.playlist_id + '', 20) }}
            </div>
            <template #content>
              <div class="style-font">{{ playlist.playlist_id + '' }}</div>
            </template>
          </TeleportTooltip>
        </div>
      </div>

      <div class="videos-amount" :class="shouldHideOnBreakpoint(props.columns, 'videos_amount')">
        <div class="videos-amount-number">
          <div>{{ props.item.videos_amount }}</div>
        </div>
        <span v-if="props.item.videos_amount === 1">{{ $t('playlist-video') }}</span><span v-if="props.item.videos_amount > 1 || props.item.videos_amount === 0">{{ $t('playlist-videos') }}</span>
      </div>
      <div class="tags" :class="shouldHideOnBreakpoint(props.columns, 'related_tags')">
        <div :id="'tag-group-container' + playlist.playlist_id" ref="tagsparentlist" class="tag-group" :class="{ empty: categoriesToShow?.length === 0 }">
          <div v-for="tag in categoriesToShow" :key="tag.id" class="tag" :title="tag.name">{{ $t('category_' + tag.id) }}</div>
          <div v-if="categoriesHidden.length > 0" class="count">
            <CategoriesDropdown class="column" :extra-tags="categoriesHidden" />
          </div>
        </div>
      </div>
      <div class="date" :class="shouldHideOnBreakpoint(props.columns, 'create_date')">{{ getDateString(props.item.create_date) }}</div>
      <ActionButtons :actions="actions" :class="shouldHideOnBreakpoint(props.columns, 'actions')" :is-visible="isRowHovered" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DataViewType } from '@/interfaces'
import PlaylistImage from './PlaylistImage.vue'
import { TeleportTooltip } from '@/components/common'
import { useI18n } from 'vue-i18n'
import CategoriesDropdown from './PlaylistShowMoreCategories.vue'
import router from '@/router'
import { getDateString, openModalCustomConfirmation, shortenText } from '@/services/utils'
import { useStore } from 'vuex'
import { onMounted, ref, inject, computed, watch } from 'vue'
import { PLAYLIST_ACTIONS_INJECTION_KEY } from '@/constants'
import type { Playlist } from '@/services/playlist/types'
import ActionButtons from '@/components/common/action-buttons/ActionButtons.vue'
import { columnsGridTemplate, shouldHideOnBreakpoint } from '@/services/layoutUtils'
import { useElementHover } from '@vueuse/core'
import { categoriesOverflow, OverflowType } from '@/composables'
import addVideosIconPlaceholder from '@/assets/playlists/playlist/add-videos-icon-placeholder.svg'
import { ModalType } from '@/components/modals/modals-util'
import { apiService } from '@/services'
import { CRUD, DataType, DataActions } from '@/interfaces'

const props = defineProps(['item', 'viewType', 'selected', 'columns'])
const store = useStore()
const { t } = useI18n()
const image = new window.Image()
const tagsparentframe = ref(null)
const tagsparentlist = ref(null)
const dataViewRow = ref()
const isRowHovered = useElementHover(dataViewRow)

const dataActions = inject<DataActions>(PLAYLIST_ACTIONS_INJECTION_KEY)
const modalTypeDelete = ModalType.PLAYLISTS_DELETE_PLAYLIST_MODAL
const modalTypeDuplicate = ModalType.PLAYLISTS_DUPLICATE_PLAYLIST_MODAL

image.src = addVideosIconPlaceholder

const { checkOverflowInit, categoriesToShow, categoriesHidden } = categoriesOverflow()

onMounted(() => {
  initCategories()
})

function initCategories() {
  if (props.viewType === DataViewType.Grid && tagsparentframe.value) {
    checkOverflowInit(tagsparentframe.value, props.item?.categories, OverflowType.Vertical, 37)
  }
  if (props.viewType === DataViewType.List && tagsparentlist.value) {
    checkOverflowInit(tagsparentlist.value, props.item?.categories, OverflowType.Vertical, 37)
  }
}

watch(() => props.item, initCategories)

const actions = [
  {
    label: 'add',
    handler: addVideosToPlaylist,
    translationKey: 'menu-add-videos'
  },
  {
    label: 'edit',
    handler: () => openPlaylistEdit(false),
    translationKey: 'edit'
  },
  {
    label: 'duplicate',
    handler: openDuplicatePlaylist,
    translationKey: 'menu-duplicate'
  },
  {
    label: 'embed',
    dropDownLabel: 'embedMenu',
    handler: openPlaylistEmbed,
    translationKey: 'menu-code-embed'
  },
  {
    label: 'delete',
    handler: onDeletePlaylist,
    translationKey: 'menu-delete'
  }
]

function addVideosToPlaylist() {
  store.commit('playlists/setSelectedPlaylist', playlist.value)
  store.commit('playlists/setEditable', true)
  router.push(`/library/createplaylist/${playlist.value.playlist_id}`)
}

function getThumbnail(index: number) {
  if (props.item.videos && index < props.item.videos.length) {
    return props.item.videos[index].thumbnails[1].url
  } else {
    return image.src
  }
}

function openPlaylistEdit(isEmbed: boolean) {
  router.push({
    name: `editplaylist`,
    params: {
      id: playlist.value.playlist_id
    },
    query: {
      embed: isEmbed.toString()
    }
  })
}

function openPlaylistEmbed() {
  openPlaylistEdit(true)
}

const duplicatePlaylist = async (params) => {
  const duplicatePlaylist: Playlist = {
    ...props.item,
    playlist_name: params.inputValue
  }
  const res = await apiService.playlist.postPlaylist(duplicatePlaylist)
  if (res?.playlist_id !== -1) {
    store.dispatch('messages/addMessage', {
      message: t('playlist-duplicated'),
      type: 'success'
    })
    if (dataActions?.resetToDefaultPageAndGetData) dataActions.resetToDefaultPageAndGetData()
  }
}

const deletePlaylist = async (params) => {
  return apiService.playlist
    .deletePlaylist(params.id)
    .then(() => {
      store.dispatch('messages/addMessage', {
        message: t('playlist-deleted'),
        type: 'success'
      })
      if (dataActions?.refreshData) dataActions.refreshData()
    })
    .catch((error) => {
      store.dispatch('messages/addMessage', {
        message: error?.response?.data?.error?.description,
        type: 'fail'
      })
    })
}

function onDeletePlaylist() {
  openModalCustomConfirmation({ action: CRUD.Delete, type: DataType.Playlist, id: playlist.value.playlist_id, onConfirm: deletePlaylist }, modalTypeDelete)
}

function openDuplicatePlaylist() {
  openModalCustomConfirmation(
    { action: CRUD.Duplicate, type: DataType.Playlist, id: playlist.value.playlist_id, itemName: props.item.playlist_name, onConfirm: duplicatePlaylist },
    modalTypeDuplicate
  )
}

const playlist = computed<Playlist>(() => {
  return props.item
})
</script>

<style lang="scss" scoped>
.playlist {
  width: 100%;
  height: fit-content;
  cursor: pointer;

  &.list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 14% 25% 13% 22% 7% auto;
    align-items: center;
    padding: 15px 0 15px 0;

    &:hover {
      background-color: #f2f4fa;

      .add-videos {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        letter-spacing: 0.2px;
        text-transform: capitalize;
        margin-top: 10px;

        color: #2c95ff;

        img {
          margin-right: 10px;
          filter: invert(28%) sepia(93%) saturate(4404%) hue-rotate(328deg) brightness(99%) contrast(99%);
        }
      }
    }

    .icon {
      cursor: pointer;
    }

    .title {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-left: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */
      letter-spacing: 0.1px;
      /* body text */

      color: #4c4c66;

      .title-text {
        font-family: Nunito;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        color: #4c4c66;
        word-break: break-word;
        flex: 1;
        overflow: hidden;
        * {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .videos-amount {
      /* white */
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #4c4c66;
      padding: 16px;
    }

    .date {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #4c4c66;
      padding: 16px;
    }

    .tags {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      font-family: Nunito;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;
      color: #4c4c66;
      background: none;
      border-radius: 0;
      margin-right: 20px;
      padding: 16px;
      gap: 8px;

      .videos-amount-number {
        color: #4c4c66;
        background: #f2f4fa;
      }

      .tag {
        padding: 3px 20px;
        background-color: #f2f4fa;
        border-radius: 4px;
        margin: 0;
        display: flex;
        max-width: fit-content;
      }
    }
  }

  &.frame {
    border-radius: 0px 0px 6px 6px;
    position: relative;
    z-index: 0;
    @include hoverFrameEffect;

    &:hover {
      z-index: 1;
    }

    .view {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3.81739px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 15px;
      /* identical to box height */

      text-transform: uppercase;

      color: #ffffff;
      position: absolute;
      width: 87px;
      height: 30px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }

    .date {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      position: absolute;
      width: 87px;
      height: 30px;
      bottom: 0px;
      left: 15px;

      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #ffffff;

      img {
        margin-right: 4px;
      }
    }
  }
}

.add-videos {
  display: none;
}

.thumbnails {
  width: 100%;
  padding-bottom: 60%;
  border-radius: 6px 6px 0px 0px;
  background: #f2f4fa;

  img.image {
    border-radius: 6px 6px 0 0;
  }

  &:hover {
    .view {
      opacity: 1;
    }
  }

  .info {
    top: 15px;
    left: 15px;
    position: absolute;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      margin-bottom: 2px;
    }

    .params {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
      text-transform: capitalize;
      color: #ffffff;
      display: flex;
      justify-content: left;
      align-items: center;

      .dot {
        display: flex;
        width: 19px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.list {
    width: 130px;
    padding-bottom: 0;
    height: 60px;
    border-radius: 6px;
    margin-right: 32px;
    cursor: pointer;
  }
}

.small-videos {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 8px 0 7px 0;
  box-sizing: border-box;

  .thumbnails {
    width: 23.6%;
    padding-bottom: 16%;
    border-radius: 4px;

    .morevideos {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff34;
      font-family: Nunito;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0px;
      text-transform: capitalize;
      color: #ffffff;
    }
  }

  :nth-child(1) {
    border-radius: 0 4px 4px 0;
  }

  :nth-child(4) {
    border-radius: 4px 0 0 4px;
  }
}

.tags {
  padding: 7px 8px 8px 8px;
  background-color: #f2f4fa;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: default;

  .tag-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    max-width: 100%;
    width: 100%;
    gap: 8px;
    height: 27px;
    min-height: 27px;
    max-height: 27px;
    overflow: hidden;

    div:last-child {
      margin: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .tag {
      text-align: center;
      padding: 4px 15px 4px 15px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      background-color: #dadbe8;
      border-radius: 4px !important;
      text-transform: capitalize;
      max-width: fit-content;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      height: 27px;
      position: relative;
      opacity: 1;

      transition: opacity 200ms ease-in;

      &.placeholder {
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
          animation: shimmer 2s infinite;
          content: '';
        }

        @keyframes shimmer {
          100% {
            transform: translateX(100%);
          }
        }
      }
    }
  }
}

.actions {
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;

  > div {
    margin: 0 8px;
  }

  .duplicate {
    width: 20px;
    height: 20px;
    padding: 10px;

    &:hover {
      filter: $truvid-filter-effect;
    }
  }

  .embed {
    width: 25px;
    height: 15px;
    padding: 10px;

    &:hover {
      filter: $truvid-filter-effect;
    }
  }

  .trash {
    width: 22px;
    height: 22px;
    padding: 10px;

    &:hover {
      filter: $truvid-filter-effect;
    }
  }

  .add {
    width: 18px;
    height: 18px;
    padding: 10px;

    &:hover {
      filter: $truvid-filter-effect;
    }
  }

  .playlist.list:hover & {
    visibility: visible;
  }
}

.videos-amount-number {
  margin-right: 5px;
  color: #4c4c66;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  background: #dadbe8;
  border-radius: 30px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1px;
  text-transform: capitalize;
}

.menu-settings {
  margin-top: 18px;
  padding-right: 0px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  right: 0;

  img {
    position: absolute;
  }
}
</style>
