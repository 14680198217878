<template>
  <button class="btn-cmp" :class="[variant, size, { disabled }]" @click="emit('click')">
    <div>
      <slot>{{ text }}</slot>
    </div>
  </button>
</template>

<script setup lang="ts">
import { BaseButton, ButtonSize, ButtonVariant } from '@/styles/types'

withDefaults(defineProps<BaseButton>(), {
  variant: ButtonVariant.PRIMARY,
  size: ButtonSize.BIG,
  disabled: false
})
const emit = defineEmits(['click'])
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.btn-cmp {
  font-family: 'Nunito', sans-serif;
  color: $white;
  background-color: $white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  width: fit-content;
  height: 35px;
  padding: 8px 12px;
  margin: 0;
  cursor: pointer;

  &.primary {
    background-color: $primary-color;

    &.disabled {
      background-color: $disabledText;
      opacity: 1;
    }

    &:hover {
      background-color: $primary-color;
      box-shadow: 2px 4px 6px 0px rgba(39, 39, 39, 0.25);
    }
  }

  &.secondary {
    border: 1px solid $primary-color;
    color: $primary-color;

    &.disabled {
      color: $disabledText;
      border: 1px solid $disabledText;
      opacity: 1;
    }

    &:hover {
      border: 1px solid $primary-color;
      background-color: rgba(44, 149, 255, 0.1);
      color: #2c95ff;
    }
  }

  // 3th button
  &.tertiary {
    color: $primary-color;
    border: none;

    &.disabled {
      color: $disabledText;
      opacity: 1;
    }

    &:hover {
      background-color: rgba(44, 149, 255, 0.1);
    }
  }

  &.tertiary-gray {
    color: $secondaryTitleText;

    &.disabled {
      color: $disabledText;
      opacity: 1;
    }

    &:hover {
      background-color: $background;
    }
  }

  &.link {
    @extend .tertiary;
    text-decoration: underline;
  }

  &.medium {
    font-size: 12px;
    height: 30px;
    padding: 4px 6px;
  }

  &.small {
    font-size: 12px;
    height: 24px;
    padding: 2px 6px;
  }
}
</style>
