import { ref } from 'vue'
import type { Ref } from 'vue'

export interface UseLastRequestOptions<T> {
  request: () => Promise<T>
  onCompleted?: (result: T) => void
  onError?: (err: unknown) => void
}

export interface UseLastRequestReturn {
  execute: () => Promise<void>
  abort: () => void
  executing: Ref<boolean>
}

export function useLastRequest<T = unknown>(options: UseLastRequestOptions<T>): UseLastRequestReturn {
  let lastExecutedAt = 0
  let abortedAt = 0

  const executing = ref(false)

  async function execute() {
    executing.value = true

    const executedAt = Date.now()
    lastExecutedAt = executedAt

    const isRequestValid = () => executedAt === lastExecutedAt && executedAt > abortedAt

    try {
      const response = await options.request()

      if (!isRequestValid()) return

      if (options.onCompleted) {
        options.onCompleted(response)
      }
    } catch (err) {
      if (!isRequestValid()) return

      if (options.onError) {
        options.onError(err)
      }
    } finally {
      if (isRequestValid()) {
        executing.value = false
      }
    }
  }

  function abort() {
    abortedAt = Date.now()
  }

  return {
    execute,
    abort,
    executing
  }
}
