import { uid } from 'uid'

const messageDelay = 2000

export interface Message {
  type?: string
  title?: string
  message: string
  id?: string
}

export interface Messages {
  messages: Message[]
}

export const messages = {
  namespaced: true,
  state: () => ({
    messages: []
  }),
  mutations: {
    addMessage(state: Messages, message: Message) {
      state.messages.unshift(message)
    },
    removeMessage(state: Messages, message: Message) {
      const index = state.messages.findIndex((m) => {
        return m.id === message.id
      })
      if (index > -1) {
        state.messages.splice(index, 1)
      }
    },
    clearMessages(state: Messages) {
      state.messages = []
    }
  },
  actions: {
    async addMessage(context, message: Message) {
      message.id = uid()
      if (!message.title) {
        message.title = message.type
      }
      context.commit('addMessage', message)
      await new Promise((resolve) => setTimeout(resolve, messageDelay))
      context.commit('removeMessage', message)
    }
  }
}
