import { ListColumn, ListColumnAlignment } from '@/interfaces'
import { computed } from 'vue'

const offeredPackagesCols = computed<ListColumn[]>(() => {
  return [
    {
      name: 'package-name',
      title: 'content-package-name',
      width: 19,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 1,
      priority: 1,
      expand: true
    },
    {
      name: 'content-creator-name',
      title: 'content-creator-name',
      width: 21,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 2,
      priority: 3
    },
    {
      name: 'sent-at',
      title: 'sent-at',
      width: 17,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 4,
      priority: 4
    },
    {
      name: 'status',
      title: 'status',
      width: 18,
      alignment: ListColumnAlignment.Center,
      sortable: true,
      position: 3,
      priority: 2
    },
    {
      name: 'actions',
      title: '',
      width: 5,
      alignment: ListColumnAlignment.Center,
      sortable: false,
      position: 5,
      priority: 0
    }
  ]
})

export default offeredPackagesCols
