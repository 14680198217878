import { debounce } from '@/services/utils'
import { useResizeObserver } from '@vueuse/core'
import { ref } from 'vue'

// parent should be flexbox
// select OverflowType.Horizontal if you have all the elements in one row

export enum OverflowType {
  Horizontal = 0, // flexbox row
  Vertical = 1 // flexbox
}

export function categoriesOverflow() {
  const parent = ref(null)
  const categoriesToShow = ref([])
  const categoriesHidden = ref([])
  const categoriesAll = ref([])
  const hiddenCategoriesWidth = ref(0)
  const overflowType = ref(OverflowType.Vertical)

  function checkOverflowInit(el: Element, tags, type, hiddenWidth = 0) {
    overflowType.value = type
    hiddenCategoriesWidth.value = hiddenWidth
    categoriesAll.value = tags
    if (tags) {
      categoriesAll.value.sort((a, b) => a.name?.length - b.name?.length)
    }
    parent.value = el

    useResizeObserver(parent.value, () => {
      checkOverflowDebounced()
    })

    requestAnimationFrame(() => {
      checkOverflowDebounced()
    })
  }

  const checkOverflowDebounced = debounce(() => {
    checkOverflow()
  }, 200)

  function checkOverflow() {
    categoriesToShow.value = categoriesAll.value
    categoriesHidden.value = []
    if (parent.value) {
      parent.value.style.opacity = 0
      let count = 0
      requestAnimationFrame(() => {
        if (parent.value.children.length > 0) {
          const parentRight = parent.value.getBoundingClientRect().right
          const parentBottom = parent.value.getBoundingClientRect().bottom
          for (const child of parent.value.children) {
            switch (overflowType.value) {
              case OverflowType.Horizontal:
                if (parentRight >= child.getBoundingClientRect().right) {
                  count += 1
                }
                break
              case OverflowType.Vertical:
                if (parentBottom >= child.getBoundingClientRect().bottom) {
                  count += 1
                }
                break
            }
          }
          if (parent.value.children[count - 1]) {
            const lastChildRight = parent.value.children[count - 1].getBoundingClientRect().right
            if (count + 1 <= categoriesAll.value.length && lastChildRight + hiddenCategoriesWidth.value + 1 > parentRight) {
              count -= 1
            }
            if (categoriesAll.value.length === 1 && lastChildRight > parentRight) {
              count -= 1
            }
          }

          categoriesToShow.value = categoriesAll.value?.slice(0, count)
          categoriesHidden.value = categoriesAll.value?.slice(count)
          if (count === categoriesAll.value.length) {
            categoriesHidden.value = []
          }

          categoriesHidden.value.sort(compareByLengthAndAlphabet)
          categoriesToShow.value.sort(compareByLengthAndAlphabet)

          parent.value.style.opacity = 1
        }
      })
    }
  }

  function compareByLengthAndAlphabet(a, b) {
    return a?.name?.localeCompare(b.name)
  }

  function setCateogriesParent(el: Element) {
    parent.value = el
  }

  return { checkOverflowInit, categoriesToShow, categoriesHidden, setCateogriesParent }
}
