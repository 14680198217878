import type {
  DashboardAgencyAccounts,
  DashboardAgencyActivity,
  DashboardAgencyActivityTop,
  DashboardAgencyQuality,
  DashboardAgencyQualityInfo,
  DashboardContentActivity,
  DashboardContentActivityInfo,
  DashboardContentActivityTop,
  DashboardPublisherActivity,
  DashboardPublisherActivityTop,
  DashboardPublisherQuality,
  DashboardPublisherQualityInfo,
  DashboardPublisherQualityTop
} from '@/interfaces'
import { DashboardType, DateRangeNames } from '@/interfaces'
import { apiService } from '@/services'
import { getDateRanges } from '@/services/utils'

export interface Dashboard {
  dashboardPublisherActivity: DashboardPublisherActivity | null
  dashboardPublisherQuality: DashboardPublisherQuality | null
  dashboardPublisherActivityTop: DashboardPublisherActivityTop | null
  dashboardPublisherQualityTop: DashboardPublisherQualityTop | null
  dashboardAgencyAccounts: DashboardAgencyAccounts | null
  dashboardAgencyActivity: DashboardAgencyActivity | null
  dashboardAgencyActivityTop: DashboardAgencyActivityTop | null
  dashboardAgencyQualityInfo: DashboardAgencyQualityInfo | null
  dashboardAgencyQuality: DashboardAgencyQuality | null
  dashboardContentActivity: DashboardContentActivity | null
  dashboardContentActivityTop: DashboardContentActivityTop | null
  dashboardPublisherQualityInfo: DashboardPublisherQualityInfo | null
  dashboardContentActivityInfo: DashboardContentActivityInfo | null
  dates: string[]
  loadingGraphData: boolean
  organization_id: string | null
  domain: string | null
  widget_id: string | null
  dashboardType: DashboardType
}

export const dashboard = {
  namespaced: true,
  state: () => ({
    dashboardPublisherActivity: null,
    dashboardPublisherQuality: null,
    dashboardPublisherActivityTop: null,
    dashboardPublisherQualityTop: null,
    dashboardAgencyAccounts: null,
    dashboardAgencyActivity: null,
    dashboardAgencyActivityTop: null,
    dashboardAgencyQualityInfo: null,
    dashboardAgencyQuality: null,
    dashboardContentActivity: null,
    dashboardContentActivityTop: null,
    dashboardContentActivityInfo: null,
    dashboardPublisherQualityInfo: null,
    dates: [],
    organizationId: null,
    domain: null,
    dashboardType: DashboardType.None
  }),
  getters: {
    getDashboardType: (state) => () => {
      return state.dashboardType
    }
  },
  mutations: {
    setDates(state, res: string[]) {
      state.dates = res
    },
    setFilteredOrganizationId(state, res: string[]) {
      state.organization_id = res
    },
    setFilteredDomain(state, res: string[]) {
      state.domain = res
    },
    setFilteredWidgetId(state, res: string[]) {
      state.widget_id = res
    },
    setPublisherActivity(state, res) {
      state.dashboardPublisherActivity = res
    },
    setPublisherActivityTop(state, res) {
      if (res) {
        const countries = []
        Object.keys(res.countries).forEach((country) => {
          countries.push({ country, data: res.countries[country] })
        })
        countries.sort((c, b) => {
          return c.data > b.data ? -1 : 1
        })
        res.countries = countries.slice(0, 5)
      }
      state.dashboardPublisherActivityTop = res
    },
    setPublisherQuality(state, res) {
      state.dashboardPublisherQuality = res
    },
    setPublisherQualityInfo(state, res) {
      state.dashboardPublisherQualityInfo = res
    },
    setPublisherQualityTop(state, res) {
      const videos = []
      Object.keys(res.videos).forEach((video) => {
        videos.push({ video, data: res.videos[video] })
      })
      videos.sort((c, b) => {
        return c.data > b.data ? -1 : 1
      })
      res.videos = videos.slice(0, 5)
      state.dashboardPublisherQualityTop = res
    },
    setDashboardType(state, type: DashboardType) {
      state.dashboardType = type
    },
    logout(state) {
      state.dashboardPublisherActivity = null
      state.dashboardPublisherQuality = null
      state.dashboardPublisherActivityTop = null
      state.dashboardPublisherQualityTop = null
      state.dashboardAgencyAccounts = null
      state.dashboardAgencyActivity = null
      state.dashboardAgencyQuality = null
      state.dashboardAgencyActivityTop = null
      state.dashboardAgencyQualityInfo = null
      state.dashboardContentActivity = null
      state.dashboardContentActivityTop = null
      state.dashboardContentActivityInfo = null
      state.dashboardPublisherQualityInfo = null
      state.dates = getDateRanges(DateRangeNames.Last30days)
      state.organization_id = null
      state.domain = null
      state.widget_id = null
      state.dashboardType = DashboardType.None
    },

    setAgencyActivity(state, res) {
      state.dashboardAgencyActivity = res
    },
    setAgencyActivityTop(state, res) {
      state.dashboardAgencyActivityTop = res
    },
    setAgencyQuality(state, res) {
      state.dashboardAgencyQuality = res
    },
    setAgencyQualityInfo(state, res) {
      state.dashboardAgencyQualityInfo = res
    },
    setAgencyAccounts(state, res) {
      state.dashboardAgencyAccounts = res
    },
    setContentActivity(state, res) {
      state.dashboardContentActivity = res
    },
    setContentActivityInfo(state, res) {
      state.dashboardContentActivityInfo = res
    },
    setContentActivityTop(state, res) {
      if (res) {
        const countries = []
        Object.keys(res.countries).forEach((country) => {
          countries.push({ country, data: res.countries[country] })
        })
        countries.sort((c, b) => {
          return c.data > b.data ? -1 : 1
        })
        res.countries = countries.slice(0, 5)
      }
      state.dashboardContentActivityTop = res
    }
  },
  actions: {
    async getDashboardPublisherActivity(context, req) {
      context.commit('setPublisherActivity', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardPublisherActivity(req)
        context.commit('setPublisherActivity', res)
      }
    },
    async getDashboardPublisherActivityTop(context, req) {
      context.commit('setPublisherActivityTop', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardPublisherActivityTop(req)
        context.commit('setPublisherActivityTop', res)
      }
    },
    async getDashboardPublisherQuality(context, req) {
      context.commit('setPublisherQuality', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardPublisherQuality(req)
        context.commit('setPublisherQuality', res)
      }
    },
    async getDashboardPublisherQualityInfo(context, req) {
      context.commit('setPublisherQualityInfo', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardPublisherQualityInfo(req)
        context.commit('setPublisherQualityInfo', res)
      }
    },
    async getDashboardPublisherQualityTop(context, req) {
      context.commit('setPublisherQualityTop', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardPublisherQualityTop(req)
        context.commit('setPublisherQualityTop', res)
      }
    },
    async getDashboardAgencyAccounts(context, req) {
      context.commit('setAgencyAccounts', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardAgencyAccounts(req)
        await context.commit('setAgencyAccounts', res)
      }
    },
    async getDashboardAgencyActivity(context, req) {
      context.commit('setAgencyActivity', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardAgencyActivity(req)
        context.commit('setAgencyActivity', res)
      }
    },
    async getDashboardAgencyActivityTop(context, req) {
      context.commit('setAgencyActivityTop', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardAgencyActivityTop(req)
        if (res) {
          const countries = []
          Object.keys(res.countries).forEach((country) => {
            countries.push({ country, data: res.countries[country] })
          })
          countries.sort((c, b) => {
            return c.data > b.data ? -1 : 1
          })
          res.countries = countries.slice(0, 5)
        }
        context.commit('setAgencyActivityTop', res)
      }
    },
    async getDashboardAgencyQualityInfo(context, req) {
      context.commit('setAgencyQualityInfo', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardAgencyQualityInfo(req)
        context.commit('setAgencyQualityInfo', res)
      }
    },
    async getDashboardAgencyQuality(context, req) {
      context.commit('setAgencyQuality', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardAgencyQuality(req)
        context.commit('setAgencyQuality', res)
      }
    },
    async getDashboardContentActivity(context, req) {
      context.commit('setContentActivity', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardContentActivity(req)
        context.commit('setContentActivity', res)
        return res
      }
    },
    async getDashboardContentActivityInfo(context, req) {
      context.commit('setContentActivityInfo', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardContentActivityInfo(req)
        context.commit('setContentActivityInfo', res)
      }
    },
    async getDashboardContentActivityTop(context, req) {
      context.commit('setContentActivityTop', null)
      if (context.rootState.isAuthenticated) {
        const res = await apiService.dashboard.GetDashboardContentActivityTop(req)
        context.commit('setContentActivityTop', res)
      }
    }
  }
}
