import { CRUD, DataType } from '@/interfaces'
import { checkPermission } from '@/store/permissions'
import { RouteRecordRaw } from 'vue-router'

const settingsRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'settings-root',
    redirect: '/settings/personal'
  },
  {
    path: 'personal',
    name: 'settingspresonal',
    component: () => import('@/components/settings/SettingsPersonal.vue'),
    meta: {
      title: 'settings'
    },
    beforeEnter: (to, from, next) => checkPermission('settings_personal_info', next)
  },
  {
    path: 'newPassword',
    name: 'settingsnewPassword',
    component: () => import('@/views/password/NewPassword.vue'),
    meta: {
      title: 'settings'
    }
  },
  {
    path: 'users',
    name: 'users',
    props: true,
    component: () => import('@/components/settings/users/SettingsUsers.vue'),
    meta: {
      title: 'users'
    },
    children: [
      {
        path: 'add',
        name: 'pb-add-user',
        component: () => import('@/components/settings/users/SettingsAddUser.vue'),
        meta: {
          title: 'users'
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'add-closeUnsavedUser',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'users'
            },
            props: { type: DataType.Account, action: CRUD.ConfirmClose }
          }
        ]
      },
      {
        path: 'edit/:id',
        name: 'pb-edit-user',
        component: () => import('@/components/settings/users/SettingsEditUser.vue'),
        props: true,
        meta: {
          title: 'users'
        },
        children: [
          {
            path: 'closeunsaved',
            name: 'edit-closeUnsavedUser',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'users'
            },
            props: { type: DataType.Account, action: CRUD.ConfirmClose }
          },
          {
            path: 'deactivate',
            name: 'edit-deactivateuser',
            component: () => import('@/components/modals/custom-confirmation/CustomConfirmation.vue'),
            meta: {
              title: 'users'
            },
            props: { type: DataType.Account, action: CRUD.Deactivate }
          }
        ]
      }
    ],
    beforeEnter: (to, from, next) => checkPermission('settings_users', next)
  },
  {
    path: 'billing',
    name: 'billing',
    component: () => import('@/components/settings/SettingsBilling.vue'),
    meta: {
      title: 'billing'
    },
    beforeEnter: (to, from, next) => checkPermission('settings_billing', next)
  },
  {
    path: 'system-info',
    name: 'system-info',
    component: () => import('@/components/settings/SystemInfo.vue'),

    meta: {
      title: 'system-info'
    }
  }
]

export default settingsRoutes
