import axios from 'axios'
import { LoginUser } from '@/services/organization-user/types'

const orgAuthLogin = async (orgId: number, userId: number, token: string): Promise<LoginUser> => {
  try {
    const apiKey = import.meta.env.VITE_APP_AUTH_LOGIN_TOKEN
    const apiUrl = import.meta.env.VITE_APP_AUTH_LOGIN_API_URL
    const response = await axios.post<LoginUser>(
      apiUrl,
      {
        organizationId: orgId,
        userId: userId,
        rs: token
      },
      {
        headers: {
          'X-API-Key': apiKey
        }
      }
    )

    return response.data
  } catch (error) {
    console.error('Login failed', error)
    throw error
  }
}

export default orgAuthLogin
