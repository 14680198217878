import type { Videos } from '@/services/videos/types'
import type { Folder } from '@/services/folder/types'

export interface Folders {
  selectedVideos: Videos[]
  folders: Folder[]
}

export const folders = {
  namespaced: true,
  state: () => ({
    selectedVideos: [],
    folders: [],
    totalFolders: 0
  }),
  mutations: {
    setVideos(state, videos: Videos) {
      state.selectedVideos = videos
    },
    setFolders(state, folders: Folder[]) {
      state.folders = folders
    },
    setTotal(state, total: number) {
      state.totalFolders = total
    },
    deselectVideo(state, id) {
      state.selectedVideos = state.selectedVideos.filter((item) => item.video_id !== id)
    },
    deselectFolder(state, id) {
      state.selectedFolders = state.selectedFolders.filter((item) => item.id !== id)
    },
    logout(state) {
      state.selectedVideos = []
      state.folders = []
      state.totalFolders = 0
    }
  },
  actions: {
    setVideos(context, videos: Videos[]) {
      context.commit('setVideos', videos)
    },
    setFolders(context, folders: Folders[]) {
      context.commit('setFolders', folders)
    },
    setTotal(context, total: number) {
      context.commit('setTotal', total)
    },
    deselectVideo(context, id: string) {
      context.commit('deselectVideo', id)
    },
    deselectFolder(context, id: string) {
      context.commit('deselectFolder', id)
    },
    reset(context) {
      context.commit('setTotal', 0)
      context.commit('setFolders', [])
    }
  }
}
