import channel from './channel'
import content from './content'
import contentPackage from './content/package'
import dashboard from './dashboard'
import domain from './domain'
import folder from './folder'
import general from './general'
import lead from './lead'
import organization from './organization'
import organizationUser from './organization-user'
import playlist from './playlist'
import report from './report'
import videos from './videos'
import videoUpload from '@/services/videos/video-upload'
import widget from './widget'
import billing from '@/services/billing'

export const apiService = {
  billing: billing,
  channel: channel,
  content: content,
  contentPackage: contentPackage,
  dashboard: dashboard,
  domain: domain,
  folder: folder,
  general: general,
  lead: lead,
  organization: organization,
  organizationUser: organizationUser,
  playlist: playlist,
  report: report,
  videos: videos,
  videoUpload: videoUpload,
  widget: widget
}
