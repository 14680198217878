import type { DataViewFilters } from '@/services/types'
import type { Domain, DomainResponse, Domains } from '@/services/domain/types'
import api from '@/services/api'

const getDomains = async (params: DataViewFilters): Promise<Domains> => {
  const a = await api.get<Domains>('/domains', { params })
  return a.data
}
const getDomain = async (domain_id: number, organization_id?: number): Promise<Domain> => {
  try {
    const params: { id: number; organization_id?: number } = { id: domain_id, ...(organization_id && { organization_id }) }
    const a = await api.get<Domain>('/domain', { params })
    return a.data
  } catch (error) {
    return Promise.reject(error)
  }
}
const refreshDomain = async (domain_id: number, organization_id?: number): Promise<Domain> => {
  try {
    const a = await api.post<Domain>('/domain/adstxt', { id: domain_id, ...(organization_id && { organization_id }) })
    return a.data
  } catch (error) {
    return Promise.reject(error)
  }
}
const getDomainsByFilter = async (page_number: number, results_per_page: number, search: string, orderBy: string, sortBy: string): Promise<Domains> => {
  const a = await api.get<Domains>('/domains', {
    params: {
      page_number: page_number,
      results_per_page: results_per_page,
      search: search,
      sort_order: orderBy,
      sort_by: sortBy
    }
  })
  return a.data
}
const deleteDomain = async (domain: string, organization_id: number): Promise<void> => {
  await api.delete<Domains>('/domain', { params: { domain, organization_id } })
}
const postDomain = async (domain: { domain: string }): Promise<DomainResponse> => {
  const a = await api.post<DomainResponse>('/domain', { ...domain })
  return a.data
}

export default { getDomains, getDomain, getDomainsByFilter, deleteDomain, postDomain, refreshDomain }
