import { computed, ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'

export function componentSize() {
  const el_width = ref(0)
  const el_height = ref(0)
  const breakpoints = ref([
    {
      name: 'xsmall',
      width: 180
    },
    {
      name: 'small',
      width: 287
    },
    {
      name: 'medium',
      width: 440
    },
    {
      name: 'large',
      width: 510
    }
  ])

  function addObserver(el) {
    useResizeObserver(el, (entries) => {
      const entry = entries[0]
      const { width, height } = entry.contentRect
      el_width.value = width
      el_height.value = height
    })
  }

  const breakpoint = computed(() => {
    const bp = breakpoints.value.find((p, index) => {
      const previous = index > 0 ? breakpoints.value[index - 1].width : 0
      return el_width.value > previous && el_width.value < p.width
    })
    return bp ? bp.name : ''
  })
  const breakpointsAdditive = computed(() => {
    let breakpointString = ''
    for (let index = 0; index < breakpoints.value.length; index++) {
      const p = breakpoints.value[index]
      if (el_width.value < p.width) {
        breakpointString += p.name + ' '
      }
    }
    return breakpointString
  })

  return { el_width, el_height, addObserver, breakpoint, breakpointsAdditive }
}
