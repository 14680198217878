import { DateRangeNames } from '@/interfaces'
import { getDateRanges } from '@/services/utils'

export interface Reports {
  dates: string[]
}

export const reports = {
  namespaced: true,
  state: () => ({
    dates: []
  }),
  mutations: {
    setDates(state, res: string[]) {
      state.dates = res
    },
    logout(state) {
      state.dates = getDateRanges(DateRangeNames.Last30days)
    }
  }
}
