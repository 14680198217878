import { apiService } from '@/services/index'
import { TodoList } from '@/interfaces'

export const todoList = {
  namespaced: true,
  state: () => ({
    accountCreated: false,
    domainsVisited: false,
    copyADStxt: false,
    checkADStxt: false,
    billingInfoAvailable: false,
    reportsVisited: false,
    accountLogin: false,
    domainCreated: false,
    playlistCreated: false,
    widgetCreated: false,
    embedCodeCopied: false,
    videoUploaded: false,
    sourceImported: false,
    profileUpdated: false,
    packageCreated: false,
    packageDistributed: false
  }),
  mutations: {
    setTaskCompleted(state, taskName) {
      state[taskName] = true
      apiService.organizationUser.putUserTodoList(state)
    },
    setState(state, res: TodoList) {
      Object.assign(state, res)
    },
    logout(state) {
      Object.entries(state).forEach((entry) => {
        state[entry[0]] = false
      })
    }
  }
}
