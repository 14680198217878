import i18n from '@/i18n'
import { CRUD, DataType } from '@/interfaces'

export function mapCRUDTypeTranslationsNew(type: CRUD): string {
  let mappedTranslation = ''
  switch (type) {
    case CRUD.Activate:
      mappedTranslation = i18n.global.t('crud-activate')
      break
    case CRUD.Add:
      mappedTranslation = i18n.global.t('crud-add')
      break
    case CRUD.Confirm:
      mappedTranslation = i18n.global.t('crud-confirm')
      break
    case CRUD.ConfirmClose:
      mappedTranslation = i18n.global.t('crud-confirm-close')
      break
    case CRUD.Deactivate:
      mappedTranslation = i18n.global.t('crud-deactivate')
      break
    case CRUD.Delete:
      mappedTranslation = i18n.global.t('crud-delete')
      break
    case CRUD.Duplicate:
      mappedTranslation = i18n.global.t('crud-duplicate')
      break
    case CRUD.Edit:
      mappedTranslation = i18n.global.t('crud-edit')
      break
    case CRUD.View:
      mappedTranslation = i18n.global.t('crud-view')
      break
    case CRUD.Unpair:
      mappedTranslation = i18n.global.t('crud-unpair')
      break
    default:
      null
  }

  return mappedTranslation
}

export function mapDataTypeKey(type: DataType): string {
  let mappedTranslation = ''
  switch (type) {
    case DataType.Folder:
      mappedTranslation = 'folder'
      break
    case DataType.Package:
      mappedTranslation = 'package'
      break
    case DataType.Video:
      mappedTranslation = 'video'
      break
    case DataType.Playlist:
      mappedTranslation = 'playlist'
      break
    case DataType.Widget:
      mappedTranslation = 'widget'
      break
    case DataType.Account:
      mappedTranslation = 'user'
      break
    case DataType.ContentSources:
      mappedTranslation = 'stream'
      break
    case DataType.ExcludedDomains:
    case DataType.MyDomains:
      mappedTranslation = 'domain'
      break
    case DataType.Users:
      mappedTranslation = 'user'
      break
    default:
      null
  }
  return mappedTranslation
}
