import store from '@/store'
import qs from 'qs'
import type { ContentTargeting, ContentOwners, ExcludedDomains, VideoTargetingSearchParams } from '@/services/content/types'
import type { DataViewFilters } from '@/services/types'
import { HTTP_STATUS_OK } from '@/services/constants'
import api from '@/services/api'
import i18n from '@/i18n'
import type { PairedUrl } from '@/services/videos/types'

const getExcludedDomains = async (params: DataViewFilters): Promise<ExcludedDomains> => {
  const a = await api.get<ExcludedDomains>('/content/domains/excluded', { params })
  return a.data
}
const postExcludedDomain = async (domain: string): Promise<boolean> => {
  const a = await api.post('/content/domain/excluded', { domain })
  return a.data
}
const deleteExcludedDomain = async (domainId: string): Promise<boolean> => {
  const a = await api.delete('/content/domain/excluded/' + domainId)
  return a.data
}
const getCreators = async (): Promise<ContentOwners> => {
  const a = await api.get<ContentOwners>('/content/promoted/library')
  return a.data
}
const getVideoTargetingUrls = async (params: VideoTargetingSearchParams) => {
  const queryParams = new URLSearchParams(params)
  const url = `/content/targeting/url?${queryParams}`
  const response = await api.get<ContentTargeting>(url)
  return response.data
}
const getContentTargeting = async (params: DataViewFilters) => {
  const a = await api.get<ContentTargeting>('/content/targeting/url', {
    params
  })
  return a.data
}
const deleteContentTargeting = async (ids: number[]) => {
  if (ids.length === 0) return
  const requests = ids.map((id) => api.delete('/content/targeting/url/' + id.toString()))
  const promiseAll = Promise.all(requests)
  try {
    const res = await promiseAll
    if (!res.some((response) => response.status !== HTTP_STATUS_OK)) {
      store.dispatch('messages/addMessage', { message: i18n.global.t('domain-pair-url-deleted'), type: 'success' })
    } else {
      store.dispatch('messages/addMessage', { message: i18n.global.t('domain-pair-url-deleted-error'), type: 'error' })
    }
    return res
  } catch (error) {
    return false
  }
}
const postContentTargetingUrls = async (urls: PairedUrl[], video_id: string) => {
  if (urls.length === 0) return
  const requests = urls.map((url) => api.post('/content/targeting/url', { video_id, url: url.url, overwrite: url.overwrite }))
  const promiseAll = Promise.all(requests)
  try {
    const res = await promiseAll
    store.dispatch('messages/addMessage', {
      message: i18n.global.t('general-embed-url-confirmation-toast'),
      type: 'success'
    })
    return res
  } catch (error) {
    store.dispatch('messages/addMessage', { message: error.response.data.error.description, type: 'error' })
    return error
  }
}
const getTags = async (query) => {
  const a = await api.get<{ tags: string[] }>('/content/tags', {
    params: { search: query ? query : null },
    paramsSerializer: (params) => {
      return qs.stringify(params)
    }
  })
  return a.data
}

export default {
  getExcludedDomains,
  postExcludedDomain,
  deleteExcludedDomain,
  getCreators,
  getVideoTargetingUrls,
  getContentTargeting,
  deleteContentTargeting,
  postContentTargetingUrls,
  getTags
}
