<template>
  <div data-title="dot-spin">
    <div class="stage">
      <div v-if="props.size !== 'mini'" class="dot-spin" :class="{ small: props.size === 'small' }" />
      <div v-else class="loader" />
    </div>
  </div>
</template>

<script lang="ts" setup>
interface videoProps {
  size?: string
}

const props = withDefaults(defineProps<videoProps>(), {
  size: 'normal'
})
</script>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow:
    0 -18px 0 0 #dadbe8,
    12.727926px -12.727926px 0 0 #dadbe8,
    18px 0 0 0 #dadbe8,
    12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
  animation: dot-spin 1.5s infinite linear;

  &.small {
    transform: scale(0.6);
  }
}

// prettier-ignore
@keyframes dot-spin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #dadbe8, 12.727926px -12.727926px 0 0 #dadbe8, 18px 0 0 0 #dadbe8, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #dadbe8, 18px 0 0 0 #dadbe8, 12.727926px 12.727926px 0 0 #dadbe8, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #dadbe8, 12.727926px 12.727926px 0 0 #dadbe8, 0 18px 0 0 #dadbe8, -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #dadbe8, 0 18px 0 0 #dadbe8, -12.727926px 12.727926px 0 0 #dadbe8, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #dadbe8, -12.727926px 12.727926px 0 0 #dadbe8, -18px 0 0 0 #dadbe8, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #dadbe8, -18px 0 0 0 #dadbe8, -12.727926px -12.727926px 0 0 #dadbe8;
  }
  75% {
    box-shadow: 0 -18px 0 0 #dadbe8, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #dadbe8, -12.727926px -12.727926px 0 0 #dadbe8;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #dadbe8, 12.727926px -12.727926px 0 0 #dadbe8, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 #dadbe8;
  }
}

.loader {
  width: 16px;
  height: 16px;
  border: 2px solid #9599c6;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
