import store from '..'
import type { NavigationGuardNext } from 'vue-router'
import type { UserRole } from '@/services/organization-user/types'

export interface Permissions {
  //Dashboards
  publisher_dashboard: boolean
  content_dashboard: boolean
  agency_dashboard: boolean

  //Content Library
  video_library: boolean

  //Content My Videos
  my_videos_videos: boolean
  my_videos_folders: boolean
  my_videos_imports: boolean
  my_videos_channel: boolean

  //Content Playlists
  playlists: boolean

  //Content Packages
  my_packages_packages: boolean
  packages_offered_packages: boolean

  //Inventory
  inventory_my_domains: boolean
  inventory_paired_urls: boolean
  inventory_excluded_domains: boolean

  //Widgets
  widgets: boolean

  //Reports
  content_reports: boolean
  publisher_reports: boolean

  //Settings
  settings_personal_info: boolean
  settings_users: boolean
  settings_avatar_edit: boolean
  settings_billing: boolean

  notifications_icon: boolean
  agency_accounts: boolean
  agency_domains: boolean
  agency_reports: boolean
}

export function GeneratePermissions(roles: UserRole[]): Permissions {
  let permissions = {
    publisher_dashboard: false,
    content_dashboard: false,
    agency_dashboard: false,
    video_library: false,
    my_videos_videos: false,
    my_videos_folders: false,
    my_videos_imports: false,
    my_videos_channel: false,
    playlists: false,
    packages_offered_packages: false,
    my_packages_packages: false,
    inventory_my_domains: false,
    inventory_paired_urls: false,
    inventory_excluded_domains: false,
    widgets: false,
    content_reports: false,
    publisher_reports: false,
    settings_personal_info: false,
    settings_users: false,
    settings_avatar_edit: false,
    settings_billing: false,
    notifications_icon: false,
    agency_accounts: false,
    agency_domains: false,
    agency_reports: false
  }
  roles?.forEach((role) => {
    const rolePermission = rolePermissions.find((r) => {
      return r.name === role.name
    })
    if (rolePermission) {
      permissions = Object.assign(permissions, rolePermission.permissions)
    }
  })
  return permissions
}

export const rolePermissions = [
  {
    name: 'agency_owner',
    permissions: {
      agency_dashboard: true,
      settings_personal_info: true,
      settings_users: true,
      settings_avatar_edit: true,
      settings_billing: true,
      agency_accounts: true,
      agency_domains: true,
      agency_reports: true,
      notifications_icon: true
    }
  },
  {
    name: 'content_owner',
    permissions: {
      content_dashboard: true,
      my_videos_videos: true,
      my_packages_packages: true,
      my_videos_folders: true,
      my_videos_imports: true,
      my_videos_channel: true,
      inventory_excluded_domains: true,
      content_reports: true,
      settings_personal_info: true,
      settings_users: true,
      settings_avatar_edit: true,
      settings_billing: true,
      notifications_icon: true
    }
  },
  {
    name: 'content_editor',
    permissions: {
      my_videos_videos: true,
      my_packages_packages: true,
      my_videos_folders: true,
      my_videos_imports: true,
      my_videos_channel: true,
      inventory_excluded_domains: true,
      settings_personal_info: true,
      notifications_icon: true
    }
  },
  {
    name: 'content_accounting',
    permissions: {
      content_reports: true,
      settings_personal_info: true,
      settings_billing: true
    }
  },
  {
    name: 'publisher_owner',
    permissions: {
      publisher_dashboard: true,
      video_library: true,
      playlists: true,
      packages_offered_packages: true,
      inventory_my_domains: false,
      inventory_paired_urls: false,
      widgets: true,
      publisher_reports: true,
      settings_personal_info: true,
      settings_users: true,
      settings_avatar_edit: true,
      settings_billing: true,
      notifications_icon: true
    }
  },

  {
    name: 'publisher_editor',
    permissions: {
      video_library: true,
      playlists: true,
      packages_offered_packages: true,
      inventory_my_domains: false,
      inventory_paired_urls: false,
      widgets: true,
      settings_personal_info: true,
      notifications_icon: true
    }
  },
  {
    name: 'publisher_accounting',
    permissions: {
      publisher_reports: true,
      settings_personal_info: true,
      settings_billing: true
    }
  }
]

export function checkPermission(route: string, next?: NavigationGuardNext): boolean | void {
  const roles = store.getters['user/getRoles']()
  const rolesNames = roles.map((r) => r.name)
  const permissions = rolesNames.map((rn) => rolePermissions.find((p) => p.name === rn).permissions)
  const mergedPermissions = Array.from(new Set<string>(permissions.reduce((acc, cur: string) => [...acc, ...Object.keys(cur)], [])))
  const cleanedRoute = route.split('/')[0]
  const hasPermissionForRoute = mergedPermissions.some((p) => p.includes(cleanedRoute))
  if (next) {
    hasPermissionForRoute ? next() : next('/')
  } else {
    return hasPermissionForRoute
  }
}
