import type { Domain } from '@/services/domain/types'

export const domains = {
  namespaced: true,
  state: () => ({
    selectedDomain: '',
    domains: [],
    totalDomains: 0
  }),
  mutations: {
    setDomain(state, domain: Domain) {
      state.selectedDomain = domain
    },
    setDomains(state, domains: Domain[]) {
      state.domains = domains
    },
    setTotal(state, total: number) {
      state.totalDomains = total
    },
    logout(state) {
      state.domains = []
      state.totalDomains = 0
    }
  },
  actions: {
    setDomain(context, domain: Domain) {
      context.commit('setDomain', domain)
    },
    setDomains(context, domains: Domain[]) {
      context.commit('setDomains', domains)
    },
    setTotal(context, total: number) {
      context.commit('setTotal', total)
    },
    reset(context) {
      if (context.domains) {
        context.commit('setTotal', 0)
        context.commit('setDomains', [])
      }
    }
  }
}
